import { useDraggable } from "@dnd-kit/core";
import _ from "lodash";
import { useSortable } from "@dnd-kit/sortable";
import { Button, ButtonGroup, Divider } from "@mui/material";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { CSS } from "@dnd-kit/utilities";
import {
  CheckBoxCard,
  DividerCard,
  DropDownCard,
  ShortAnswerCardValue,
  MultipleChoiceCard,
  ParagraphCard,
  ShortAnswerCard,
} from "./SurveyQuestions";
import { DRAGGABLE_TYPES } from "../constants/Constants";
import { v4 as uuid } from "uuid";

interface DraggableButtonValue {
  id: string;
  children: string;
  icon: React.ReactNode;
  onClick?: (type: string) => void;
}

function AddQuestionButton(props: DraggableButtonValue) {

  return (
    <>
      <ButtonGroup
        variant="outlined"
        sx={{ height: 40, width: "100%" }}
      >
        <Button
          variant="outlined"
          startIcon={props.icon}
          sx={{
            justifyContent: "flex-start",
            flex: "1 1 0",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            _.isFunction(props.onClick) && props.onClick(props.id);
          }}
        >
          {props.children}
        </Button>
      </ButtonGroup>
    </>
  );
}


export default function DraggableButton(props: DraggableButtonValue) {
  // const { attributes, listeners, setNodeRef, transform } = useDraggable({
  //   id: props.id,
  //   data: { type: _.kebabCase(props.children), title: props.children },
  // });

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id,
      data: { type: props.id, title: props.children },
    });

  return (
    <>
      <ButtonGroup
        ref={setNodeRef}
        variant="outlined"
        sx={{ height: 40, width: "100%" }}
        style={{ transform: CSS.Translate.toString(transform) }}
      >
        <Button
          variant="outlined"
          startIcon={props.icon}
          sx={{
            justifyContent: "flex-start",
            flex: "1 1 0",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            _.isFunction(props.onClick) && props.onClick(props.id);
          }}
        >
          {props.children}
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ flex: "0 1 0", borderLeft: 0 }}
          disableRipple
          disableFocusRipple
          {...listeners}
          {...attributes}
        >
          <DragIndicatorIcon />
        </Button>
      </ButtonGroup>
    </>
  );
}

function DraggableItem({
  item,
  questionToIndex,
  t,
}: {
  item: any;
  questionToIndex: Record<string, number>;
  t: any;
}) {
  let component = null;
  // const { attributes, listeners, setNodeRef, transform, transition } =
  //   useSortable({
  //     id: item.id,
  //     data: { type: _.kebabCase(item.children), title: item.children },
  //   });
  if (DRAGGABLE_TYPES.includes(item.id.toString())) {
    component = (
      <ButtonGroup
        // ref={setNodeRef}
        variant="outlined"
        sx={{ height: 40, width: "100%" }}
        // style={{ transform: CSS.Translate.toString(transform) }}
      >
        <Button
          variant="outlined"
          startIcon={item.icon}
          sx={{
            justifyContent: "flex-start",
            flex: "1 1 0",
            whiteSpace: "nowrap",
          }}
        >
          {item.children}
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ flex: "0 1 0", borderLeft: 0 }}
          disableRipple
          disableFocusRipple
          // {...listeners}
          // {...attributes}
        >
          <DragIndicatorIcon />
        </Button>
      </ButtonGroup>
    );
  } else {
    // Dummy card for displaying
    if (item.type === "short-answer") {
      component = (
        <ShortAnswerCard
          index={_.get(questionToIndex, item.id, 0)}
          arrayIndex={0}
          key={item.id}
          id={item.id}
          dbId=""
          isEditing={false}
          title={item.title}
          onQuestionChange={() => {}}
          onQuestionDelete={() => {}}
          onQuestionDuplicate={() => {}}
          onClick={() => {}}
          moveQuestion={() => {}}
          required={item.required}
          shuffle={item.shuffle}
          description={item.description}
          showDescription={item.showDescription}
          t={t}
        />
      );
    } else if (item.type === "paragraph") {
      component = (
        <ParagraphCard
          index={_.get(questionToIndex, item.id, 0)}
          arrayIndex={0}
          key={item.id}
          id={item.id}
          dbId=""
          isEditing={false}
          title={item.title}
          onQuestionChange={() => {}}
          onQuestionDelete={() => {}}
          onQuestionDuplicate={() => {}}
          onClick={() => {}}
          moveQuestion={() => {}}
          required={item.required}
          shuffle={item.shuffle}
          description={item.description}
          showDescription={item.showDescription}
          t={t}
        />
      );
    } else if (item.type === "multiple-choice") {
      component = (
        <MultipleChoiceCard
          index={_.get(questionToIndex, item.id, 0)}
          arrayIndex={0}
          key={uuid()}
          id={item.id}
          dbId=""
          isEditing={false}
          title={item.title}
          multipleChoiceOptions={item.multipleChoiceOptions}
          otherOption={item.otherOption}
          onQuestionChange={() => {}}
          onQuestionDelete={() => {}}
          onQuestionDuplicate={() => {}}
          onClick={() => {}}
          onOptionChange={() => {}}
          onOptionAdd={() => {}}
          onOptionDelete={() => {}}
          toggleOtherOption={() => {}}
          moveQuestion={() => {}}
          required={item.required}
          shuffle={item.shuffle}
          description={item.description}
          showDescription={item.showDescription}
          t={t}
        />
      );
    } else if (item.type === "checkboxes") {
      component = (
        <CheckBoxCard
          index={_.get(questionToIndex, item.id, 0)}
          arrayIndex={0}
          key={uuid()}
          id={item.id}
          dbId=""
          isEditing={false}
          title={item.title}
          checkBoxOptions={item.checkBoxOptions}
          otherOption={item.otherOption}
          onQuestionChange={() => {}}
          onQuestionDelete={() => {}}
          onQuestionDuplicate={() => {}}
          onClick={() => {}}
          onOptionChange={() => {}}
          onOptionAdd={() => {}}
          onOptionDelete={() => {}}
          toggleOtherOption={() => {}}
          moveQuestion={() => {}}
          required={item.required}
          shuffle={item.shuffle}
          description={item.description}
          showDescription={item.showDescription}
          t={t}
        />
      );
    } else if (item.type === "dropdown") {
      component = (
        <DropDownCard
          index={_.get(questionToIndex, item.id, 0)}
          arrayIndex={0}
          key={uuid()}
          id={item.id}
          dbId=""
          isEditing={false}
          title={item.title}
          dropDownOptions={item.dropDownOptions}
          onQuestionChange={() => {}}
          onQuestionDelete={() => {}}
          onQuestionDuplicate={() => {}}
          onClick={() => {}}
          onOptionChange={() => {}}
          onOptionAdd={() => {}}
          onOptionDelete={() => {}}
          moveQuestion={() => {}}
          required={item.required}
          shuffle={item.shuffle}
          description={item.description}
          showDescription={item.showDescription}
          t={t}
        />
      );
    } else if (item.type === "divider") {
      component = (
        <DividerCard
          arrayIndex={_.get(questionToIndex, item.id, 0)}
          key={uuid()}
          id={item.id}
          dbId=""
          isEditing={false}
          title={""}
          onClick={() => {}}
          onQuestionDelete={() => {}}
          onQuestionDuplicate={() => {}}
          moveQuestion={() => {}}
          t={t}
        />
      );
    }
  }
  return component;
}

// function DraggableItem({ item }: { item: any }) {
//   let component = <></>;
//   // const { attributes, listeners, setNodeRef, transform, transition } =
//   //   useSortable({
//   //     id: item.id,
//   //     data: { type: _.kebabCase(item.children), title: item.children },
//   //   });
//   if (DRAGGABLE_TYPES.includes(item.id.toString())) {
//     component = (
//       <ButtonGroup
//         // ref={setNodeRef}
//         variant="outlined"
//         sx={{ height: 40, width: "100%" }}
//         // style={{ transform: CSS.Translate.toString(transform) }}
//       >
//         <Button
//           variant="outlined"
//           startIcon={item.icon}
//           sx={{
//             justifyContent: "flex-start",
//             flex: "1 1 0",
//             whiteSpace: "nowrap",
//           }}
//         >
//           {item.children}
//         </Button>
//         <Button
//           variant="outlined"
//           size="small"
//           sx={{ flex: "0 1 0", borderLeft: 0 }}
//           disableRipple
//           disableFocusRipple
//           // {...listeners}
//           // {...attributes}
//         >
//           <DragIndicatorIcon />
//         </Button>
//       </ButtonGroup>
//     );
//   } else {
//     console.log("switching");
//     let component = null;
//     console.log(item)
//     switch (item.type) {
//       case "short-answer":
//         component = (
//           <ShortAnswerCard
//             key={uuid()}
//             id={item.id}
//             dbId=""
//             isEditing={item.isEditing}
//             title={item.title}
//             onQuestionChange={() => {}}
//             onClick={() => {}}
//           />
//         );
//         break;
//       case "paragraph":
//         component = (
//           <ParagraphCard
//             key={uuid()}
//             id={item.id}
//             dbId=""
//             isEditing={item.isEditing}
//             title={item.title}
//             onQuestionChange={() => {}}
//             onClick={() => {}}
//           />
//         );
//         break;
//       case "multiple-choice":
//         component = (
//           <MultipleChoiceCard
//             key={uuid()}
//             id={item.id}
//             dbId=""
//             isEditing={item.isEditing}
//             title={item.title}
//             multipleChoiceOptions={item.multipleChoiceOptions}
//             otherOption={item.otherOption}
//             onQuestionChange={() => {}}
//             onClick={() => {}}
//             onOptionChange={() => {}}
//             onOptionAdd={() => {}}
//             onOptionDelete={() => {}}
//             toggleOtherOption={() => {}}
//           />
//         );
//         break;
//       case "checkboxes":
//         component = (
//           <CheckBoxCard
//             key={uuid()}
//             id={item.id}
//             dbId=""
//             isEditing={item.isEditing}
//             title={item.title}
//             checkBoxOptions={item.checkBoxOptions}
//             otherOption={item.otherOption}
//             onQuestionChange={() => {}}
//             onClick={() => {}}
//             onOptionChange={() => {}}
//             onOptionAdd={() => {}}
//             onOptionDelete={() => {}}
//             toggleOtherOption={() => {}}
//           />
//         );
//         break;
//       case "dropdown":
//         component = <div key={item.id}>{item.id}</div>;
//         break;
//     }
//   }
//   return component;
// }

export { DraggableItem, AddQuestionButton };
