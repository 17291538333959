import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, TextFieldProps } from "@mui/material";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import { COUNTRIES, CountryType } from "../constants/Constants";
import { useTranslation } from "react-i18next";

interface CountrySelectProps {
  id?: string;
  value: CountryType | null | undefined;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  onBlur?: (event: React.SyntheticEvent<Element, Event>) => void;
}
export default function CountrySelect({
  id,
  value,
  onChange,
  onBlur,
}: CountrySelectProps) {
  const { t, i18n } = useTranslation();
  return (
    <Autocomplete
      id={id}
      options={COUNTRIES}
      autoHighlight
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={t("signup.whereDoYouLive")}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
