import * as React from "react";
import goAPI from "../api/GoAPI";
import { toast } from "react-toastify";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import UserContext from "../context/AuthProvider";
import _ from "lodash";

import {
  Button,
  Container,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@mui/material";
import { useCookies } from "react-cookie";

import Copyright from "../components/Copyright";
import BackgroundImage from "../images/login-background.jpg";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { ACCOUNT_MAX_LENGTH } from "../constants/Constants";

export default function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let location = useLocation();
  const { auth, setAuth, persist, setPersist } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const handleShowPasswordClick = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  let from = location.state?.from?.pathname || "/";

  // React.useEffect(() => {
  //   if (!_.isEmpty(auth)) {
  //     navigate("/");
  //   }
  // }, []);

  React.useEffect(() => {
    localStorage.setItem("persist", persist.toString());
  }, [persist]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let submission = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      setLoading(true);
      const response = await goAPI.auth.login(submission);
      let auth = response.data;
      let accessToken = _.get(auth, "tokens.access.token", null);

      if (!_.isNil(auth?.user) && !_.isNil(accessToken)) {
        // Set user and token
        setAuth({ user: auth.user, accessToken: accessToken });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (from !== "/signup" && from !== "/login") {
          navigate(from, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      } else {
        throw new Error("No user or access token found");
      }
    } catch (error: any) {
      console.log(error); // Prints the error message
      toast.error(t("login.loginFailed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            padding: 4,
            borderRadius: 4,
          }}
          elevation={3}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h4">
              {t("login.title")}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 4 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("login.email")}
                name="email"
                autoComplete="email"
                autoFocus
                inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.EMAIL }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("login.password")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.PASSWORD }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPasswordClick}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="persist"
                      onChange={togglePersist}
                      checked={persist}
                    />
                  }
                  label={t("login.trustDevice")}
                />
              </FormControl>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 2, borderRadius: 6 }}
              >
                {t("login.title")}
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link
                    component={RouterLink}
                    to="/forget-password"
                    variant="body2"
                  >
                    {t("login.forgotPassword")}
                  </Link>
                </Grid> */}
                <Grid item>
                  <Link component={RouterLink} to="/signup" variant="body2">
                    {t("login.noAccountSignUp")}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Paper>
      </Container>
    </Grid>
  );
}
