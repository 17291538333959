import * as React from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { SelectType } from "../constants/Constants";

interface GenderSelectProps {
  id?: string;
  label?: string;
  value: string | null | undefined;
  options: readonly SelectType[];
  onChange: (
    event: SelectChangeEvent<string | null>,
    child: React.ReactNode
  ) => void;
  onBlur?: (event: React.SyntheticEvent<Element, Event>) => void;
  error?: boolean | undefined;
  helperText?: string | false | undefined;
  t: any;
}

export default function FormSelect({
  id,
  label,
  value,
  options,
  onChange,
  onBlur,
  error,
  helperText,
  t,
}: GenderSelectProps) {
  return (
    <FormControl fullWidth error={error} required>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
      >
        {options.map((item: SelectType) => (
          <MenuItem key={item.key} value={item.key}>
            {t(item.text)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
