import { AnswerObject } from "../../components/SurveyResponse";
import axios, { authAxiosInstance, getJWTHeader } from "../Axios";

const routers = {
  // For the route that requires authentication, we use authAxiosInstance instead of axios
  // For nomral, public routes, we use axios
  addPoint: async (accessToken: string) =>
    authAxiosInstance.get("/point", getJWTHeader(accessToken)),
};

export default routers;
