import { useNavigate } from "react-router-dom";
import openAxiosInstance from "../api/Axios";
import useAuth from "./useAuth";
import GoAPI from "../api/GoAPI";

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    setAuth({});
    try {
      const response = await GoAPI.auth.logout();
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
