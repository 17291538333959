import {
  ArrowBack as ArrowBackIcon,
  Publish as PublishIcon,
} from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LinkIcon from "@mui/icons-material/Link";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { toast } from "react-toastify";
import goAPI from "../api/GoAPI";
import {
  GOOGLEFORM_TYPE,
  ICON_LOOK_UP,
  QuestionTypeToName,
  QuestionTypeToPrice,
  RECOMMENDATION_PRICE,
  RecommendationTypeToName,
  STEPPER_HEIGHT,
} from "../constants/Constants";
import UserContext from "../context/AuthProvider";
import { SurveyTargetAudienceValue, SurveyValue } from "../pages/CreateSurvey";
import { QuestionValue } from "./SurveyQuestions";

interface CreateSurveySubmitPageProps {
  goToPrevPage: () => void;
  handlePublish: () => void;
  questionItems: QuestionValue[];
  targetAudience: SurveyTargetAudienceValue[];
  published: boolean;
  surveyId: string;
  surveyType: string;
  t: any;
}

const getIcon = (type: string) => {
  if (ICON_LOOK_UP[type]) {
    return ICON_LOOK_UP[type];
  } else {
    return null;
  }
};

export default function CreateSurveySubmitPage({
  goToPrevPage,
  handlePublish,
  questionItems,
  targetAudience,
  published = false,
  surveyId = "",
  surveyType = GOOGLEFORM_TYPE,
  t,
}: CreateSurveySubmitPageProps) {
  const { auth } = React.useContext(UserContext);

  const theme = useTheme();
  const [questionCart, setQuestionCart] = React.useState<
    Record<string, number> | undefined
  >(undefined);
  const [recommendationCart, setRecommendationCart] = React.useState<
    Record<string, number>
  >({});
  const [isPublishDialogOpen, setPublishDialogOpen] =
    React.useState<boolean>(false);

  const [totalCost, setTotalCost] = React.useState<number>(-1);

  const ignoreList = new Set(["divider"]);

  // const [QuestionTypeToPrice, setQuestionTypeToPrice] = React.useState<
  //   Record<string, Record<string, number>>
  // >({});

  // React.useEffect(() => {
  //   const fetchQuestionPrices = async () => {
  //     const prices = await goAPI.survey.getQuestionPrices(
  //       auth?.accessToken ?? ""
  //     );
  //     setQuestionTypeToPrice(
  //       prices.data as Record<string, Record<string, number>>
  //     );
  //   };
  //   if (auth?.accessToken) {
  //     fetchQuestionPrices();
  //   }
  // }, [auth?.accessToken]);

  React.useEffect(() => {
    let newQuestionCart: Record<string, number> = {};
    questionItems.forEach((item) => {
      if (item.type !== undefined && !ignoreList.has(item.type)) {
        newQuestionCart[item.type] = _.get(newQuestionCart, item.type, 0) + 1;
      }
    });
    setQuestionCart(newQuestionCart);
  }, [questionItems]);

  React.useEffect(() => {
    // Call the async function when the component mounts
    const calculatePoints = async () => {
      // Question
      const response = await goAPI.survey.calculateTotalCost(
        auth?.accessToken ?? "",
        questionCart ?? {},
        surveyType
      );
      let questionPoints = response.data.cost;

      // Recommendation
      let recommendationPoints = targetAudience.length * RECOMMENDATION_PRICE;
      console.log(questionPoints);
      const totalCost = questionPoints + recommendationPoints;
      setTotalCost(totalCost);
    };

    if (
      auth?.accessToken &&
      targetAudience !== undefined &&
      questionCart !== undefined
    ) {
      calculatePoints();
    }
  }, [auth?.accessToken, questionCart, targetAudience]);

  React.useEffect(() => {
    let newRecommendationCart: Record<string, number> = {};
    targetAudience.forEach((item) => {
      if (item.type !== undefined) {
        newRecommendationCart[item.type] =
          _.get(newRecommendationCart, item.type, 0) + 1;
      }
    });
    setRecommendationCart(newRecommendationCart);
  }, [targetAudience]);

  const baseUrl = process.env.REACT_APP_WEB_URL;

  return (
    <React.Suspense>
      {totalCost !== -1 && (
        <PushlishConfirmationDialog
          open={isPublishDialogOpen}
          handleClose={() => setPublishDialogOpen(false)}
          handleOk={() => {
            setPublishDialogOpen(false);
            handlePublish();
          }}
          points={totalCost}
          theme={theme}
          t={t}
        />
      )}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          minHeight: {
            xs: `calc(100vh - ${STEPPER_HEIGHT.xs + 64}px)`,
            sm: `calc(100vh - ${STEPPER_HEIGHT.sm + 64}px)`,
            md: `calc(100vh - ${STEPPER_HEIGHT.md + 64}px)`,
          },
          backgroundColor: theme.palette.greyBackground?.main || "#f5f5f5",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: {
              xs: 2,
              md: 8,
            },
            paddingTop: {
              xs: 4,
              md: 12,
            },
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box component="form" noValidate autoComplete="off">
            <Box sx={{ width: "fit-content", display: "flex" }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                {published
                  ? t("createSurvey.surveyHasPublished")
                  : t("createSurvey.publishYourSurvey")}
              </Typography>
            </Box>
            {published ? (
              <Stack sx={{ my: 8 }} spacing={2} gap={1}>
                <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                  <LinkIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  <Typography variant="h5" component="h4">
                    {t("createSurvey.shareYourSurvey")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                  <TextField
                    value={baseUrl?.concat("/s/").concat(surveyId) || ""}
                    disabled
                    fullWidth
                    size="small"
                  ></TextField>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        baseUrl?.concat("/s/").concat(surveyId) || ""
                      );
                      toast.info(t("createSurvey.copied"));
                    }}
                    variant="contained"
                    sx={{ width: "fit-content" }}
                  >
                    {t("createSurvey.copy")}
                  </Button>
                </Box>
              </Stack>
            ) : (
              <Stack sx={{ my: 8 }} spacing={2} gap={2}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    textAlign: "end",
                  }}
                >
                  <PointTable
                    theme={theme}
                    questionCart={questionCart}
                    recommendationCart={recommendationCart}
                    t={t}
                  />
                  <Divider />
                  {surveyType === GOOGLEFORM_TYPE ? (
                    <Typography variant="subtitle1" component="h4">
                      {`${t("createSurvey.gf")}: ${QuestionTypeToPrice.gf}pt`}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" component="h4">
                      {`${t("createSurvey.base")}: ${
                        QuestionTypeToPrice.base
                      }pt`}
                    </Typography>
                  )}
                  {/* <Typography variant="h6" component="h4">
                </Typography> */}
                  <Divider />
                  <Typography variant="h6" component="h4">
                    {`${t("createSurvey.total")}: ${
                      totalCost !== -1 ? totalCost + "pt" : "Loading"
                    }`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      goToPrevPage();
                    }}
                  >
                    {t("createSurvey.back")}
                  </Button>
                  <Button
                    startIcon={<PublishIcon />}
                    variant="outlined"
                    size="large"
                    sx={{ ml: "auto" }}
                    onClick={async () => {
                      if (
                        _.get(auth, "user.points", 0) < totalCost ||
                        totalCost < 0
                      ) {
                        toast.warning(
                          t("createSurvey.pointNotEnoughDescription")
                        );
                        return;
                      }
                      setPublishDialogOpen(true);
                    }}
                  >
                    {t("createSurvey.publish")}
                  </Button>
                </Box>
              </Stack>
            )}
          </Box>
          {/* <Box sx={{ display: "flex", mt: "auto" }}>

          </Box> */}
        </Container>
      </Grid>
    </React.Suspense>
  );
}

function PointTable({
  theme,
  questionCart,
  recommendationCart,
  t,
}: {
  theme: Theme;
  questionCart?: Record<string, number>;
  recommendationCart: Record<string, number>;
  t: any;
}) {
  const { auth } = React.useContext(UserContext);

  // const [QuestionTypeToPrice, setQuestionTypeToPrice] = React.useState<
  //   Record<string, Record<string, number>>
  // >({});

  // React.useEffect(() => {
  //   const fetchQuestionPrices = async () => {
  //     const prices = await goAPI.survey.getQuestionPrices(
  //       auth?.accessToken ?? ""
  //     );
  //     setQuestionTypeToPrice(
  //       prices.data as Record<string, Record<string, number>>
  //     );
  //   };
  //   if (auth?.accessToken) {
  //     fetchQuestionPrices();
  //   }
  // }, [auth?.accessToken]);
  // console.log("QuestionTypeToPrice:", QuestionTypeToPrice["questionPrices"])

  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("createSurvey.item")}</TableCell>
            <TableCell align="right">
              {t("createSurvey.pointRequired")}
            </TableCell>
            <TableCell align="right">{t("createSurvey.number")}</TableCell>
            <TableCell align="right">{t("createSurvey.subtotal")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionCart &&
            Object.entries(questionCart).map((row) => {
              let qType = row[0];
              let qCount = row[1];
              let Icon = getIcon(qType);
              return (
                <TableRow
                  key={qType}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {Icon && (
                        <Icon
                          sx={{ color: theme.palette.primary.main, mr: 1 }}
                        />
                      )}{" "}
                      <Typography variant="body1" component="span">
                        {t(QuestionTypeToName[qType])}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {QuestionTypeToPrice[qType]}
                  </TableCell>
                  <TableCell align="right">{qCount}</TableCell>
                  <TableCell align="right">
                    {QuestionTypeToPrice[qType] * qCount}
                  </TableCell>
                </TableRow>
              );
            })}
          {Object.entries(recommendationCart).map((row) => {
            let rType = row[0];
            let rCount = row[1];
            let Icon = AutoAwesomeIcon;
            return (
              <TableRow
                key={rType}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {Icon && (
                      <Icon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                    )}{" "}
                    <Typography variant="body1" component="span">
                      {`${t("createSurvey.rule")}: ${t(
                        RecommendationTypeToName[rType]
                      )}`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{RECOMMENDATION_PRICE}</TableCell>
                <TableCell align="right">{rCount}</TableCell>
                <TableCell align="right">
                  {RECOMMENDATION_PRICE * rCount}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function PushlishConfirmationDialog({
  points,
  open,
  handleOk,
  handleClose,
  theme,
  t,
}: {
  points: number;
  open: boolean;
  handleOk: () => void;
  handleClose: () => void;
  theme: Theme;
  t: any;
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Publish this survey?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("createSurvey.youWillBeCharge") + " "}
            <span
              style={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
              {points}
            </span>{" "}
            {t("createSurvey.chargeDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("createSurvey.cancel")}</Button>
          <Button onClick={handleOk} variant="contained">
            {t("createSurvey.agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
