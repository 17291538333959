import _ from "lodash";
import openAxiosInstance from "../api/Axios";
import GoAPI from "../api/GoAPI";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await GoAPI.auth.refreshToken();
    let accessToken = _.get(response, "data.access.token", "");
    setAuth((prev: any) => {
      // console.log(JSON.stringify(prev));
      // console.log(accessToken);
      return {
        ...prev,
        accessToken: accessToken,
      };
    });
    return accessToken;
  };
  return refresh;
};

export default useRefreshToken;
