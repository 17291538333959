import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Login from "./pages/Login";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthProvider } from "./context/AuthProvider";
import { CookiesProvider } from "react-cookie";
import RequireAuth from "./components/RequireAuth";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import SignUp from "./pages/Signup";
import MySurveys from "./pages/MySurveys";
import CreateSurvey from "./pages/CreateSurvey";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import TermsOfService from "./pages/TermsOfService";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SurveyResponse from "./pages/SurveyResponse";
import SurveyReport from "./pages/SurveyReport";
import PersistLogin from "./components/PersistLogin";

// import i18n (needs to be bundled ;))
import "./i18n";
import HowTo from "./pages/HowTo";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      {/* public routes */}
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="how-to" element={<HowTo />} />
      {/* we want to protect these routes */}
      <Route element={<PersistLogin />}>
        <Route path="/" element={<Home />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/s/:surveyId" element={<SurveyResponse />} />
        <Route element={<RequireAuth />}>
          <Route path="/my-surveys" element={<MySurveys />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path="/my-surveys/edit/:surveyId" element={<CreateSurvey />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path="/my-surveys/report/:surveyId"
            element={<SurveyReport />}
          />
        </Route>
      </Route>

      {/* catch all */}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: (
//       <PersistLogin>
//         <Layout>
//           <Home />
//         </Layout>
//       </PersistLogin>
//     ),
//   },
//   {
//     path: "/s/:surveyId",
//     element: (
//       <PersistLogin>
//         <SurveyResponse />
//       </PersistLogin>
//     ),
//   },
//   {
//     path: "/my-surveys",
//     element: (
//       <PersistLogin>
//         <RequireAuth>
//           <MySurveys />
//         </RequireAuth>
//       </PersistLogin>
//     ),
//   },
//   {
//     path: "/my-surveys/edit/:surveyId",
//     element: (
//       <PersistLogin>
//         <RequireAuth>
//           <CreateSurvey />
//         </RequireAuth>
//       </PersistLogin>
//     ),
//   },
//   {
//     path: "/my-surveys/report/:surveyId",
//     element: (
//       <PersistLogin>
//         <RequireAuth>
//           <SurveyReport />
//         </RequireAuth>
//       </PersistLogin>
//     ),
//   },
//   {
//     path: "/my-surveys/edit",
//     element: (
//       <PersistLogin>
//         <RequireAuth>
//           <CreateSurvey />
//         </RequireAuth>
//       </PersistLogin>
//     ),
//   },
// ]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <AuthProvider>
            <RouterProvider router={router} />
            <ToastContainer position="top-center" />
          </AuthProvider>
        </CookiesProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
