import * as React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import {
  GOOGLEFORM_TYPE,
  STEPPER_HEIGHT,
  SURVEYSKY_TYPE,
  SURVEY_FORM_VALIDATION,
  PREFERRED_LANGUAGES,
} from "../constants/Constants";
import type {
  SurveyTargetAudienceValue,
  SurveyValue,
} from "../pages/CreateSurvey";
import TargetAudienceSelect from "./TargetAudienceSelect";

interface CreateSurveyHeaderPageProps {
  goToNextPage: () => void;
  survey: SurveyValue;
  setSurvey: React.Dispatch<React.SetStateAction<SurveyValue>>;
  t: any;
}

export default function CreateSurveyHeaderPage({
  goToNextPage,
  setSurvey,
  survey,
  t,
}: CreateSurveyHeaderPageProps) {
  const theme = useTheme();
  const [error, setError] = React.useState({
    title: "",
    description: "",
    gfLink: "",
    language: "",
  });
  const [language, setLanguage] = React.useState("");

  const setTitle = (newValue: string) => {
    setSurvey((oldState) => {
      return {
        ...oldState,
        title: newValue,
      };
    });
  };
  const setDescription = (newValue: string) => {
    setSurvey((oldState) => {
      return {
        ...oldState,
        description: newValue,
      };
    });
  };
  const setTags = (newValue: string[]) => {
    setSurvey((oldState) => {
      return {
        ...oldState,
        tags: newValue,
      };
    });
  };
  const setTargetAudience = (newValue: SurveyTargetAudienceValue[]) => {
    setSurvey((oldState) => {
      return {
        ...oldState,
        targetAudience: newValue,
      };
    });
  };
  const setGfLink = (newValue: string) => {
    setSurvey((oldState) => {
      return {
        ...oldState,
        gfLink: newValue,
      };
    });
  };

  React.useEffect(() => {
    const browserLang = navigator.language;
    setLanguage(browserLang.includes("jp") ? "jp" : "en");
  }, []);


  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLang = event.target.value;
    setLanguage(newLang);
  };

  return (
    <React.Suspense>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          minHeight: {
            xs: `calc(100vh - ${STEPPER_HEIGHT.xs + 64}px)`,
            sm: `calc(100vh - ${STEPPER_HEIGHT.sm + 64}px)`,
            md: `calc(100vh - ${STEPPER_HEIGHT.md + 64}px)`,
          },
          backgroundColor: theme.palette.greyBackground?.main || "#f5f5f5",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            px: {
              xs: 2,
              md: 8,
            },
            py: {
              xs: 2,
              md: 4,
            },
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box sx={{ display: "flex", mb: 6 }}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontWeight: 600,
              }}
            >
              {survey?.surveyType == GOOGLEFORM_TYPE
                ? "Google Form Survey"
                : "Survey Sky Survey"}
            </Typography>
          </Box>
          <Box component="form" noValidate autoComplete="off" sx={{ mb: 12 }}>
            {survey.surveyType === GOOGLEFORM_TYPE && (
              <>
                {/* Google form link */}
                <Box sx={{ width: "fit-content", display: "flex" }}>
                  <Box
                    sx={{
                      width: "6px",
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "8px",
                      mr: 1,
                    }}
                  ></Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {t("createSurvey.googleFormLink")}
                  </Typography>
                </Box>
                <Stack sx={{ mt: 2 }} spacing={2}>
                  <div>
                    <Typography variant="subtitle1" component="p">
                      {t("createSurvey.googleFormLinkDescription")}
                    </Typography>
                  </div>
                  <TextField
                    placeholder={t("createSurvey.googleFormLinkPlaceholder")}
                    variant="outlined"
                    multiline
                    value={survey.gfLink}
                    inputProps={{
                      style: {
                        fontWeight: 400,
                      },
                      maxLength: SURVEY_FORM_VALIDATION.GF_LINK.MAX_LENGTH,
                    }}
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setError((oldState) => {
                          return {
                            ...oldState,
                            gfLink: "",
                          };
                        });
                      }
                      setGfLink(event.target.value);
                    }}
                    error={error.gfLink !== ""}
                    helperText={error.gfLink}
                  />
                </Stack>
                {/* Google form code hint */}
                <Box sx={{ width: "fit-content", display: "flex", mt: 4 }}>
                  <Box
                    sx={{
                      width: "6px",
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "8px",
                      mr: 1,
                    }}
                  ></Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {t("createSurvey.googleFormCode")}
                  </Typography>
                </Box>
                <Stack sx={{ mt: 2 }} spacing={2}>
                  <div>
                    <Typography
                      variant="subtitle1"
                      component="h4"
                      sx={{ fontWeight: 600, color: "red" }}
                    >
                      {t("createSurvey.googleFormCodeDescription")}
                    </Typography>
                  </div>
                  <TextField
                    variant="outlined"
                    multiline
                    value={survey?.gfCode}
                    inputProps={{
                      style: {
                        fontWeight: 400,
                      },
                    }}
                    disabled
                  />
                </Stack>
              </>
            )}
            {/* Header */}
            <Box sx={{ width: "fit-content", display: "flex", mt: 4 }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 600,
                }}
              >
                {t("createSurvey.designHeader")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 2 }} spacing={2}>
              <div>
                <Typography variant="subtitle1" component="p">
                  {t("createSurvey.designHeaderDescription")}
                </Typography>
              </div>
              <TextField
                placeholder={t("createSurvey.surveyTitle")}
                variant="outlined"
                multiline
                value={survey.title}
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "22pt",
                    lineHeight: 1.25,
                    letterSpacing: 0,
                  },
                  maxLength: SURVEY_FORM_VALIDATION.TITLE.MAX_LENGTH,
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setError((oldState) => {
                      return {
                        ...oldState,
                        title: "",
                      };
                    });
                  }
                  setTitle(event.target.value);
                }}
                error={error.title !== ""}
                helperText={error.title}
              />
              <TextField
                placeholder={t("createSurvey.surveyDescription")}
                variant="outlined"
                multiline
                value={survey.description}
                inputProps={{
                  style: {
                    fontWeight: 400,
                  },
                  maxLength: SURVEY_FORM_VALIDATION.DESCRIPTION.MAX_LENGTH,
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setError((oldState) => {
                      return {
                        ...oldState,
                        description: "",
                      };
                    });
                  }
                  setDescription(event.target.value);
                }}
                error={error.description !== ""}
                helperText={error.description}
              />
            </Stack>
            {/* Language */}
            <Box sx={{ width: "fit-content", display: "flex", mt: 4 }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 600,
                }}
              >
                {t("createSurvey.setlanguage")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 2 }} spacing={2}>
              <div>
                <Typography variant="subtitle1" component="p">
                  {t("createSurvey.setlanguageDescription")}
                </Typography>
              </div>
            <FormControl fullWidth>
              <InputLabel id="language-select-label">{t('createSurvey.setlanguage')}</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                label={t('createSurvey.setlanguage')}
                onChange={handleLanguageChange}
              >
                {PREFERRED_LANGUAGES.map((lang) => (
                  <MenuItem key={lang.key} value={lang.key}>
                    {t(lang.text)}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
          </Stack>
            {/* Tags */}
            <Box sx={{ width: "fit-content", display: "flex", mt: 4 }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 600,
                }}
              >
                {t("createSurvey.createTags")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 2 }} spacing={2}>
              <div>
                <Typography variant="subtitle1" component="p">
                  {t("createSurvey.createTagsDescription")}
                </Typography>
                <Typography variant="subtitle2" component="p" sx={{ mt: 1 }}>
                  {t("createSurvey.createTagsHint")}
                </Typography>
              </div>
              <Autocomplete
                multiple
                freeSolo
                autoComplete={false}
                id="multiple-tags"
                options={[]}
                value={survey.tags}
                onChange={(event: any, newValue: string[]) => {
                  if (survey.tags.length >= 5 && newValue.length > 5) return;
                  if (
                    newValue[newValue.length - 1] === "" ||
                    newValue[newValue.length - 1].length > 50
                  )
                    return;
                  setTags(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("createSurvey.tags")}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Stack>
            {/* Recommendation */}
            <Box sx={{ width: "fit-content", display: "flex", mt: 4 }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 600,
                }}
              >
                {t("createSurvey.recommendationTitle")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 2 }} spacing={2}>
              <div>
                <Typography variant="subtitle1" component="p">
                  {t("createSurvey.recommendationDescription")}
                </Typography>
                <Typography variant="subtitle2" component="p" sx={{ mt: 1 }}>
                  {t("createSurvey.recommendationHint")}
                </Typography>
              </div>
              <TargetAudienceSelect
                setValue={setTargetAudience}
                value={survey.targetAudience}
                t={t}
              />
            </Stack>
          </Box>
          <Box sx={{ display: "flex", mt: "auto" }}>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              sx={{ ml: "auto" }}
              onClick={() => {
                if (survey.title === "" || survey.description === "") {
                  setError({
                    title:
                      survey.title === ""
                        ? t("createSurvey.titleIsRequired")
                        : "",
                    description:
                      survey.description === ""
                        ? t("createSurvey.descriptionIsRequired")
                        : "",
                    gfLink: "",
                    language:
                      survey.language === ""
                        ? t("createSurvey.languageIsRequired")
                        : "",
                  });
                  return;
                }

                if (survey.surveyType === GOOGLEFORM_TYPE) {
                  if (survey.gfLink === "" || survey.gfLink === undefined) {
                    setError({
                      ...error,
                      gfLink:
                        survey.gfLink === ""
                          ? t("createSurvey.googleFormLinkIsRequired")
                          : "",
                    });
                    return;
                  }
                  // Check if the google form link is valid
                  try {
                    const url = new URL(survey.gfLink);
                    if (
                      url.hostname !== "docs.google.com" &&
                      url.hostname !== "forms.gle"
                    ) {
                      setError({
                        ...error,
                        gfLink: t("createSurvey.googleFormLinkIsInvalid"),
                      });
                      return;
                    }
                  } catch (e) {
                    setError({
                      ...error,
                      gfLink: t("createSurvey.googleFormLinkIsInvalid"),
                    });
                    return;
                  }
                }
                goToNextPage();
              }}
            >
              {t("createSurvey.next")}
            </Button>
          </Box>
        </Container>
      </Grid>
    </React.Suspense>
  );
}
