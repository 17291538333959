import { useDroppable, UniqueIdentifier } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
  rectSortingStrategy,
  AnimateLayoutChanges,
  NewIndexGetter,
} from "@dnd-kit/sortable";
import { Stack } from "@mui/material";
import {
  ShortAnswerCard,
  ParagraphCard,
  QuestionValue,
  QuestionEvents,
  DropDownCard,
  MultipleChoiceCard,
  CheckBoxCard,
  DropDownCardValue,
  DividerCard,
} from "./SurveyQuestions";

import { styled, useTheme } from "@mui/material/styles";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { useEffect, useState } from "react";
import React from "react";

export interface QuestionEditingAreaProp {
  areaId: string;
  questionItems: QuestionValue[];
  setQuestionItems: React.Dispatch<React.SetStateAction<QuestionValue[]>>;
  activeItemId: UniqueIdentifier | null;
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    targetId: string
  ) => void;
  isDragging: boolean;
  questionToIndex: Record<string, number>;
  moveQuestion: (id: string, atIndex: number) => void;
  t: any;
}

export default function QuestionEditingArea({
  areaId,
  questionItems,
  questionToIndex,
  setQuestionItems,
  onClick,
  activeItemId,
  isDragging = false,
  moveQuestion,
  t,
}: QuestionEditingAreaProp) {
  const { setNodeRef } = useDroppable({
    id: areaId,
  });
  const theme = useTheme();
  const style = isDragging
    ? {
        backgroundColor: "#eeeeee",
        borderStyle: "dashed",
        borderColor: "#e0e0e0",
        borderRadius: "16px",
        px: 2,
        py: 4,
      }
    : { px: 2, py: 4 };

  const changeQuestion = (itemId: string, changes: Record<string, any>) => {
    setQuestionItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, ...changes };
        } else {
          return { ...item };
        }
      })
    );
  };
  const deleteQuestion = (itemId: string) => {
    setQuestionItems((prevItems) =>
      prevItems.filter((item) => {
        if (item.id === itemId) {
          return false;
        } else {
          return true;
        }
      })
    );
  };
  const duplicateQuestion = (itemId: string) => {
    let newQuestionItems = [...questionItems];
    let targetItemIndex = newQuestionItems.findIndex(
      (item) => item.id === itemId
    );
    let targetItem = newQuestionItems[targetItemIndex];
    // Create new id for the item
    let duplicatedItem = {
      ...targetItem,
      id: uuid(),
      // dbId should be handled later
      dbId: "",
      multipleChoiceOptions: targetItem.multipleChoiceOptions?.map((option) => {
        return {
          ...option,
          id: uuid(),
        };
      }),
      checkBoxOptions: targetItem.checkBoxOptions?.map((option) => {
        return {
          ...option,
          id: uuid(),
        };
      }),
      dropDownOptions: targetItem.dropDownOptions?.map((option) => {
        return {
          ...option,
          id: uuid(),
        };
      }),
    };
    newQuestionItems.splice(targetItemIndex, 0, duplicatedItem);
    setQuestionItems(newQuestionItems);
  };
  const onOptionChange = (
    itemId: string,
    optionId: string,
    changes: Record<string, any>,
    fieldKey: "multipleChoiceOptions" | "checkBoxOptions" | "dropDownOptions"
  ) => {
    setQuestionItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === itemId) {
          let newOptions: any[] = [...(item[fieldKey] ?? [])];
          newOptions = newOptions.map((option) => {
            if (option.id === optionId) {
              return { ...option, ...changes };
            } else {
              return { ...option };
            }
          });
          return { ...item, [fieldKey]: newOptions };
        } else {
          return { ...item };
        }
      });
    });
  };
  const onOptionAdd = (
    itemId: string,
    fieldKey: "multipleChoiceOptions" | "checkBoxOptions" | "dropDownOptions"
  ) => {
    setQuestionItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          let oldOptions = [...(item[fieldKey] ?? [])];
          return {
            ...item,
            [fieldKey]: [
              ...oldOptions,
              {
                id: uuid(),
                title: "Option " + (oldOptions.length + 1),
              },
            ],
          };
        } else {
          return { ...item };
        }
      })
    );
  };
  const onOptionDelete = (
    itemId: string,
    optionId: string,
    fieldKey: "multipleChoiceOptions" | "checkBoxOptions" | "dropDownOptions"
  ) => {
    setQuestionItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          let oldOptions = [...(item[fieldKey] ?? [])];
          return {
            ...item,
            [fieldKey]: oldOptions.filter((option) => option.id !== optionId),
          };
        } else {
          return { ...item };
        }
      })
    );
  };

  return (
    <SortableContext
      items={questionItems}
      strategy={verticalListSortingStrategy}
    >
      <Stack spacing={2} width={"100%"} ref={setNodeRef} sx={style}>
        {questionItems.map((questionItem, idx) => {
          let component = null;
          switch (questionItem.type) {
            case "short-answer":
              component = (
                <ShortAnswerCard
                  index={_.get(questionToIndex, questionItem.id, 0)}
                  arrayIndex={idx}
                  key={questionItem.id}
                  id={questionItem.id}
                  dbId=""
                  required={questionItem.required}
                  shuffle={questionItem.shuffle}
                  description={questionItem.description}
                  showDescription={questionItem.showDescription}
                  isEditing={questionItem.isEditing}
                  isDragging={activeItemId?.toString() === questionItem.id}
                  title={questionItem.title}
                  onQuestionChange={changeQuestion}
                  onQuestionDelete={deleteQuestion}
                  onQuestionDuplicate={duplicateQuestion}
                  onClick={(event) => {
                    onClick(event, questionItem.id);
                  }}
                  moveQuestion={moveQuestion}
                  t={t}
                />
              );
              break;
            case "paragraph":
              component = (
                <ParagraphCard
                  index={_.get(questionToIndex, questionItem.id, 0)}
                  arrayIndex={idx}
                  key={questionItem.id}
                  id={questionItem.id}
                  dbId=""
                  required={questionItem.required}
                  shuffle={questionItem.shuffle}
                  description={questionItem.description}
                  showDescription={questionItem.showDescription}
                  isEditing={questionItem.isEditing}
                  isDragging={activeItemId?.toString() === questionItem.id}
                  title={questionItem.title}
                  onQuestionChange={changeQuestion}
                  onQuestionDelete={deleteQuestion}
                  onQuestionDuplicate={duplicateQuestion}
                  onClick={(event) => {
                    onClick(event, questionItem.id);
                  }}
                  moveQuestion={moveQuestion}
                  t={t}
                />
              );
              break;
            case "multiple-choice":
              component = (
                <MultipleChoiceCard
                  index={_.get(questionToIndex, questionItem.id, 0)}
                  arrayIndex={idx}
                  key={questionItem.id}
                  id={questionItem.id}
                  dbId=""
                  required={questionItem.required}
                  shuffle={questionItem.shuffle}
                  description={questionItem.description}
                  showDescription={questionItem.showDescription}
                  isEditing={questionItem.isEditing}
                  isDragging={activeItemId?.toString() === questionItem.id}
                  title={questionItem.title}
                  multipleChoiceOptions={questionItem.multipleChoiceOptions}
                  otherOption={questionItem.otherOption}
                  onQuestionChange={changeQuestion}
                  onQuestionDelete={deleteQuestion}
                  onQuestionDuplicate={duplicateQuestion}
                  onClick={(event) => {
                    onClick(event, questionItem.id);
                  }}
                  onOptionChange={(itemId, optionId, changes) => {
                    onOptionChange(
                      itemId,
                      optionId,
                      changes,
                      "multipleChoiceOptions"
                    );
                  }}
                  onOptionAdd={(itemId) => {
                    onOptionAdd(itemId, "multipleChoiceOptions");
                  }}
                  onOptionDelete={(itemId, optionId) => {
                    onOptionDelete(itemId, optionId, "multipleChoiceOptions");
                  }}
                  toggleOtherOption={(on) => {
                    setQuestionItems((prevItems) =>
                      prevItems.map((item) => {
                        if (item.id === questionItem.id) {
                          return { ...item, otherOption: on };
                        } else {
                          return { ...item };
                        }
                      })
                    );
                  }}
                  moveQuestion={moveQuestion}
                  t={t}
                />
              );
              break;
            case "checkboxes":
              component = (
                <CheckBoxCard
                  index={_.get(questionToIndex, questionItem.id, 0)}
                  arrayIndex={idx}
                  key={questionItem.id}
                  id={questionItem.id}
                  dbId=""
                  required={questionItem.required}
                  shuffle={questionItem.shuffle}
                  description={questionItem.description}
                  showDescription={questionItem.showDescription}
                  isEditing={questionItem.isEditing}
                  isDragging={activeItemId?.toString() === questionItem.id}
                  title={questionItem.title}
                  checkBoxOptions={questionItem.checkBoxOptions}
                  otherOption={questionItem.otherOption}
                  onQuestionChange={changeQuestion}
                  onQuestionDelete={deleteQuestion}
                  onQuestionDuplicate={duplicateQuestion}
                  onClick={(event) => {
                    onClick(event, questionItem.id);
                  }}
                  onOptionChange={(itemId, optionId, changes) => {
                    onOptionChange(
                      itemId,
                      optionId,
                      changes,
                      "checkBoxOptions"
                    );
                  }}
                  onOptionAdd={(itemId) => {
                    onOptionAdd(itemId, "checkBoxOptions");
                  }}
                  onOptionDelete={(itemId, optionId) => {
                    onOptionDelete(itemId, optionId, "checkBoxOptions");
                  }}
                  toggleOtherOption={(on) => {
                    setQuestionItems((prevItems) =>
                      prevItems.map((item) => {
                        if (item.id === questionItem.id) {
                          return { ...item, otherOption: on };
                        } else {
                          return { ...item };
                        }
                      })
                    );
                  }}
                  moveQuestion={moveQuestion}
                  t={t}
                />
              );
              break;
            case "dropdown":
              component = (
                <DropDownCard
                  index={_.get(questionToIndex, questionItem.id, 0)}
                  arrayIndex={idx}
                  key={questionItem.id}
                  id={questionItem.id}
                  dbId=""
                  required={questionItem.required}
                  shuffle={questionItem.shuffle}
                  description={questionItem.description}
                  showDescription={questionItem.showDescription}
                  isEditing={questionItem.isEditing}
                  isDragging={activeItemId?.toString() === questionItem.id}
                  title={questionItem.title}
                  dropDownOptions={questionItem.dropDownOptions}
                  onQuestionChange={changeQuestion}
                  onQuestionDelete={deleteQuestion}
                  onQuestionDuplicate={duplicateQuestion}
                  onClick={(event) => {
                    onClick(event, questionItem.id);
                  }}
                  onOptionChange={(itemId, optionId, changes) => {
                    onOptionChange(
                      itemId,
                      optionId,
                      changes,
                      "dropDownOptions"
                    );
                  }}
                  onOptionAdd={(itemId) => {
                    onOptionAdd(itemId, "dropDownOptions");
                  }}
                  onOptionDelete={(itemId, optionId) => {
                    onOptionDelete(itemId, optionId, "dropDownOptions");
                  }}
                  moveQuestion={moveQuestion}
                  t={t}
                />
              );
              break;
            case "divider":
              component = (
                <DividerCard
                  key={questionItem.id}
                  id={questionItem.id}
                  arrayIndex={idx}
                  dbId=""
                  title=""
                  isEditing={questionItem.isEditing}
                  isDragging={activeItemId?.toString() === questionItem.id}
                  onClick={(event) => {
                    onClick(event, questionItem.id);
                  }}
                  onQuestionDelete={deleteQuestion}
                  onQuestionDuplicate={duplicateQuestion}
                  moveQuestion={moveQuestion}
                  t={t}
                />
              );
              break;
            default:
              <div key={questionItem.id}>{t("createSurvey.error")}</div>;
          }
          return component;
        })}
      </Stack>
    </SortableContext>
  );
}

// export default function QuestionDroppable({
//   items,
//   onQuestionChange,
//   onClick,
//   isDragging = false,
// }: IQuestionDroppable) {
//   const { setNodeRef } = useDroppable({
//     id: "question-droppable",
//   });
//   const theme = useTheme();

//   const style = isDragging
//     ? {
//         backgroundColor: "#eeeeee",
//         borderStyle: "dashed",
//         borderColor: "#e0e0e0",
//         borderRadius: "16px",
//         padding: 2,
//       }
//     : { padding: 2 };

//   return (
//     <Stack spacing={2} width={"100%"} ref={setNodeRef} sx={style}>
//       {items.map((item, idx) => {
//         let component = null;
//         switch (item.type) {
//           case "short-answer":
//             component = (
//               <ShortAnswerCard
//                 id={item.id}
//                 key={item.id}
//                 isEditing={item.isEditing}
//                 isDragging={item.isDragging}
//                 title={item.title}
//                 onQuestionChange={onQuestionChange}
//                 onClick={(event) => {
//                   onClick(event, item.id);
//                 }}
//               />
//             );
//             break;
//           case "paragraph":
//             component = <div key={item.id}>{item.title}</div>;
//             break;
//           case "multiple-choice":
//             component = <div key={item.id}>{item.title}</div>;
//             break;
//           case "checkboxes":
//             component = <div key={item.id}>{item.title}</div>;
//             break;
//           case "dropdown":
//             component = <div key={item.id}>{item.title}</div>;
//             break;
//           default:
//             <div key={item.id}>Error</div>;
//         }
//         return component;
//       })}
//     </Stack>
//   );
// }
