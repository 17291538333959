import * as React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, IconButton, Menu, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshUser from "../hooks/useRefreshUser";
import logo from "../images/logo.png";
import NavUserSection from "./NavUserSection";

const pages = ["Home", "My Survey"];

const pagesText: Record<string, string> = {
  Home: "topAppBar.nav.home",
  "My Survey": "topAppBar.nav.mySurvey",
};

interface TopAppBarProps {
  sx?: Record<string, any>;
  position?: "relative" | "absolute" | "fixed" | "static" | "sticky";
  showCreateButton?: boolean;
  className?: string; // Add className prop
  t: any;
}

export default function TopAppBar({
  sx = {},
  position = "relative",
  showCreateButton = true,
  className, // Destructure className prop
  t,
}: TopAppBarProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { auth } = useAuth();
  const refreshUser = useRefreshUser();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  React.useEffect(() => {
    refreshUser();
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (itemName: string) => {
    setAnchorElNav(null);
    switch (itemName) {
      case "Home":
        navigate("/");
        break;
      case "My Survey":
        navigate("/my-surveys");
        break;
      default:
        break;
    }
  };

  return (
    <AppBar
      position={position}
      className={className}
      style={{ background: "#FFFFFF" }}
      sx={{
        zIndex: theme.zIndex.appBar,
        ...sx,
      }}
    >
      <Toolbar>
        {/* Normal navbar */}
        <Button
          variant="text"
          onClick={() => {
            navigate("/");
          }}
          sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}
        >
          <Avatar src={logo} sx={{ mr: 1 }}></Avatar>
          <Typography
            align="left"
            variant="h6"
            color="black"
            noWrap
            sx={{
              fontWeight: 700,
              textDecoration: "none",
            }}
          >
            SURVEY SKY
          </Typography>
        </Button>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page}
              onClick={() => {
                handleCloseNavMenu(page);
              }}
              sx={{ my: 2, color: "black", display: "block" }}
            >
              {t(pagesText[page])}
            </Button>
          ))}
          {/* {showCreateButton && (
            <Button
              variant="contained"
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              Create
            </Button>
          )} */}
        </Box>
        {/* Displayed in mobile or small screen */}
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{ color: "black" }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page}
                onClick={() => {
                  handleCloseNavMenu(page);
                }}
              >
                <Typography textAlign="center">{t(pagesText[page])}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Button
          variant="text"
          href="/"
          sx={{ display: { xs: "flex", md: "none" }, mr: 2, flexGrow: 1 }}
        >
          <Avatar src={logo} sx={{ mr: 1 }}></Avatar>
          <Typography
            align="left"
            variant="h6"
            color="black"
            noWrap
            sx={{
              fontWeight: 700,
              textDecoration: "none",
            }}
          >
            SURVEY SKY
          </Typography>
        </Button>
        {/* Point section, hide in small screen */}
        <NavUserSection auth={auth} t={t} />
      </Toolbar>
    </AppBar>
  );
}
