import axios, { authAxiosInstance, getJWTHeader } from "../Axios";
import { SurveyValue } from "../../pages/CreateSurvey";

const routers = {
  // For the route that requires authentication, we use authAxiosInstance instead of axios
  // For nomral, public routes, we use axios
  getSurvey: async (accessToken: string) =>
    authAxiosInstance.get("/my-survey/", getJWTHeader(accessToken)),
  getSurveyReportSummary: async (accessToken: string, surveyId: string) =>
    authAxiosInstance.get(
      "/my-survey/report/summary/".concat(surveyId),
      getJWTHeader(accessToken)
    ),
  getSurveyReportAnswers: async (accessToken: string, surveyId: string) =>
    authAxiosInstance.get(
      "/my-survey/report/answers/".concat(surveyId),
      getJWTHeader(accessToken)
    ),
  createSurvey: async (accessToken: string, surveyType: string) =>
    authAxiosInstance.post(
      "/my-survey/",
      { surveyType: surveyType },
      getJWTHeader(accessToken)
    ),
  getSurveyById: async (accessToken: string, surveyId: string) =>
    authAxiosInstance.get(
      "/my-survey/".concat(surveyId),
      getJWTHeader(accessToken)
    ),
  updateSurvey: async (
    accessToken: string,
    surveyId: string,
    surveyItem: any
  ) =>
    authAxiosInstance.patch(
      "/my-survey/".concat(surveyId),
      surveyItem,
      getJWTHeader(accessToken)
    ),
  deleteSurveyById: async (accessToken: string, surveyId: string) =>
    authAxiosInstance.delete(
      "/my-survey/".concat(surveyId),
      getJWTHeader(accessToken)
    ),
  // Add more API endpoints here
};

export default routers;
