import * as React from "react";
import _ from "lodash";
import { Theme } from "@mui/material/styles";
import { QuestionValue } from "../components/SurveyQuestions";
import {
  DndContext,
  DragOverlay,
  DragEndEvent,
  UniqueIdentifier,
  DropAnimation,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Typography,
  Stack,
  Button,
  Fab,
  Zoom,
  useTheme,
} from "@mui/material";
import {
  ShortText as ShortTextIcon,
  Subject as SubjectIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckBoxIcon,
  ArrowDropDownCircle as ArrowDropDownCircleIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  Splitscreen as SplitscreenIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DraggableButton, { DraggableItem } from "../components/DraggableButton";
import QuestionEditingArea from "../components/QuestionEditingArea";
import CreateSurveyMobileDrawer from "./CreateSurveyMobileDrawer";
import { DROP_ZONE_ID, STEPPER_HEIGHT, QuestionTypeToPrice } from "../constants/Constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export default function CreateSurveyQuestionPage({
  setIsDragging,
  setActiveId,
  onDragEnd,
  tabValue,
  handleChange,
  appendNewQuestion,
  setActiveStep,
  theme,
  isDragging,
  questionItems,
  setQuestionItems,
  questionToIndex,
  moveQuestion,
  activeId,
  setEditingQuestion,
  getDraggingItem,
  t,
}: {
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveId: React.Dispatch<React.SetStateAction<UniqueIdentifier | null>>;
  onDragEnd: (e: DragEndEvent) => void;
  tabValue: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  appendNewQuestion: (type: string) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  theme: Theme;
  isDragging: boolean;
  questionItems: QuestionValue[];
  setQuestionItems: React.Dispatch<React.SetStateAction<QuestionValue[]>>;
  questionToIndex: Record<string, number>;
  moveQuestion: (id: string, targetIndex: number) => void;
  activeId: UniqueIdentifier | null;
  setEditingQuestion: (itemId: string) => void;
  getDraggingItem: (activeId: UniqueIdentifier) => QuestionValue | undefined;
  t: any;
}) {
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const [openMobileDrawer, setMobileDrawerOpen] = React.useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Suspense>
      {matches && (
        <Zoom
          in={matches}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${transitionDuration.exit}ms`,
          }}
          unmountOnExit
        >
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "fixed", bottom: 16, right: 16 }}
            onClick={() => {
              setMobileDrawerOpen(true);
            }}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      )}
      <DndContext
        onDragStart={({ active }) => {
          if (!active) {
            return;
          }
          setIsDragging(true);
          setActiveId(active.id);
        }}
        onDragEnd={onDragEnd}
        onDragCancel={() => setActiveId(null)}
      >
        <CreateSurveyMobileDrawer
          open={openMobileDrawer}
          handleClose={() => setMobileDrawerOpen(false)}
          appendNewQuestion={appendNewQuestion}
          t={t}
        />
        {/* Create questions */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{
            height: {
              xs: `calc(100vh - ${STEPPER_HEIGHT.xs + 64}px)`,
              sm: `calc(100vh - ${STEPPER_HEIGHT.sm + 64}px)`,
              md: `calc(100vh - ${STEPPER_HEIGHT.md + 64}px)`,
            },
          }}
        >
          <Grid
            item
            container
            direction="column"
            sx={{
              display: { xs: "none", md: "flex" },
              borderRight: 1,
              borderColor: "rgba(0, 0, 0, 0.12)",
              p: 3,
            }}
            md={4}
          >
            <Box sx={{ width: "fit-content", display: "flex" }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                {t("createSurvey.createQuestion")}
              </Typography>
            </Box>
            <Box sx={{ mt: 4, borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Question Type" {...a11yProps(0)} />
                {/* <Tab label="My Templates" {...a11yProps(1)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2} sx={{ width: "100%" }}>
                {/* Input */}
                <Grid
                  item
                  container
                  direction={"column"}
                  xs={12}
                  xl={6}
                  justifyContent="start"
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      {t("createSurvey.InputQType")}
                    </Typography>
                    <Typography variant="subtitle2">
                      {QuestionTypeToPrice["short-answer"]} pts
                    </Typography>
                    <DraggableButton
                      id="short-answer"
                      icon={<ShortTextIcon />}
                      onClick={appendNewQuestion}
                    >
                      {t("createSurvey.shortAnswer")}
                    </DraggableButton>
                    <Typography variant="subtitle2">
                      {QuestionTypeToPrice["paragraph"]} pts
                    </Typography>
                    <DraggableButton
                      id="paragraph"
                      icon={<SubjectIcon />}
                      onClick={appendNewQuestion}
                    >
                      {t("createSurvey.paragraph")}
                    </DraggableButton>
                  </Stack>
                </Grid>
                {/* Choice */}
                <Grid
                  item
                  container
                  direction={"column"}
                  xs={12}
                  xl={6}
                  justifyContent="start"
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      {t("createSurvey.choiceQType")} - {QuestionTypeToPrice["multiple-choice"]}pt
                    </Typography>
                    <DraggableButton
                      id="multiple-choice"
                      icon={<RadioButtonCheckedIcon />}
                      onClick={appendNewQuestion}
                    >
                      {t("createSurvey.multipleChoice")}
                    </DraggableButton>
                    <DraggableButton
                      id="checkboxes"
                      icon={<CheckBoxIcon />}
                      onClick={appendNewQuestion}
                    >
                      {t("createSurvey.checkboxes")}
                    </DraggableButton>
                    <DraggableButton
                      id="dropdown"
                      icon={<ArrowDropDownCircleIcon />}
                      onClick={appendNewQuestion}
                    >
                      {t("createSurvey.dropdown")}
                    </DraggableButton>
                  </Stack>
                </Grid>
                {/* Section */}
                <Grid
                  item
                  container
                  direction={"column"}
                  xs={12}
                  xl={6}
                  justifyContent="start"
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      {t("createSurvey.choiceQType")}
                    </Typography>
                    <DraggableButton
                      id="divider"
                      icon={<SplitscreenIcon />}
                      onClick={appendNewQuestion}
                    >
                      {t("createSurvey.divider")}
                    </DraggableButton>
                  </Stack>
                </Grid>
              </Grid>
            </TabPanel>
            <Box sx={{ display: "flex", mt: "auto" }}>
              <Button
                variant="contained"
                size="large"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  setActiveStep(0);
                }}
              >
                {t("createSurvey.back")}
              </Button>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                sx={{ ml: "auto" }}
                onClick={() => {
                  setActiveStep(2);
                }}
              >
                {t("createSurvey.next")}
              </Button>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={8}
            justifyContent="center"
            sx={{
              backgroundColor: theme.palette.greyBackground?.main || "#f5f5f5",
              overflow: "auto",
              height: "100%",
            }}
          >
            <Grid container item xs={12} sm={10} xl={8} sx={{ py: 4 }}>
              <QuestionEditingArea
                areaId={DROP_ZONE_ID}
                isDragging={isDragging}
                questionItems={questionItems}
                setQuestionItems={setQuestionItems}
                questionToIndex={questionToIndex}
                moveQuestion={moveQuestion}
                activeItemId={activeId}
                onClick={(e, itemId) => {
                  setEditingQuestion(itemId);
                }}
                t={t}
              ></QuestionEditingArea>
            </Grid>
          </Grid>
        </Grid>
        <DragOverlay dropAnimation={dropAnimationConfig}>
          {activeId ? (
            <DraggableItem
              questionToIndex={questionToIndex}
              item={getDraggingItem && getDraggingItem(activeId)}
              t={t}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </React.Suspense>
  );
}
