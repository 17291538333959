import * as React from "react";
import _ from "lodash";
import * as yup from "yup";
import {
  Box,
  Button,
  Container,
  Stack,
  createTheme,
  ThemeProvider,
  Backdrop,
  CircularProgress,
  styled,
} from "@mui/material";
import { SurveyValue } from "./CreateSurvey";
import {
  TitleCard,
  ShortAnswerCard,
  ParagraphCard,
  MultipleChoiceCard,
  CheckBoxCard,
  DropDownCard,
} from "../components/SurveyAnswering";
import { QuestionValue } from "../components/SurveyQuestions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import SurveyResponse, { AnswerObject } from "../components/SurveyResponse";
import goAPI from "../api/GoAPI";
import { DEFAULT_SURVEY_THEME } from "../constants/Constants";
import TopAppBar from "../components/TopAppBar";
import { convertToSurvey } from "../components/utils/utils";
import useRefreshUser from "../hooks/useRefreshUser";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

const StyledTopAppBar = styled(
  TopAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function SurveyResponsePage({}: {}) {
  const { t, i18n } = useTranslation();
  let { surveyId } = useParams();
  const { auth } = useAuth();
  const refreshUser = useRefreshUser();
  const [survey, setSurvey] = React.useState<SurveyValue>();
  const [isLoadingSurvey, setLoadingSurvey] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [hasSubmitted, sethasSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (auth) {
      const getSurvey = async () => {
        setLoadingSurvey(true);
        try {
          if (!surveyId) {
            throw new Error("Survey ID not found");
          }

          const { data } = await goAPI.survey.getSurveyById(
            _.get(auth, "accessToken", ""),
            surveyId
          );

          if (!data.survey) {
            throw new Error("Survey not found");
          }

          if (data.survey.submitted) {
            sethasSubmitting(true);
            setLoadingSurvey(false);
          }

          const surveyData = convertToSurvey({}, data);
          setSurvey(surveyData);
          setLoadingSurvey(false);
        } catch (error) {
          console.log(error);
          toast.error("Failed to load survey");
        } finally {
          setLoadingSurvey(false);
        }
      };

      getSurvey();
    }
  }, [auth, surveyId]);

  const submitSurvey = async (SurveyResponse: AnswerObject) => {
    try {
      if (!surveyId) {
        throw new Error("Survey ID not found");
      }
      setIsSubmitting(true);
      await goAPI.survey.submitSurvey(
        _.get(auth, "accessToken", ""),
        surveyId,
        SurveyResponse
      );
      await refreshUser();
      sethasSubmitting(true);
    } catch (error) {
      console.log(error);
      toast.error(t("surveyResponse.errorSubmit"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Suspense>
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          backgroundColor:
            survey?.theme?.background || DEFAULT_SURVEY_THEME.background,
          transition: "background-color 0.5s ease",
        }}
      >
        <StyledTopAppBar showCreateButton={false} t={t} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoadingSurvey}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 0,
            },
          }}
        >
          {
            // If the survey is not found, then show the error message
            survey && (
              <SurveyResponse
                user={auth?.user}
                survey={survey}
                isPreview={false}
                submitSurvey={submitSurvey}
                isSubmitting={isSubmitting}
                hasSubmitted={hasSubmitted}
                t={t}
              ></SurveyResponse>
            )
          }
        </Box>
      </Box>
    </React.Suspense>
  );
}
