import StarsIcon from "@mui/icons-material/Stars";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Hidden
} from "@mui/material";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import goAPI from "../api/GoAPI";
import useLogout from "../hooks/useLogout";
import useRefreshUser from "../hooks/useRefreshUser";

import React from "react";
import { toast } from "react-toastify";

const loggedInSettings = ["Logout"];
const notLoggedInSettings = ["Login"];
const userNavMenuText: Record<string, string> = {
  Logout: "topAppBar.nav.logout",
  Login: "topAppBar.nav.login",
};

interface NavUserSectionProps {
  auth: any;
  t: any;
}

export default function NavUserSection({ auth, t }: NavUserSectionProps) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();
  const refreshUser = useRefreshUser();

  React.useEffect(() => {
    refreshUser();
  }, []);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const login = () => {
    navigate("/login", { state: { from: location } });
  };

  const signOut = async () => {
    await logout();
  };

  const handleCloseUserMenu = (itemName: string) => {
    setAnchorElUser(null);
    if (itemName === "Logout") signOut();
    else if (itemName === "Login") login();
  };

  return (
    <>
      {!_.isEmpty(auth) && (
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            mr: 2,
            alignItems: "center",
          }}
        >
          <StarsIcon
            sx={{
              color: theme.palette.primary.main,
              margin: "5px",
              marginLeft: "auto",
            }}
          />
          <Typography color="black">{_.get(auth, "user.points", 0)}</Typography>
        </Box>
      )}

      <Box
        sx={{
          flexGrow: {
            xs: 1,
            md: 0,
          },
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar src="" />
        </IconButton>
        {_.isEmpty(auth) ? (
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {notLoggedInSettings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  handleCloseUserMenu(setting);
                }}
              >
                <Typography textAlign="center">
                  {t(userNavMenuText[setting])}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Hidden mdUp>
              <ListItem button={false}>
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                    },
                    mr: 2,
                    alignItems: "center",
                  }}
                >
                  <StarsIcon
                    sx={{
                      color: theme.palette.primary.main,
                      margin: "5px",
                      marginLeft: "auto",
                    }}
                  />
                  <Typography color="black">
                    {_.get(auth, "user.points", 0)}
                  </Typography>
                </Box>
              </ListItem>
            </Hidden>
            {loggedInSettings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  handleCloseUserMenu(setting);
                }}
              >
                <Typography textAlign="center">
                  {t(userNavMenuText[setting])}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    </>
  );
}
