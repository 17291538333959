"use client";
import { createContext, useState, ReactNode, useEffect } from "react";

type User = {
  username: string;
  email?: string;
};

type Auth = { user?: User; accessToken?: string }; // Replace 'any' with the actual type for auth data

type AuthData = {
  auth?: Auth; // Replace 'any' with the actual type for auth data
  setAuth: React.Dispatch<React.SetStateAction<any>>; // Replace 'any' with the actual type for setAuth function
  persist: boolean;
  setPersist: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthContext = createContext<AuthData>({
  setAuth: () => {},
  setPersist: () => {},
  persist: JSON.parse(localStorage.getItem("persist") || "false"),
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<any>({}); // Replace 'any' with the actual type for auth state
  const [persist, setPersist] = useState<boolean>(
    JSON.parse(localStorage.getItem("persist") || "false")
  );

  useEffect(() => {}, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
