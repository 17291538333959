import React from 'react';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Copyright from '../components/Copyright';

const termsOfServiceContent = `
## OVERVIEW
SurveySky is a platform provided by BizJapan, a student organization based in Japan with participants all over the world. SurveySky users can promote surveys for academic or commercial purposes and can collect points by participating in surveys of other users. With these points, users can advance their own surveys in a ranking in which all surveys promoted on SurveySky appear. The use of SurveySky is only possible upon acceptance of the General Terms and Conditions (GTC) of SurveySky. For the collection, saving and processing of your data, please read our Privacy Policy.

&nbsp;

## SECTION 1 - REGISTRATION; CREATING A USER ACCOUNT; CONTRACT CONCLUSION; CONTRACT LANGUAGE

1. By clicking the register button you confirm that you fulfill these requirements.
2. Only users aged 15 or above may register with SurveySky. 
3. Registration is only permitted if a password-protected email address is used, through which we can reliably reach you. The use of so-called "disposable" or "trashmail" email addresses is not permitted and may result in your user account being blocked without prior notice. You are advised to use an email address provided by an institution you belong to, such as university emails.
4. The information entered during the registration process must be complete and truthful. After clicking the register button, you will receive a confirmation email containing a confirmation link. Click the confirmation link to complete the registration and to conclude the contract on the basis of these GTC.
5. It is forbidden to register and operate more than one user account by one person.
6. User accounts are not transferable. You must not pass on your log-in details of your user account to third parties.
7. **The contract language is English. Translations of these GTC to other languages are for information only. In the event of contradictions between the English text and the translations, the English text shall have priority.**

&nbsp;

## SECTION 2 - FREE USE OF SURVEYCIRCLE; AVAILABILITY; WARRANTY

Registration and usage of SurveySky is entirely free of charge. We believe in providing you with access to our platform without any financial obligations. However, we retain the right to discontinue SurveySky at any time, and this may occur without prior notice. Please understand that such actions are taken at our discretion. In the event of SurveySky's termination, you cannot make any claims against us. This means you cannot demand:

- The continued existence or availability of certain features or functions on SurveySky. 
- The preservation of data collected from your surveys. 
- Assurance of freedom from defects in the platform. 
- A specific level of availability of SurveySky or our support services. 

We value your use of SurveySky and strive to provide a reliable and user-friendly experience. However, circumstances may require us to cease operations, and we appreciate your understanding in such cases. 


&nbsp;


## SECTION 3 - RULES FOR PLACING SURVEYS ON SURVEYSKY

1. All surveys on the platform should have meaningful content that engages with the participants. Surveys should not constantly profane language or other inappropriate means of communication.
2. All information on the survey must be truthful, valid and not script generated. The publication of false information is strictly prohibited. 
3. Users must have all respective Intellectual Property rights for all textual content and media. SurveySky is not responsible for any legal action and cannot be held liable for any IP violation change. 
4. Users may not attempt to modify or change the command building interface. 
5. Linking to external websites is strongly prohibited to protect intellectual property.
6. After the end of the survey period, you need to remove the survey from the Survey Ranking without delay by closing it in your user account.If you don’t do so, SurveySky has a right to remove your survey from (name) without notifying you.
7. The surveys you promote and link on SurveyCircle must fulfill the following minimum requirements: 
   - They must not contain the contents listed in section 9 of these GTC.You must ensure the anonymity of the participants. 
   - You are not permitted to consolidate the email addresses received within the scope of voucher or gift raffles with the participants' response data records.
   - The Survey Manager must ensure that the survey can be processed comfortably on the device categories recommended by him (e.g. without having to zoom in and out repeatedly). We reserve the right to limit the user's recommendation if we should notice that the survey cannot be processed comfortably with a device category recommended by the user.
   - Participation in the survey must be voluntary.
8. Furthermore, we reserve the right to remove the following surveys from the Survey Ranking:
   - Surveys with a processing time of less than three minutes or more than 45 minutes
   - Surveys that lack a certain minimum measure of content quality or formal quality or a minimum measure of seriousness
   - Surveys that cannot be reached via the specified linkSurveys whose survey period has already ended
   - Surveys of Survey Managers who violate these GT



**To place a survey on SurveyCircle as a Survey Manager you need to comply with the following rules**:

1. All information you provide concerning the survey must be truthful and objective. The input fields must not be filled with sensational content that is not covered by the survey and that mainly serves the purpose of encouraging people to participate in the survey.

2. You as a Survey Manager have to make sure that you are allowed to publish and advertise your survey in connection with your supervisor’s name.
3. For each Survey Manager, the maximum number of participants that can be acquired via SurveyCircle is limited. Every time the Survey Code of your survey is redeemed on SurveyCircle, this is counted as one participant. You can learn the current maximum number of participants when setting up a survey on SurveyCircle. We reserve the right to change this number at any time, not however for surveys that have already been set online.


&nbsp;


## SECTION 4 - KEYWORDS

We reserve the right to supplement all surveys with keywords and tags for internal operation optimization and to recommend the relevant surveys to users. Users cannot opt out of having keywords on the survey. Keywords may be published to certain entities for factual verification. Users requesting to remove keywords and recommendations will be subject to a disciplinary plan.We reserve the right but do not assume any obligation to supplement your survey with keywords in order to make it even easier to find. If you do not wish these keywords, you can send us a brief email, upon which we will promptly remove the keywords. Furthermore, we reserve the right to remove keywords that are sensational and not objective and whose content is not covered by your survey.


&nbsp;


## SECTION 5 - PARTICIPATION IN SURVEYS; COLLECTION AND FORWARDING OF POINTS

1. Earning Points through 

   SurveysBy participating in surveys, all users of SurveySky can accumulate points. The number of points a user receives is determined by the following factors:

   - Survey Length: Credited points depend on the length of the survey, considering the number of questions and the type of response required by the user (multiple choice, free response, etc).
   - Relevancy to User: Users responding to surveys that match their 'Target Audience' will receive bonus points. These surveys will appear on the Recommendation page, and the bonus points credited will be determined by SurveySky and are subject to change.

2. Point Awards and Promotions

   Points may be awarded during limited-time promotions, but only through official promotions endorsed by SurveySky. Points are awarded automatically through the platform, and all point transactions are logged. SurveySky cannot modify the point values awarded or manually award points. If there are technical issues, please contact support.

3. Redeeming Points

   Points may be redeemed for various purposes, including posting surveys, receiving survey responses, and other awards determined by SurveySky. 

   - Posting Surveys: Users can post surveys using their point balance. There is a minimum base fee paid in points to post any survey on the platform, and this fee is subject to change to sustain the point system.
   - Receiving Responses: Users must maintain a sufficient point balance to receive responses for their survey. The platform will charge the posting user the same amount of points credited to each participant. If the survey poster does not maintain a sufficient point balance, survey responses will be hidden. The points per response are calculated based on the number and type of questions but are subject to change.
   - Awards: Points may be redeemed for other awards, including but not limited to giveaway entries, coupons, and other promotions. These awards are decided by SurveySky and are subject to change at any time.

4. Fraudulent Use of Points

   Users may only receive points through participation in surveys or limited-time promotions. All other methods of accumulating points are considered fraudulent and may result in user termination. If multiple parties are engaged in fraudulent activity, all parties will face corrective action depending on their involvement. SurveySky reserves the right to take disciplinary action against fraudulent accumulation, including but not limited to removal of points, user suspension, and user termination (limited or lifetime) without notification to the user.

5. Reporting Point System Issues, Bugs, and Fraudulent Activity

   Users are expected to report all point system issues and fraudulent activity to SurveySky support promptly and without publicly disclosing the details without the direct consent of SurveySky. Failure to report such activity or engaging in malicious use may result in a fine (points), user suspension, and user termination (limited or lifetime) without notification to the user.

6. Transfer of Points

   The transfer of point balances between user accounts is strictly prohibited and will be considered fraudulent use of points.

7. Sale of Points

   Points may not be sold in any form to any outside service, store, or user. Points may only be redeemed within SurveySky for predefined use cases.

8. Prohibited Actions

   Users must not engage in actions that manipulate SurveySky's point-based incentive system, exploit it unilaterally, or jeopardize its self-regulation capacity.

9. Multiple Accounts

   Creating multiple accounts is strictly prohibited on this platform to protect the integrity of our survey data. If multiple accounts belonging to the same individual/entity/organization are discovered, all related accounts will be terminated immediately.

10. Response to Surveys

    All users are expected to participate in surveys truthfully and provide responses of reasonable length when applicable. Responses that are not completed truthfully and to a minimum reasonable level are considered fraudulent, and disciplinary action will be taken against the user without notice. Survey posters may have the ability to report fraudulent responses.

11. Prohibited Automation

    All forms of bots, automation, scripts, programs, and non-human behavior are strictly prohibited. The site reserves the right to monitor all user activity, including repeated operation patterns, navigational speed, and interactions, to identify fraudulent platform operation. Users engaging in such activities will immediately be subject to disciplinary action, including limited or lifetime removal from the platform. All points obtained using these methods will be invalidated as fraudulent accumulation of points.

&nbsp;

## SECTION 6 - VOLUNTARY BENEFITS TO SURVEYSKY

If voluntary benefits are paid to SurveySky, no invoice can be issued for this . The amounts transferred voluntarily are gross amounts.

&nbsp;

## SECTION 7 - OBLIGATIONS OF THE USERS, CONSEQUENCES OF VIOLATIONS

1. When submitting data or contents or linking to studies, the following rules shall be observed. Data, contents and surveys must not contain any of the following:
   - Contents of a racist, pornographic, obscene, or insulting nature, contents that glorify or trivialize violence or that are otherwise unlawful or offensive;
   - Contents that impair or endanger children and youths in their development or upbringing or that violate human dignity or other legal rights;
   - Contents that could incite others to commit punishable or offensive acts;Insulting or offending statements;
   - Threats against other users, providers or third parties;
   - Abusive criticism, slander, lies or misinformation that do not only serve to hide the true purpose of your study due to scientific reasons for a limited period of time;
   - Links to websites containing any of the prohibited contents listed above;
   - Any other contents that breach third-party rights;
   - Contents that contain viruses, Trojans, spyware or other malware or that could endanger or impair the security and stability of our systems.
2. Should it come to our attention that the data or contents submitted or the studies linked violate the above mentioned rules, or laws, or should a third party that asserts a violation request us to do so, we may remove the respective data, contents or links without delay and block the violating user and/or delete his user account and exclude him from the future use of SurveySky as a registered user. The same shall apply if the user breaches other obligations under these terms. We reserve the right to assert further claims, e.g. damages.

&nbsp;

## SECTION 8 - GRANTING THE RIGHTS OF THE USE, THIRD-PARTY CONTENTS, ASSURANCE OF OWNERSHIP OF RIGHT

1. By entering data or placing content you grant us a non-exclusive, free-of-charge right to use the data or content without any limitation as to place and time in order to make the data or content publicly available on SurveySky. We do not need more rights and expressly consider such data or contents to be third-party content.
2. You assure that you hold the rights to all data and content you submit to surveyskySurveyCircle, which are required for granting rights pursuant to subsection (1), and that this does not breach any third-party rights, including personality rights.
3. We reserve the right to assert further claims, e.g. damages and to exclude the user from the use of SurveySky.

&nbsp;

## SECTION 9 - TERMINATION OF THE CONTRACT

Survey Managers and Research Enthusiasts can terminate this contract at any time by deleting their user account. For more information, please read section 7 of our Privacy Policy.

&nbsp;

## SECTION 10 - LIMITATION OF OUR LIABILITY

1. We are not liable for the compliance of our users with the above-mentioned rules.

2. In any other case, we shall only be liable for intent and gross negligence.

3. The limitation of our liability shall not apply in the event of injury to life, body and/or health and in the event of liability.

4. We shall not be liable for any events of force majeure that make the contractual performance impossible, even if such events merely impair the due performance of the contract to a significant extent or hinder it temporarily. Force majeure comprises all circumstances that are independent from the will and influence of the contracting parties, such as terror attacks, embargo, confiscation, natural disasters, strike, official orders or other serious and unforeseeable circumstances for which the contracting parties are not responsible. In this context, a circumstance will be regarded as force majeure only if it occurs after the conclusion of the contract.

5. We shall not be liable for disruptions and loss of quality of data transfer via the internet for which we are not responsible and which make more difficult or impede the use of the contractual services. We shall not be liable for the loss of data or contents submitted to surveysky.

6. Insofar as our liability is excluded or limited, this shall also apply to the liability of our staff members, representatives and agents.

&nbsp; 

## SECTION 11 - APPLICABLE LAW; MANDATORY CONSUMER PROTECTION REGULATIONS

This contract shall be governed by Japanese law.. Mandatory consumer protection regulations under the law of the state in which a consumer has his habitual abode remain unaffected. A consumer is any natural person that concludes a legal transaction for purposes largely not related to its commercial or self-employed activity.

&nbsp;

## SECTION 12 - AMENDMENTS TO THESE GTC

We reserve the right to amend these GTC in case of introduction of new features, amendments to our business concept and/or changes of the legal situation in accordance with the following procedure:You will be sent the amended GTC by email to your email address, indicating the respective amendments. If you do not object within two weeks of the receipt of the said email, your approval of the respective amendments will be deemed granted. If you object to the amendments within this period, we may exclude you within four weeks after your objection from the use of Survey Circle and delete your user profile.

&nbsp;

## SECTION 13 - SEVERABILITY

Individual provisions of these GTC should be fully or partially invalid,unenforceable, otherwise, it will become invalid or unenforceable after the conclusion of the contract. However, his shall not affect the validity of the remaining part of the contract.  
`;

const TermsOfServicePage = () => {
  const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '55px',
    maxWidth: 'calc(100vw - 40px)',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif', // Changed the font to Arial for formality
    color: '#333',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  };

  return (
    <>
      <CssBaseline />
      <Box sx={{ backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
        <Box sx={containerStyles}>
          <Box p={4} width="100%">
            <Typography variant="h2" align="center" gutterBottom style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', letterSpacing: '-1px' }}>
              Terms of Service
            </Typography>
            <Box pt={2}>
              <ReactMarkdown>{termsOfServiceContent}</ReactMarkdown>
            </Box>
            <Box mt={10} textAlign="center">
              <Copyright sx={{ mt: 8, mb: 4 }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TermsOfServicePage;
