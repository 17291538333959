import { Outlet } from "react-router";
import { injectStyle } from "react-toastify/dist/inject-style";
import ReactGA from "react-ga";

const TRACKING_ID = "G-P92HV0T7QG"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// CALL IT ONCE IN YOUR APP
if (typeof window !== "undefined") {
  injectStyle();
}

export default function App() {
  return (
    <main>
      <Outlet />
    </main>
  );
}
