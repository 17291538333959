import axios, { authAxiosInstance, getJWTHeader } from "../Axios";

const routers = {
  register: async (payload: object) => axios.post("/auth/register", payload),
  login: async (payload: object) =>
    authAxiosInstance.post("/auth/login", payload),
  refreshToken: async () => authAxiosInstance.get("/auth/refresh-tokens"),
  getUserFromToken: async (accessToken: string) =>
    authAxiosInstance.get("/auth/get-user", getJWTHeader(accessToken)),
  logout: async () => authAxiosInstance.get("/auth/logout"),
  // Add more API endpoints here
};

export default routers;
