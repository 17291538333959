import * as React from "react";
import _ from "lodash";
import { styled, useTheme } from "@mui/material/styles";
import TopAppBar from "../components/TopAppBar";
import CreateSurveyStepper from "../components/CreateSurveyStepper";
import CreateSurveyHeaderPage from "../components/CreateSurveyHeaderPage";
import CreateSurveyThemePage from "../components/CreateSurveyThemePage";
import { QuestionValue } from "../components/SurveyQuestions";
import { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import {
  Box,
  IconButton,
  Paper,
  Button,
  Tooltip,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  ShortText as ShortTextIcon,
  Subject as SubjectIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckBoxIcon,
  ArrowDropDownCircle as ArrowDropDownCircleIcon,
  Splitscreen as SplitscreenIcon,
} from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import { arrayMove } from "@dnd-kit/sortable";
import {
  DEFAULT_SURVEY_THEME,
  DRAGGABLE_TYPES,
  STEPPER_HEIGHT,
  DROP_ZONE_ID,
  SURVEY_STATUS,
  GOOGLEFORM_TYPE,
  CREATE_SURVEY_STEPS,
  SURVEYSKY_TYPE,
} from "../constants/Constants";
import CreateSurveySubmitPage from "../components/CreateSurveySubmitPage";
import CreateSurveyQuestionPage from "../components/CreateSurveyQuestionPage";
import { useParams } from "react-router";
import goAPI from "../api/GoAPI";
import UserContext from "../context/AuthProvider";
import { toast } from "react-toastify";
import { convertToSurvey } from "../components/utils/utils";
import { ThemeProvider } from "@emotion/react";
import SaveSurveyModal from "../components/SaveSurveyModal";
import DeleteSurveyModal from "../components/DeleteSurveyModal";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import useAuth from "../hooks/useAuth";
import useRefreshUser from "../hooks/useRefreshUser";
import { useTranslation } from "react-i18next";

const StyledTopAppBar = styled(
  TopAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));


const settings = ["deleteSurvey"];

export interface SurveyThemeValue {
  color: string;
  background: string;
}

export interface SurveyTargetAudienceValue {
  type: string;
  value: string;
}

export interface SurveyValue {
  id: string;
  title: string;
  description: string;
  tags: string[];
  questions: QuestionValue[];
  theme: SurveyThemeValue;
  status: string;
  credits: number;
  targetAudience: SurveyTargetAudienceValue[];
  surveyType: string;
  gfCode?: string;
  gfLink?: string;
  language: string;
}

export default function CreateSurvey() {
  // Prevent user from leaving page
  // TODO: Add detech whether there is an unsaved change
  // React.useEffect(() => {
  //   const unloadCallback = (event: any) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  // Get surveyId from the url
  let { surveyId } = useParams();
  const { auth } = useAuth();
  const refreshUser = useRefreshUser();
  const [accessToken, setAccessToken] = React.useState(
    _.get(auth, "accessToken", "")
  );
  const [isInitializing, setInitializing] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  // For draggable items
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
  // For stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [tabValue, setTabValue] = React.useState(0);
  const [questionItems, setQuestionItems] = React.useState<QuestionValue[]>([]);
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [questionToIndex, setQuestionToIndex] = React.useState<
    Record<string, number>
  >({});
  const [indexToQuestion, setIndexToQuestion] = React.useState<
    Record<number, string>
  >({});
  const [survey, setSurvey] = React.useState<SurveyValue>({
    id: "",
    title: "",
    description: "",
    tags: [],
    questions: [],
    theme: DEFAULT_SURVEY_THEME,
    status: SURVEY_STATUS.PRIVATE,
    credits: 0,
    targetAudience: [],
    surveyType: SURVEYSKY_TYPE,
    language: "en",
  });

  const [isSaveSurveyModalOpen, setSaveSurveyModalOpen] = React.useState(false);
  const [isDeleteSurveyModalOpen, setDeleteSurveyModalOpen] =
    React.useState(false);

  const [anchorElSetting, setAnchorElSetting] =
    React.useState<null | HTMLElement>(null);

  const [steps, setSteps] = React.useState<string[]>([]);

  React.useEffect(() => {
    setAccessToken(_.get(auth, "accessToken", ""));
  }, [auth]);

  React.useEffect(() => {
    if (survey.surveyType == GOOGLEFORM_TYPE) {
      setSteps([
        CREATE_SURVEY_STEPS.HEADER,
        CREATE_SURVEY_STEPS.SELECT_THEME,
        CREATE_SURVEY_STEPS.PUBLISH_SURVEY,
      ]);
    } else {
      setSteps([
        CREATE_SURVEY_STEPS.HEADER,
        CREATE_SURVEY_STEPS.CRERATE_QUESTIONS,
        CREATE_SURVEY_STEPS.SELECT_THEME,
        CREATE_SURVEY_STEPS.PUBLISH_SURVEY,
      ]);
    }
  }, [survey]);

  // Fetch survey
  React.useEffect(() => {
    if (!accessToken || !surveyId || surveyId === "undefined") {
      return;
    }
    const fetchSurvey = async (surveyId: string) => {
      try {
        const { data } = await goAPI.mySurvey.getSurveyById(
          accessToken,
          surveyId
        );
        if (!data.survey) {
          throw new Error("Survey not found");
        }
        const surveyData = convertToSurvey({}, data);
        setSurvey(surveyData);

        setQuestionItems(surveyData.questions);
      } catch (error) {
        console.log(error);
        // If is 404 error or survey not found, then go back
        // fix 'error' is of type 'unknown'
        if (
          (error as AxiosError).response?.status === 404 ||
          (error as Error).message === "Survey not found"
        ) {
          toast.error(t("createSurvey.surveyNotFound"));
          navigate("/my-surveys", { replace: true });
        }
      } finally {
        setInitializing(false);
      }
    };

    fetchSurvey(surveyId);
  }, [accessToken, navigate, surveyId]);

  const confirmDeleteSurvey = () => {
    setDeleteSurveyModalOpen(true);
  };

  const deleteSurvey = async () => {
    if (!accessToken || !surveyId) {
      return;
    }
    try {
      setDeleteSurveyModalOpen(false);
      setLoading(true);
      // If response code is 204 then success
      const response = await goAPI.mySurvey.deleteSurveyById(
        accessToken,
        surveyId
      );
      if (response.status === 204) {
        toast.success(t("createSurvey.surveyDeleted"));
        navigate("/my-surveys");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSettingMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleCloseUserMenu = (itemName: string) => {
    setAnchorElSetting(null);
    if (itemName === "deleteSurvey") confirmDeleteSurvey();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const generateNewQuestion = (type: string) => {
    let itemId = uuid();
    let multipleChoiceitemId = uuid();
    let checkBoxitemId = uuid();
    let dropDownitemId = uuid();

    return {
      title: "",
      type: type,
      id: itemId,
      dbId: itemId,
      isEditing: true,
      multipleChoiceOptions:
        type === "multiple-choice"
          ? [
              {
                id: multipleChoiceitemId,
                title: t("createSurvey.option1"),
              },
            ]
          : [],
      checkBoxOptions:
        type === "checkboxes"
          ? [{ id: checkBoxitemId, title: t("createSurvey.option1") }]
          : [],
      dropDownOptions:
        type === "dropdown"
          ? [{ id: dropDownitemId, title: t("createSurvey.option1") }]
          : [],
    };
  };

  const appendNewQuestion = (type: string) => {
    const item = generateNewQuestion(type);
    if (!item.type || DRAGGABLE_TYPES.indexOf(item.type) === -1) {
      console.error("Invalud question type: ", type);
      return;
    }

    let temp = questionItems.map((item) => {
      return { ...item, isEditing: false };
    });
    temp.push(item);
    setQuestionItems(temp);
  };

  const moveQuestion = (id: string, targetIndex: number) => {
    if (targetIndex < 0 || targetIndex >= questionItems.length) return;
    const currentIndex = questionItems.findIndex((item) => item.id === id);
    setQuestionItems((items) => arrayMove(items, currentIndex, targetIndex));
  };

  const onDragEnd = (e: DragEndEvent) => {
    setIsDragging(false);
    setActiveId(null);

    const activeId = e.active?.id;
    const overId = e.over?.id;
    const isNew = DRAGGABLE_TYPES.includes(activeId.toString());
    if (activeId === undefined || overId === undefined) return;

    let item: QuestionValue | null | undefined = null;
    if (isNew) {
      item = generateNewQuestion(e.active.data.current?.type);
    } else {
      item = questionItems.find((item) => item.id === activeId);
    }
    if (item === null || item === undefined) return;

    let temp = [];
    // Set all other editing question to false if the item is new
    if (isNew) {
      temp = questionItems.map((item) => {
        return { ...item, isEditing: false };
      });
    } else {
      temp = [...questionItems];
    }

    if (overId === DROP_ZONE_ID && isNew) {
      temp.push(item);
      setQuestionItems(temp);
      return;
    }

    if (activeId !== overId) {
      if (isNew) {
        const overIndex = temp.findIndex((item) => item.id === overId);
        temp.splice(overIndex, 0, item);
        setQuestionItems(temp);
      } else {
        const activeIndex = temp.findIndex((item) => item.id === activeId);
        const overIndex = temp.findIndex((item) => item.id === overId);
        setQuestionItems((items) => arrayMove(items, activeIndex, overIndex));
      }
    }
  };

  const setEditingQuestion = (itemId: string) => {
    setQuestionItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, isEditing: true };
        } else {
          return { ...item, isEditing: false };
        }
      })
    );
  };

  const getDraggingItem = (activeId: UniqueIdentifier) => {
    let item = undefined;
    if (activeId === null) {
      return undefined;
    }

    const itemLookup = {
      "short-answer": {
        children: t("createSurvey.shortAnswer"),
        id: "short-answer",
        icon: <ShortTextIcon />,
      },
      paragraph: {
        children: t("createSurvey.paragraph"),
        id: "paragraph",
        icon: <SubjectIcon />,
      },
      "multiple-choice": {
        children: t("createSurvey.multipleChoice"),
        id: "multiple-choice",
        icon: <RadioButtonCheckedIcon />,
      },
      checkboxes: {
        children: t("createSurvey.checkboxes"),
        id: "checkboxes",
        icon: <CheckBoxIcon />,
      },
      dropdown: {
        children: t("createSurvey.dropdown"),
        id: "dropdown",
        icon: <ArrowDropDownCircleIcon />,
      },
      divider: {
        children: t("createSurvey.divider"),
        id: "divider",
        icon: <SplitscreenIcon />,
      },
    };

    item =
      _.get(itemLookup, activeId.toString(), undefined) ||
      questionItems.find((item) => item.id === activeId);
    return item;
  };

  const onSaveClick = async () => {
    // If survey is publised then pop save modal
    if (survey.status === SURVEY_STATUS.PUBLISHED) {
      setSaveSurveyModalOpen(true);
    } else {
      await saveSurvey();
    }
  };

  const saveSurvey = async () => {
    setSaveSurveyModalOpen(false);
    // Allowed fields
    let allowedFields = [
      "title",
      "description",
      "questions",
      "category",
      "tags",
      "theme",
      "targetAudience",
      "gfLink",
      "language",
    ];
    let reqData = _.pick(
      { ...survey, questions: questionItems },
      allowedFields
    );
    if (_.isEmpty(reqData.title) || reqData.title?.trim().length === 0) {
      toast.error(t("createSurvey.titleIsRequired"));
      return;
    }
    if (
      _.isEmpty(reqData.description) ||
      reqData.description?.trim().length === 0
    ) {
      toast.error(t("createSurvey.descriptionIsRequired"));
      return;
    }

    if (surveyId) {
      try {
        setLoading(true);
        let result = await goAPI.mySurvey.updateSurvey(
          accessToken,
          surveyId,
          reqData
        );
        if (result.status === 200) {
          toast.success(t("createSurvey.saveSuccessfully"));
          await refreshUser();
        }
      } catch (error: any) {
        console.log(error);
        toast.error(
          error?.response?.data?.message || t("createSurvey.saveFailed")
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const publishSurvey = async () => {
    // Allowed fields
    let allowedFields = [
      "title",
      "description",
      "questions",
      "category",
      "tags",
      "theme",
      "status",
      "targetAudience",
      "gfLink",
      "language",
    ];
    let reqData = _.pick(
      { ...survey, questions: questionItems },
      allowedFields
    );
    reqData.status = SURVEY_STATUS.PUBLISHED;
    if (_.isEmpty(reqData.title) || reqData.title?.trim().length === 0) {
      toast.error(t("createSurvey.titleIsRequired"));
      return;
    }
    if (
      _.isEmpty(reqData.description) ||
      reqData.description?.trim().length === 0
    ) {
      toast.error(t("createSurvey.descriptionIsRequired"));
      return;
    }

    if (surveyId) {
      try {
        setLoading(true);
        let result = await goAPI.mySurvey.updateSurvey(
          accessToken,
          surveyId,
          reqData
        );
        if (result.status === 200) {
          toast.success(t("createSurvey.surveyPublished"));
          const surveyData = convertToSurvey({}, result.data);
          setSurvey(surveyData);
          await refreshUser();
        }
      } catch (error) {
        toast.error(t("createSurvey.failedToPublish"));
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    // Setup question to index and index to question for question items
    let newQuestionToIndex: any = {};
    let newIndexToQuestion: any = {};
    let indexCounter = 1;
    for (let i in questionItems) {
      if (
        !_.isEmpty(questionItems[i].id) &&
        questionItems[i].type !== "divider"
      ) {
        let id = questionItems[i].id;
        newQuestionToIndex[id] = indexCounter;
        newIndexToQuestion[indexCounter] = id;
        indexCounter++;
      }
    }

    setQuestionToIndex(newQuestionToIndex);
    setIndexToQuestion(newIndexToQuestion);
    setSurvey((prevItem) => {
      return {
        ...prevItem,
        questions: questionItems,
      };
    });
  }, [questionItems]);

  return (
    <React.Suspense fallback={<Box>Loading</Box>}>
      <Box>
        <StyledTopAppBar position="fixed" showCreateButton={false} t={t} />
        <SaveSurveyModal
          open={isSaveSurveyModalOpen}
          handleClose={() => setSaveSurveyModalOpen(false)}
          handleOk={saveSurvey}
          t={t}
        />
        <DeleteSurveyModal
          open={isDeleteSurveyModalOpen}
          handleClose={() => setDeleteSurveyModalOpen(false)}
          handleOk={deleteSurvey}
          t={t}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || isInitializing}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {!isInitializing && (
          <>
            <Paper
              sx={{
                mt: { xs: 7, sm: 8 },
                height: {
                  ...STEPPER_HEIGHT,
                  xs: "auto",
                },
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  pt: 2,
                  pb: 2,
                  pr: 2,
                  height: {
                    ...STEPPER_HEIGHT,
                    xs: "auto",
                  },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    mt: {
                      xs: 2,
                      md: 0,
                    },
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                  }}
                >
                  <CreateSurveyStepper
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    completed={completed}
                    setCompleted={setCompleted}
                    t={t}
                  />
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                    ml: "auto",
                    mt: 0,
                    gap: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button variant="contained" onClick={onSaveClick}>
                    {t("createSurvey.save")}
                  </Button>
                  <Tooltip title="Settings">
                    <IconButton
                      color="primary"
                      aria-label="settings"
                      onClick={handleOpenSettingMenu}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
                p: 2,
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button variant="contained" onClick={onSaveClick}>
                  {t("createSurvey.save")}
                </Button>
                <Tooltip title={t("createSurvey.settings")}>
                  <IconButton
                    color="primary"
                    aria-label="settings"
                    onClick={handleOpenSettingMenu}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{}}
                  id="menu-appbar"
                  anchorEl={anchorElSetting}
                  open={Boolean(anchorElSetting)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        handleCloseUserMenu(setting);
                      }}
                    >
                      <Typography textAlign="center">
                        {t(`createSurvey.${setting}`)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
            {_.isArray(steps) && steps[activeStep] === CREATE_SURVEY_STEPS.HEADER && (
              <CreateSurveyHeaderPage
                goToNextPage={() => setActiveStep(activeStep + 1)}
                survey={survey}
                setSurvey={setSurvey}
                t={t}
              />
            )}
            {_.isArray(steps) && steps[activeStep] === CREATE_SURVEY_STEPS.CRERATE_QUESTIONS && (
              <CreateSurveyQuestionPage
                setIsDragging={setIsDragging}
                setActiveId={setActiveId}
                onDragEnd={onDragEnd}
                tabValue={tabValue}
                handleChange={handleChange}
                appendNewQuestion={appendNewQuestion}
                setActiveStep={setActiveStep}
                theme={theme}
                isDragging={isDragging}
                questionItems={questionItems}
                setQuestionItems={setQuestionItems}
                questionToIndex={questionToIndex}
                moveQuestion={moveQuestion}
                activeId={activeId}
                setEditingQuestion={setEditingQuestion}
                getDraggingItem={getDraggingItem}
                t={t}
              />
            )}
            {_.isArray(steps) && steps[activeStep] === CREATE_SURVEY_STEPS.SELECT_THEME && (
              <CreateSurveyThemePage
                goToPrevPage={() => setActiveStep(activeStep - 1)}
                goToNextPage={() => setActiveStep(activeStep + 1)}
                survey={survey}
                setSurvey={setSurvey}
                t={t}
              />
            )}
            {_.isArray(steps) && steps[activeStep] === CREATE_SURVEY_STEPS.PUBLISH_SURVEY && (
              <CreateSurveySubmitPage
                questionItems={questionItems}
                targetAudience={survey.targetAudience || []}
                handlePublish={publishSurvey}
                goToPrevPage={() => setActiveStep(activeStep - 1)}
                published={survey.status === SURVEY_STATUS.PUBLISHED}
                surveyId={survey.id}
                surveyType={survey.surveyType}
                t={t}
              />
            )}
          </>
        )}
      </Box>
    </React.Suspense>
  );
}
