import _ from "lodash";
import { DEFAULT_SURVEY_THEME } from "../../constants/Constants";

export const convertToSurvey = (currentSurvey: any = {}, data: any) => {
  return {
    ...data.survey,
    questions:
      data.survey.questions?.map((item: any) => {
        return {
          id: _.get(item, "id", item._id),
          title: item.title || "",
          type: item.type || "",
          multipleChoiceOptions:
            item.multipleChoiceOptions?.map((option: any) => {
              return {
                id: option?._id,
                title: option?.title || "",
              };
            }) || [],
          checkBoxOptions:
            item.checkBoxOptions?.map((option: any) => {
              return {
                id: option?._id,
                title: option.title || "",
              };
            }) || [],
          dropDownOptions:
            item.dropDownOptions?.map((option: any) => {
              return {
                id: option?._id,
                title: option.title || "",
              };
            }) || [],
          otherOption: item.otherOption || false,
          required: item.required || false,
          showDescription: item.showDescription || false,
          description: item.description || "",
          shuffle: item.shuffle || false,
        };
      }) || [],
    theme: {
      color: data.survey.theme?.color || DEFAULT_SURVEY_THEME.color,
      background:
        data.survey.theme?.background || DEFAULT_SURVEY_THEME.background,
    },
  };
};
