import * as React from "react";
import _ from "lodash";
import * as yup from "yup";
import {
  Box,
  Button,
  Container,
  Stack,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { SurveyValue } from "../pages/CreateSurvey";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  TitleCard,
  ShortAnswerCard,
  ParagraphCard,
  MultipleChoiceCard,
  CheckBoxCard,
  DropDownCard,
  ThankYouCard,
} from "./SurveyAnswering";
import { QuestionValue } from "./SurveyQuestions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GOOGLEFORM_TYPE } from "../constants/Constants";
import goAPI from "../api/GoAPI";

interface SurveyAnswerObject {
  answer: string | string[] | Record<string, boolean>;
  otherAnswer?: string;
}

export type AnswerObject = Record<string, SurveyAnswerObject>;

const GOOGLE_FORM_CODE_QUESTION_ID = "google-form-code";

export default function SurveyResponse({
  user,
  survey,
  isPreview,
  submitSurvey = () => {},
  isSubmitting = false,
  hasSubmitted = false,
  t,
}: {
  user?: any;
  survey: SurveyValue;
  isPreview: boolean;
  submitSurvey: (SurveyResponse: AnswerObject) => void;
  isSubmitting?: boolean;
  hasSubmitted?: boolean;
  t: any;
}) {
  let { surveyId } = useParams();
  const theme = createTheme({
    palette: {
      primary: {
        main: survey.theme.color,
      },
      background: {
        default: survey.theme.background,
      },
    },
  });
  const [surveyQuestions, setSurveyQuestions] = React.useState<any[]>([[]]);
  const [surveyAnswers, setSurveyAnswers] = React.useState<AnswerObject>({});
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [validationSchemas, setValidationSchemas] = React.useState<
    Record<string, yup.AnySchema>
  >({});
  // Error object by page, id: errorText
  const [errorObjects, setErrorObjects] = React.useState<
    Record<string, string>
  >({});
  const [googleFormCodeInput, setGoogleFormCodeInput] =
    React.useState<string>("");

  const createValidationSchema = (
    questionType: string,
    isRequired: boolean
  ) => {
    let validationSchemaObject;

    switch (questionType) {
      case "short-answer":
      case "paragraph":
        validationSchemaObject = yup.object().shape({
          answer: isRequired
            ? yup.string().required(t("surveyResponse.fieldIsRequired"))
            : yup.string(),
        });
        break;
      case "multiple-choice":
        validationSchemaObject = yup.object().shape({
          answer: isRequired
            ? yup.string().required(t("surveyResponse.fieldIsRequired"))
            : yup.string(),
          otherAnswer: yup.string().when("answer", {
            is: (val: string) => val === t("surveyResponse.other"),
            then: (schema) => schema.required(t("surveyResponse.typeOther")),
            otherwise: (schema) => schema,
          }),
        });
        break;
      case "checkboxes":
        validationSchemaObject = yup.object().shape({
          answer: yup
            .object()
            .test(
              "has-true",
              t("surveyResponse.selectAtLeastOne"),
              (value: Record<string, boolean>) => {
                if (!isRequired) {
                  // If the field is not required, skip the test
                  return true;
                }
                // If the field is required:
                // Check if the object is empty
                if (Object.keys(value).length === 0) {
                  return false;
                }
                // Check if there's at least one property with the value `true`
                for (let key in value) {
                  if (value[key] === true) {
                    return true;
                  }
                }
                return false;
              }
            ),
          otherAnswer: yup.string().optional(),
        });
        break;
      case "dropdown":
        validationSchemaObject = yup.object().shape({
          answer: isRequired
            ? yup.string().required(t("surveyResponse.fieldIsRequired"))
            : yup.string(),
          otherAnswer: yup.string().optional(),
        });
        break;
      default:
        validationSchemaObject = yup.mixed().nullable();
        break;
    }

    return validationSchemaObject;
  };
  const setupQuestionPages = (surveyQuestions: QuestionValue[]) => {
    const answerObject: AnswerObject = {};
    const validateObject: any = {};
    const questionPages: any[] = [];
    let page: QuestionValue[] = [];
    // Jump table for the feature of jump to specific question based on the answer of the previous question
    let jumpTable: Record<string, number> = {};
    surveyQuestions.forEach((question) => {
      // Initialize answer object
      if (question.type === "short-answer") {
        answerObject[question.id] = {
          answer: "",
        };
      } else if (question.type === "paragraph") {
        answerObject[question.id] = {
          answer: "",
        };
      } else if (question.type === "multiple-choice") {
        answerObject[question.id] = {
          answer: "",
          otherAnswer: "",
        };
      } else if (question.type === "checkboxes") {
        answerObject[question.id] = {
          answer: {},
          otherAnswer: "",
        };
      } else if (question.type === "dropdown") {
        answerObject[question.id] = {
          answer: "",
          otherAnswer: "",
        };
      }

      // If the question is divider, then push the page to questionPages and reset page
      if (question.type === "divider") {
        questionPages.push(page);
        page = [];
      }
      // If the question is not divider, then push the question to page
      else {
        // Create validation schema for the current question
        const validationSchema = createValidationSchema(
          question.type as string,
          question.required || false
        );
        validateObject[question.id] = validationSchema;

        page.push(question);
        jumpTable[question.id] = questionPages.length;
      }
    });

    // Push the last page to questionPages
    questionPages.push(page);
    if (questionPages.length === 0) {
      questionPages.push([]);
    }

    setSurveyQuestions(questionPages);
    setSurveyAnswers(answerObject);
    setValidationSchemas(validateObject);
  };
  const validatePage = async (page: QuestionValue[]) => {
    let hasError = false;

    const validationPromises = page.map((question) =>
      validationSchemas[question.id]
        .validate(surveyAnswers[question.id])
        .then((value) => {})
        .catch((err) => {
          setErrorObjects((prev) => {
            return {
              ...prev,
              [question.id]: err.message,
            };
          });
          hasError = true;
        })
    );

    await Promise.all(validationPromises);
    return hasError;
  };
  const resetError = (questionId: string) => {
    setErrorObjects((prev) => {
      return {
        ...prev,
        [questionId]: "",
      };
    });
  };
  const surveySubmit = async () => {
    const hasError = await validatePage(surveyQuestions[currentPage]);
    if (hasError) {
      toast.error(t("surveyResponse.checkError"));
      return;
    }

    // If survey type is google form, then check if google form code is inputted
    if (survey.surveyType === GOOGLEFORM_TYPE) {
      if (!googleFormCodeInput) {
        setErrorObjects((prev) => {
          return {
            ...prev,
            [GOOGLE_FORM_CODE_QUESTION_ID]: t(
              "surveyResponse.googleFormCodeRequired"
            ),
          };
        });
        return;
      }
      // If google form code is inputted, then check if it is correct
      try {
        const { data } = await goAPI.survey.validateGFCode(
          surveyId || "",
          googleFormCodeInput
        );

        if (!data.isValid) {
          setErrorObjects((prev) => {
            return {
              ...prev,
              [GOOGLE_FORM_CODE_QUESTION_ID]: t(
                "surveyResponse.googleFormCodeIncorrect"
              ),
            };
          });
          return;
        }
      } catch (error) {
        console.log(error);
        toast.error(t("surveyResponse.errorSubmit"));
        return;
      }
    }

    submitSurvey(surveyAnswers);
  };
  React.useEffect(() => {
    if (survey?.questions) {
      setupQuestionPages(survey.questions);
    }
  }, [survey?.questions]);

  return (
    <Container
      maxWidth="sm"
      disableGutters
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <ThemeProvider theme={theme}>
        {
          /* If the survey is not loaded, then show the loading screen */
          !survey ? (
            <Box sx={{ width: "100%", height: "100%" }}></Box>
          ) : (
            /* If the survey is loaded, then show the survey */
            <Box sx={{ width: "100%", height: "100%" }}>
              {hasSubmitted ? (
                <Stack sx={{ py: 4 }} gap={2}>
                  <ThankYouCard
                    title={t("surveyResponse.answerRecorded")}
                    text={t("surveyResponse.thankyou")}
                    headerGradientStart={theme.palette.primary.main}
                    headerGradientEnd={theme.palette.background.default}
                    t={t}
                  />
                </Stack>
              ) : (
                <Stack sx={{ py: 4 }} gap={2}>
                  <TitleCard
                    userEmail={user?.email || ""}
                    title={survey?.title || ""}
                    description={survey?.description || ""}
                    gfLink={survey?.gfLink || ""}
                    headerGradientStart={theme.palette.primary.main}
                    headerGradientEnd={theme.palette.background.default}
                    t={t}
                  />
                  {surveyQuestions[currentPage].map(
                    (question: QuestionValue) => {
                      return (
                        <div key={question.id}>
                          {question.type === "short-answer" && (
                            <ShortAnswerCard
                              id={question.id}
                              dbId=""
                              title={question.title}
                              required={question.required}
                              description={question.description}
                              theme={survey.theme}
                              answer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.answer`,
                                  ""
                                ) as string
                              }
                              errorText={_.get(errorObjects, question.id, "")}
                              setAnswer={(answer: string) => {
                                resetError(question.id);
                                setSurveyAnswers((prev) => ({
                                  ...prev,
                                  [question.id]: {
                                    answer,
                                  },
                                }));
                              }}
                              t={t}
                            />
                          )}
                          {question.type === "paragraph" && (
                            <ParagraphCard
                              key={question.id}
                              id={question.id}
                              dbId=""
                              title={question.title}
                              required={question.required}
                              description={question.description}
                              theme={survey.theme}
                              answer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.answer`,
                                  ""
                                ) as string
                              }
                              errorText={_.get(errorObjects, question.id, "")}
                              setAnswer={(answer: string) => {
                                resetError(question.id);
                                setSurveyAnswers((prev) => ({
                                  ...prev,
                                  [question.id]: {
                                    answer,
                                  },
                                }));
                              }}
                              t={t}
                            />
                          )}
                          {question.type === "multiple-choice" && (
                            <MultipleChoiceCard
                              key={question.id}
                              id={question.id}
                              dbId=""
                              title={question.title}
                              required={question.required}
                              description={question.description}
                              theme={survey.theme}
                              answer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.answer`,
                                  ""
                                ) as string
                              }
                              errorText={_.get(errorObjects, question.id, "")}
                              setAnswer={(
                                answer: string,
                                otherAnswer?: string
                              ) => {
                                resetError(question.id);
                                // If answer is the same as previous answer, then clean the answer
                                if (answer === "Other") {
                                  setSurveyAnswers((prev) => ({
                                    ...prev,
                                    [question.id]: {
                                      ...prev[question.id],
                                      answer: answer,
                                      otherAnswer: otherAnswer || "",
                                    },
                                  }));
                                } else {
                                  setSurveyAnswers((prev) => ({
                                    ...prev,
                                    [question.id]: {
                                      ...prev[question.id],
                                      answer: answer,
                                    },
                                  }));
                                }
                              }}
                              multipleChoiceOptions={
                                question.multipleChoiceOptions
                              }
                              otherAnswer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.otherAnswer`,
                                  ""
                                ) as string
                              }
                              otherOption={question.otherOption}
                              t={t}
                            />
                          )}
                          {question.type === "checkboxes" && (
                            <CheckBoxCard
                              key={question.id}
                              id={question.id}
                              dbId=""
                              title={question.title}
                              required={question.required}
                              description={question.description}
                              theme={survey.theme}
                              answer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.answer`,
                                  {}
                                ) as Record<string, boolean>
                              }
                              errorText={_.get(errorObjects, question.id, "")}
                              setAnswer={(
                                answerKey: string,
                                value: boolean,
                                otherAnswer?: string
                              ) => {
                                resetError(question.id);
                                if (answerKey === "Other") {
                                  setSurveyAnswers((prev) => ({
                                    ...prev,
                                    [question.id]: {
                                      answer: {
                                        ...(_.get(
                                          prev,
                                          `${question.id}.answer`,
                                          {}
                                        ) as Record<string, boolean>),
                                        [answerKey]: value,
                                      },
                                      otherAnswer: otherAnswer || "",
                                    },
                                  }));
                                } else {
                                  setSurveyAnswers((prev) => ({
                                    ...prev,
                                    [question.id]: {
                                      ...prev[question.id],
                                      answer: {
                                        ...(_.get(
                                          prev,
                                          `${question.id}.answer`,
                                          {}
                                        ) as Record<string, boolean>),
                                        [answerKey]: value,
                                      },
                                    },
                                  }));
                                }
                              }}
                              checkBoxOptions={question.checkBoxOptions}
                              otherAnswer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.otherAnswer`,
                                  ""
                                ) as string
                              }
                              otherOption={question.otherOption}
                              t={t}
                            />
                          )}
                          {question.type === "dropdown" && (
                            <DropDownCard
                              key={question.id}
                              id={question.id}
                              dbId=""
                              title={question.title}
                              required={question.required}
                              description={question.description}
                              theme={survey.theme}
                              answer={
                                _.get(
                                  surveyAnswers,
                                  `${question.id}.answer`,
                                  ""
                                ) as string
                              }
                              errorText={_.get(errorObjects, question.id, "")}
                              setAnswer={(answer: string) => {
                                resetError(question.id);
                                setSurveyAnswers((prev) => ({
                                  ...prev,
                                  [question.id]: {
                                    answer,
                                  },
                                }));
                              }}
                              dropDownOptions={question.dropDownOptions}
                              t={t}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                  {/* If survey is google form, then show a short answer question to allow input of google form code */}
                  {survey.surveyType === GOOGLEFORM_TYPE && (
                    <ShortAnswerCard
                      id={GOOGLE_FORM_CODE_QUESTION_ID}
                      dbId=""
                      title={t("surveyResponse.googleFormCodeTitle")}
                      required={true}
                      description={t(
                        "surveyResponse.googleFormCodeDescription"
                      )}
                      theme={survey.theme}
                      answer={googleFormCodeInput}
                      errorText={_.get(
                        errorObjects,
                        GOOGLE_FORM_CODE_QUESTION_ID,
                        ""
                      )}
                      placeholder="f6f055e2-6925-471f-8786-90f4f6bc062b"
                      setAnswer={(answer: string) => {
                        resetError(GOOGLE_FORM_CODE_QUESTION_ID);
                        setGoogleFormCodeInput(answer);
                      }}
                      t={t}
                    />
                  )}
                  {/* If current page is the last page, shows submit */}
                  {currentPage === surveyQuestions.length - 1 && (
                    <Box sx={{ display: "flex" }}>
                      {surveyQuestions.length > 1 && (
                        <Button
                          variant="outlined"
                          sx={{ backgroundColor: "white" }}
                          onClick={() => {
                            //goToPrevPage();
                            setCurrentPage(currentPage - 1);
                          }}
                        >
                          {t("surveyResponse.back")}
                        </Button>
                      )}
                      <LoadingButton
                        variant="contained"
                        sx={{ ml: "auto" }}
                        onClick={() => {
                          surveySubmit();
                        }}
                        loading={isSubmitting}
                      >
                        {t("surveyResponse.submit")}
                      </LoadingButton>
                    </Box>
                  )}
                  {/* If current page is not the last page, shows next */}
                  {currentPage !== surveyQuestions.length - 1 && (
                    <Box sx={{ display: "flex" }}>
                      {surveyQuestions.length > 1 && currentPage > 0 && (
                        <Button
                          variant="outlined"
                          sx={{ backgroundColor: "white" }}
                          onClick={() => {
                            //goToPrevPage();
                            setCurrentPage(currentPage - 1);
                          }}
                        >
                          {t("surveyResponse.back")}
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        sx={{ ml: "auto", backgroundColor: "white" }}
                        onClick={async () => {
                          // goToPrevPage();
                          const hasError = await validatePage(
                            surveyQuestions[currentPage]
                          );
                          if (!hasError) {
                            // If there are no errors, go to next page
                            setCurrentPage(currentPage + 1);
                          } else {
                            // If there are errors, handle them
                            console.log("Validation errors occurred.");
                          }
                        }}
                      >
                        {t("surveyResponse.next")}
                      </Button>
                    </Box>
                  )}
                </Stack>
              )}
            </Box>
          )
        }
      </ThemeProvider>
    </Container>
  );
}
