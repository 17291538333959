import authRouters from "./routers/AuthRouters";
import mySurveyRouters from "./routers/MySurveyRouters";
import surveyRouters from "./routers/SurveyRouters";
import pointRouters from "./routers/PointRouters";

const API = {
  auth: authRouters,
  mySurvey: mySurveyRouters,
  survey: surveyRouters,
  point: pointRouters,
};

export default API;
