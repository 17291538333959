import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import {
  TextField,
  Paper,
  Stack,
  Grid,
  Typography,
  Box,
  IconButton,
  Input,
  Button,
  Divider,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  DeleteOutline as DeleteOutlineIcon,
  ContentCopy as ContentCopyIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Shortcut as ShortcutIcon,
  Settings as SettingsIcon,
  PlayArrow as PlayArrowIcon,
  ShortText as ShortTextIcon,
  Subject as SubjectIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckBoxIcon,
  ArrowDropDownCircle as ArrowDropDownCircleIcon,
  ArrowBack as ArrowBackIcon,
  Splitscreen as SplitscreenIcon,
  Publish as PublishIcon,
} from "@mui/icons-material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { DraggableAttributes } from "@dnd-kit/core";
import useHover from "./utils/useHover";
import { styled, useTheme } from "@mui/material/styles";
import theme from "../theme";
import { v4 as uuid } from "uuid";
import { ICON_LOOK_UP, QUESTION_FORM_VALIDATION } from "../constants/Constants";

interface MultiChoiceOptionValue {
  id: string;
  title: string;
}

interface CheckBoxOptionValue {
  id: string;
  title: string;
}

interface DropDownOptionValue {
  id: string;
  title: string;
}

interface QuestionValue {
  id: string;
  title: string;
  dbId?: string;
  type?: string;
  description?: string;
  showDescription?: boolean;
  required?: boolean;
  shuffle?: boolean;
  isEditing: boolean;
  multipleChoiceOptions?: MultiChoiceOptionValue[];
  otherOption?: boolean;
  checkBoxOptions?: CheckBoxOptionValue[];
  dropDownOptions?: DropDownOptionValue[];
}

interface QuestionEvents {
  onQuestionChange: (itemId: string, changes: Record<string, any>) => void;
  onQuestionDelete: (itemId: string) => void;
  onQuestionDuplicate: (itemId: string) => void;
}

interface QuestionCardValue extends QuestionEvents, QuestionValue {
  index: number;
  arrayIndex: number;
  isDragging?: boolean;
  onClick:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  moveQuestion: (itemId: string, toIndex: number) => void;
  children?: React.ReactNode;
  style?: object | undefined;
  t: any;
}

interface DividerCardValue extends QuestionValue {
  arrayIndex: number;
  isDragging?: boolean;
  onClick:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  moveQuestion: (itemId: string, toIndex: number) => void;
  onQuestionDelete: (itemId: string) => void;
  onQuestionDuplicate: (itemId: string) => void;
  children?: React.ReactNode;
  style?: object | undefined;
  t: any;
}

interface DividerValue {}
interface ShortAnswerCardValue extends QuestionCardValue {}
interface ParagraphCardValue extends QuestionCardValue {}
interface MultiChoiceCardValue extends QuestionCardValue {
  multipleChoiceOptions?: MultiChoiceOptionValue[];
  onOptionChange?: (
    itemId: string,
    optionId: string,
    changes: Record<string, any>
  ) => void;
  onOptionAdd?: (itemId: string) => void;
  onOptionDelete?: (itemId: string, optionId: string) => void;
  toggleOtherOption?: (on: boolean) => void;
}
interface CheckBoxCardValue extends QuestionCardValue {
  checkBoxOptions?: CheckBoxOptionValue[];
  onOptionChange?: (
    itemId: string,
    optionId: string,
    changes: Record<string, any>
  ) => void;
  onOptionAdd?: (itemId: string) => void;
  onOptionDelete?: (itemId: string, optionId: string) => void;
  toggleOtherOption?: (on: boolean) => void;
}
interface DropDownCardValue extends QuestionCardValue {
  dropDownOptions?: DropDownOptionValue[];
  onOptionChange?: (
    itemId: string,
    optionId: string,
    changes: Record<string, any>
  ) => void;
  onOptionAdd?: (itemId: string) => void;
  onOptionDelete?: (itemId: string, optionId: string) => void;
  toggleOtherOption?: (on: boolean) => void;
}

interface OptionMenuItemValue {
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
}

function DragHandle({
  showHandle,
  id,
  arrayIndex,
  attributes,
  listeners,
  moveQuestion,
  t,
}: {
  id: string;
  arrayIndex: number;
  showHandle: boolean;
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
  moveQuestion: (itemId: string, toIndex: number) => void;
  t: any;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {showHandle && (
        <IconButton
          sx={{
            position: "absolute",
          }}
          size="small"
          aria-label="drag"
          disableFocusRipple
          disableRipple
          {...attributes}
          {...listeners}
        >
          <DragHandleIcon />
        </IconButton>
      )}
      {showHandle && (
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            right: "0px",
            pr: 4,
          }}
        >
          <Tooltip title={t("createSurvey.moveQuestionUp")}>
            <IconButton
              size="small"
              aria-label="up"
              onClick={() => {
                moveQuestion(id, arrayIndex - 1);
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("createSurvey.moveQuestionDown")}>
            <IconButton
              size="small"
              aria-label="down"
              onClick={() => {
                moveQuestion(id, arrayIndex + 1);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Box>
  );
}

function DraggableCardBase(props: {
  id: string;
  arrayIndex: number;
  isEditing: boolean;
  isDragging: boolean;
  onClick:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  moveQuestion: (itemId: string, toIndex: number) => void;
  children?: React.ReactNode;
  style?: object | undefined;
  t: any;
}) {
  const {
    id,
    arrayIndex,
    isEditing,
    isDragging = false,
    onClick,
    style = {},
    moveQuestion,
    t,
  } = props;

  const [hovered, hoverEventHandlers] = useHover();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const paperStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...style,
  };

  return (
    <Paper
      sx={{
        opacity: isDragging ? 0.5 : 1,
        borderRadius: "16px",
      }}
      elevation={isEditing || isDragging ? 3 : 0}
      onClick={onClick}
      ref={setNodeRef}
      variant={isEditing || isDragging ? undefined : "outlined"}
      style={paperStyle}
      {...hoverEventHandlers}
    >
      {/* Control area */}
      <DragHandle
        id={id}
        arrayIndex={arrayIndex}
        showHandle={hovered || isEditing}
        attributes={attributes}
        listeners={listeners}
        moveQuestion={moveQuestion}
        t={t}
      />
      {props.children}
    </Paper>
  );
}

function OptionMenu(props: {
  id: string;
  ariaLabeledBy?: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  menuItems: OptionMenuItemValue[];
}) {
  const { id, ariaLabeledBy, anchorEl, open, onClose, menuItems = [] } = props;

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": ariaLabeledBy,
      }}
    >
      {menuItems.map((item, index) => (
        <MenuItem key={uuid()} onClick={item.onClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{item.text}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
}

function QuestionCard(props: QuestionCardValue) {
  const {
    id,
    dbId,
    index,
    arrayIndex,
    isEditing,
    isDragging = false,
    title,
    description = "",
    type = "",
    showDescription = false,
    required = false,
    shuffle = false,
    onQuestionChange,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    moveQuestion,
    style = {},
    t,
  } = props;

  let theme = useTheme();

  const getIcon = (type: string) => {
    if (ICON_LOOK_UP[type]) {
      return ICON_LOOK_UP[type];
    } else {
      return null;
    }
  };
  const IconComponent = getIcon(type);

  const hasShuffle = ["multiple-choice", "checkboxes"];

  return (
    <DraggableCardBase
      id={id}
      arrayIndex={arrayIndex}
      isEditing={isEditing}
      isDragging={isDragging}
      onClick={onClick}
      moveQuestion={moveQuestion}
      style={style}
      t={t}
    >
      {/* Question index */}
      <Box style={{ position: "relative" }}>
        <Box
          sx={{
            width: 64,
            height: 32,
            backgroundColor: theme.palette.primary.main,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "16px",
            borderBottomRightRadius: "8px",
            // borderWidth: "1px",
            // borderStyle: "solid",
            //borderRadius: "16px",
            gap: 1,
            color: theme.palette.primary.contrastText,
          }}
        >
          <span>{index}</span>
          {IconComponent && <IconComponent />}
        </Box>
      </Box>
      <Stack spacing={2} sx={{ px: 4, pb: 4, pt: 2, mt: 4 }}>
        {isEditing ? (
          <Grid container direction={"column"}>
            <Grid item xs={12}>
              <TextField
                label={t("createSurvey.question")}
                variant="filled"
                fullWidth
                rows={4}
                value={title}
                onChange={(event) => {
                  onQuestionChange(id, { title: event.target.value });
                }}
                inputProps={{
                  maxLength: QUESTION_FORM_VALIDATION.TITLE.MAX_LENGTH,
                }}
              />
              {showDescription && (
                <TextField
                  sx={{ mt: 1 }}
                  placeholder={t("createSurvey.description")}
                  variant="standard"
                  fullWidth
                  value={description}
                  onChange={(event) => {
                    onQuestionChange(id, { description: event.target.value });
                  }}
                  inputProps={{
                    maxLength: QUESTION_FORM_VALIDATION.DESCRIPTION.MAX_LENGTH,
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="subtitle1">
            {_.isEmpty(title) ? "" : title}
          </Typography>
        )}
        {props.children}
      </Stack>
      {isEditing && (
        <Box sx={{ px: 4 }}>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Stack spacing={2} sx={{ py: 2 }} direction={"row"}>
              <Tooltip title={t("createSurvey.deleteQuestion")}>
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    onQuestionDelete(id);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("createSurvey.duplicate")}>
                <IconButton
                  aria-label="duplicate"
                  size="large"
                  onClick={() => {
                    onQuestionDuplicate(id);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" />
              <FormGroup
                row
                sx={{
                  height: "100%",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={required}
                      onChange={(e) => {
                        onQuestionChange(id, { required: e.target.checked });
                      }}
                    />
                  }
                  label={t("createSurvey.required")}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showDescription}
                      onChange={(e) => {
                        onQuestionChange(id, {
                          showDescription: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={t("createSurvey.description")}
                />
                {hasShuffle.includes(type) && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shuffle}
                        onChange={(e) => {
                          onQuestionChange(id, {
                            shuffle: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={t("createSurvey.shuffle")}
                  />
                )}
              </FormGroup>
            </Stack>
          </Box>
        </Box>
      )}
    </DraggableCardBase>
  );
}

function ShortAnswerCard(props: ShortAnswerCardValue) {
  const {
    id,
    dbId,
    index,
    arrayIndex,
    isEditing,
    isDragging,
    title,
    description = "",
    showDescription = false,
    required = false,
    shuffle = false,
    onQuestionChange,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    moveQuestion,
    style = {},
    t,
  } = props;

  return (
    <QuestionCard
      index={index}
      arrayIndex={arrayIndex}
      onClick={onClick}
      isEditing={isEditing}
      isDragging={isDragging}
      title={title}
      description={description}
      showDescription={showDescription}
      required={required}
      shuffle={shuffle}
      type={"short-answer"}
      onQuestionChange={onQuestionChange}
      onQuestionDelete={onQuestionDelete}
      onQuestionDuplicate={onQuestionDuplicate}
      moveQuestion={moveQuestion}
      id={id}
      dbId={dbId}
      style={style}
      t={t}
    >
      <TextField
        label={t("createSurvey.shortAnswerText")}
        variant="standard"
        fullWidth
        rows={4}
        disabled
      />
    </QuestionCard>
  );
}

function ParagraphCard(props: ParagraphCardValue) {
  const {
    id,
    dbId,
    index,
    arrayIndex,
    isEditing,
    isDragging,
    title,
    description = "",
    showDescription = false,
    required = false,
    shuffle = false,
    onQuestionChange,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    moveQuestion,
    style = {},
    t,
  } = props;

  return (
    <QuestionCard
      index={index}
      arrayIndex={arrayIndex}
      onClick={onClick}
      isEditing={isEditing}
      isDragging={isDragging}
      title={title}
      description={description}
      showDescription={showDescription}
      required={required}
      shuffle={shuffle}
      type={"paragraph"}
      onQuestionChange={onQuestionChange}
      onQuestionDelete={onQuestionDelete}
      onQuestionDuplicate={onQuestionDuplicate}
      moveQuestion={moveQuestion}
      id={id}
      dbId={dbId}
      style={style}
      t={t}
    >
      <TextField
        label={t("createSurvey.longAnswerText")}
        variant="standard"
        fullWidth
        rows={4}
        disabled
      />
    </QuestionCard>
  );
}

function MultipleChoiceCard(props: MultiChoiceCardValue) {
  const {
    id,
    dbId,
    index,
    arrayIndex,
    isEditing,
    isDragging,
    title,
    otherOption,
    multipleChoiceOptions,
    description = "",
    showDescription = false,
    required = false,
    shuffle = false,
    moveQuestion,
    onQuestionChange,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    onOptionAdd = () => {},
    onOptionDelete = () => {},
    onOptionChange = () => {},
    toggleOtherOption = () => {},
    style = {},
    t,
  } = props;

  let theme = useTheme();
  const [optionMenuAnchorEl, setOptionMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openOptionMenu = Boolean(optionMenuAnchorEl);
  const handleOptionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOptionMenuAnchorEl(event.currentTarget);
  };
  const handleOptionMenuClose = () => {
    setOptionMenuAnchorEl(null);
  };
  return (
    <QuestionCard
      index={index}
      arrayIndex={arrayIndex}
      onClick={onClick}
      isEditing={isEditing}
      isDragging={isDragging}
      title={title}
      description={description}
      showDescription={showDescription}
      required={required}
      shuffle={shuffle}
      type={"multiple-choice"}
      onQuestionChange={onQuestionChange}
      onQuestionDelete={onQuestionDelete}
      onQuestionDuplicate={onQuestionDuplicate}
      moveQuestion={moveQuestion}
      id={id}
      dbId={dbId}
      style={style}
      t={t}
    >
      {multipleChoiceOptions?.map((option) => {
        return (
          <Box key={option.id}>
            {isEditing ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                <RadioButtonUncheckedIcon
                  sx={{ width: 20, color: theme.palette.grey[400] }}
                />
                <Input
                  value={option.title}
                  fullWidth
                  onChange={(event) => {
                    onOptionChange(id, option.id, {
                      title: event.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onOptionAdd(id);
                    }
                  }}
                />
                {multipleChoiceOptions.length > 1 && (
                  <IconButton
                    onClick={() => {
                      onOptionDelete(id, option.id);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {/* <IconButton
                  onClick={handleOptionMenuClick}
                  id={option.id + "-button"}
                >
                  <SettingsIcon />
                </IconButton>
                <OptionMenu
                  id={option.id + "-menu"}
                  ariaLabeledBy={option.id + "-button"}
                  open={openOptionMenu}
                  anchorEl={optionMenuAnchorEl}
                  onClose={handleOptionMenuClose}
                  menuItems={[
                    {
                      onClick: () => {},
                      text: "Skip to question",
                      icon: <ShortcutIcon fontSize="small" />,
                    },
                  ]}
                /> */}
              </Stack>
            ) : (
              <Stack
                key={option.id}
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                <RadioButtonUncheckedIcon
                  sx={{ width: 20, color: theme.palette.grey[400] }}
                />
                <Typography variant="subtitle1">{option.title}</Typography>
              </Stack>
            )}
          </Box>
        );
      })}
      {otherOption && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <RadioButtonUncheckedIcon
            sx={{ width: 20, color: theme.palette.grey[400] }}
          />
          {isEditing ? (
            <>
              <Input
                disabled
                value={t("createSurvey.otherValue")}
                fullWidth
                sx={{ color: theme.palette.grey[600] }}
              />
              <IconButton
                onClick={() => {
                  toggleOtherOption(false);
                }}
              >
                <ClearIcon />
              </IconButton>
              {/* <IconButton
                onClick={handleOptionMenuClick}
                id={id + "-other" + "-button"}
              >
                <SettingsIcon />
              </IconButton>
              <OptionMenu
                id={id + "-other" + +"-menu"}
                ariaLabeledBy={id + "-other" + +"-button"}
                open={openOptionMenu}
                anchorEl={optionMenuAnchorEl}
                onClose={handleOptionMenuClose}
                menuItems={[
                  {
                    onClick: () => {},
                    text: "Skip to question",
                    icon: <ShortcutIcon fontSize="small" />,
                  },
                ]}
              /> */}
            </>
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[600] }}
            >
              {t("createSurvey.otherValue")}
            </Typography>
          )}
        </Stack>
      )}
      {/* Add option buttons */}
      {isEditing && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "start",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Button
            variant="text"
            sx={{ color: theme.palette.grey[600] }}
            disableFocusRipple
            onClick={() => {
              onOptionAdd(id);
            }}
          >
            {t("createSurvey.addOption")}
          </Button>
          {!otherOption && (
            <Box sx={{ display: "inline-flex", alignItems: "center", gap: 1 }}>
              <Typography>{" or "}</Typography>
              <Button
                variant="text"
                onClick={() => {
                  toggleOtherOption(true);
                }}
              >
                {t("createSurvey.addOther")}
              </Button>
            </Box>
          )}
        </Stack>
      )}
    </QuestionCard>
  );
}

function CheckBoxCard(props: CheckBoxCardValue) {
  const {
    id,
    dbId,
    index,
    arrayIndex,
    isEditing,
    isDragging,
    title,
    otherOption,
    checkBoxOptions,
    description = "",
    showDescription = false,
    required = false,
    shuffle = false,
    moveQuestion,
    onQuestionChange,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    onOptionAdd = () => {},
    onOptionDelete = () => {},
    onOptionChange = () => {},
    toggleOtherOption = () => {},
    style = {},
    t,
  } = props;

  let theme = useTheme();

  return (
    <QuestionCard
      index={index}
      arrayIndex={arrayIndex}
      onClick={onClick}
      isEditing={isEditing}
      isDragging={isDragging}
      title={title}
      description={description}
      showDescription={showDescription}
      required={required}
      shuffle={shuffle}
      type={"checkboxes"}
      onQuestionChange={onQuestionChange}
      onQuestionDelete={onQuestionDelete}
      onQuestionDuplicate={onQuestionDuplicate}
      moveQuestion={moveQuestion}
      id={id}
      dbId={dbId}
      style={style}
      t={t}
    >
      {checkBoxOptions?.map((option) => {
        return (
          <Box key={option.id}>
            {isEditing ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                <CheckBoxOutlineBlankIcon
                  sx={{ width: 20, color: theme.palette.grey[400] }}
                />
                <Input
                  value={option.title}
                  fullWidth
                  onChange={(event) => {
                    onOptionChange(id, option.id, {
                      title: event.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onOptionAdd(id);
                    }
                  }}
                />
                {checkBoxOptions.length > 1 && (
                  <IconButton
                    onClick={() => {
                      onOptionDelete(id, option.id);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {/* <IconButton onClick={() => {}}>
                  <SettingsIcon />
                </IconButton> */}
              </Stack>
            ) : (
              <Stack
                key={option.id}
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                <CheckBoxOutlineBlankIcon
                  sx={{ width: 20, color: theme.palette.grey[400] }}
                />
                <Typography variant="subtitle1">{option.title}</Typography>
              </Stack>
            )}
          </Box>
        );
      })}
      {otherOption && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <CheckBoxOutlineBlankIcon
            sx={{ width: 20, color: theme.palette.grey[400] }}
          />
          {isEditing ? (
            <Input
              disabled
              value={t("createSurvey.otherValue")}
              fullWidth
              sx={{ color: theme.palette.grey[600] }}
            />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[600] }}
            >
              {t("createSurvey.otherValue")}
            </Typography>
          )}
          {isEditing && (
            <IconButton
              onClick={() => {
                toggleOtherOption(false);
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Stack>
      )}
      {/* Add option buttons */}
      {isEditing && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "start",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Button
            variant="text"
            sx={{ color: theme.palette.grey[600] }}
            disableFocusRipple
            onClick={() => {
              onOptionAdd(id);
            }}
          >
            {t("createSurvey.addOption")}
          </Button>
          {!otherOption && (
            <Box sx={{ display: "inline-flex", alignItems: "center", gap: 1 }}>
              <Typography>{" or "}</Typography>
              <Button
                variant="text"
                onClick={() => {
                  toggleOtherOption(true);
                }}
              >
                {t("createSurvey.addOther")}
              </Button>
            </Box>
          )}
        </Stack>
      )}
    </QuestionCard>
  );
}

function DropDownCard(props: DropDownCardValue) {
  const {
    id,
    dbId,
    index,
    arrayIndex,
    isEditing,
    isDragging,
    title,
    dropDownOptions,
    description = "",
    showDescription = false,
    required = false,
    shuffle = false,
    moveQuestion,
    onQuestionChange,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    onOptionAdd = () => {},
    onOptionDelete = () => {},
    onOptionChange = () => {},
    style = {},
    t,
  } = props;

  let theme = useTheme();

  return (
    <QuestionCard
      index={index}
      arrayIndex={arrayIndex}
      onClick={onClick}
      isEditing={isEditing}
      isDragging={isDragging}
      title={title}
      description={description}
      showDescription={showDescription}
      required={required}
      shuffle={shuffle}
      type={"dropdown"}
      onQuestionChange={onQuestionChange}
      onQuestionDelete={onQuestionDelete}
      onQuestionDuplicate={onQuestionDuplicate}
      moveQuestion={moveQuestion}
      id={id}
      dbId={dbId}
      style={style}
      t={t}
    >
      {dropDownOptions?.map((option, index) => {
        return (
          <Box key={option.id}>
            {isEditing ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                <Box sx={{ width: 20 }}>{index + 1}.</Box>
                <Input
                  value={option.title}
                  fullWidth
                  onChange={(event) => {
                    onOptionChange(id, option.id, {
                      title: event.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onOptionAdd(id);
                    }
                  }}
                />
                {dropDownOptions.length > 1 && (
                  <IconButton
                    onClick={() => {
                      onOptionDelete(id, option.id);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {/* <IconButton onClick={() => {}}>
                  <SettingsIcon />
                </IconButton> */}
              </Stack>
            ) : (
              <Stack
                key={option.id}
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                <Box sx={{ width: 20 }}>{index + 1}.</Box>
                <Typography variant="subtitle1">{option.title}</Typography>
              </Stack>
            )}
          </Box>
        );
      })}
      {/* Add option buttons */}
      {isEditing && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "start",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Button
            variant="text"
            sx={{ color: theme.palette.grey[600] }}
            disableFocusRipple
            onClick={() => {
              onOptionAdd(id);
            }}
          >
            {t("createSurvey.addOption")}
          </Button>
        </Stack>
      )}
    </QuestionCard>
  );
}

function DividerCard(props: DividerCardValue) {
  const {
    id,
    arrayIndex,
    dbId,
    isEditing,
    isDragging = false,
    description = "",
    required = false,
    showDescription = false,
    onQuestionDelete,
    onQuestionDuplicate,
    onClick,
    moveQuestion,
    style = {},
    t,
  } = props;

  return (
    <DraggableCardBase
      id={id}
      arrayIndex={arrayIndex}
      isEditing={isEditing}
      isDragging={isDragging}
      onClick={onClick}
      style={style}
      moveQuestion={moveQuestion}
      t={t}
    >
      <Stack spacing={2} sx={{ px: 4, pb: 4, pt: 2, mt: 4 }}>
        <TextField
          label={t("createSurvey.dividerLabel")}
          variant="filled"
          fullWidth
          disabled
        />
      </Stack>
      {isEditing && (
        <Box sx={{ px: 4 }}>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Stack spacing={2} sx={{ py: 2 }} direction={"row"}>
              <Tooltip title={t("createSurvey.deleteQuestion")}>
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    onQuestionDelete(id);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("createSurvey.duplicate")}>
                <IconButton
                  aria-label="duplicate"
                  size="large"
                  onClick={() => {
                    onQuestionDuplicate(id);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </Box>
      )}
    </DraggableCardBase>
  );
}

export type {
  QuestionValue,
  ShortAnswerCardValue,
  ParagraphCardValue,
  MultiChoiceCardValue,
  CheckBoxCardValue,
  QuestionEvents,
  DropDownCardValue,
  DividerCardValue,
  MultiChoiceOptionValue,
  CheckBoxOptionValue,
  DropDownOptionValue,
};
export {
  ShortAnswerCard,
  ParagraphCard,
  MultipleChoiceCard,
  CheckBoxCard,
  DropDownCard,
  DividerCard,
};
