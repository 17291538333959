import React, { useEffect } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Chip,
  Link,
} from "@mui/material";
import { SurveyThemeValue } from "../pages/CreateSurvey";
import {
  CheckBoxOptionValue,
  DropDownOptionValue,
  MultiChoiceOptionValue,
} from "./SurveyQuestions";
import { useLocation, useNavigate } from "react-router-dom";
import { MAX_ANSWER_LENGTH } from "../constants/Constants";

interface TitleCardValue {
  title: string;
  emai?: string;
  description?: string;
  headerMaxHeight?: string;
  headerGradientStart?: string;
  headerGradientEnd?: string;
  userEmail?: string;
  gfLink?: string;
  t: any;
}

interface ThankYouCardValue {
  title: string;
  text?: string;
  headerMaxHeight?: string;
  headerGradientStart?: string;
  headerGradientEnd?: string;
  t: any;
}

interface AnswerCardValue {
  id: string;
  dbId: string;
  title: string;
  description?: string;
  theme: SurveyThemeValue;
  required?: boolean;
  style?: object;
  children?: React.ReactNode;
  t: any;
}

interface ShortAnswerCardValue extends AnswerCardValue {
  answer: string;
  errorText?: string;
  placeholder?: string;
  setAnswer: (answer: string) => void;
}
interface ParagraphCardValue extends AnswerCardValue {
  answer: string;
  errorText?: string;
  setAnswer: (answer: string) => void;
}
interface MultipleChoiceCardValue extends AnswerCardValue {
  answer: string;
  errorText?: string;
  setAnswer: (answer: string, otherAnswer?: string) => void;
  multipleChoiceOptions?: MultiChoiceOptionValue[];
  otherOption: boolean | undefined;
  otherAnswer: string | undefined;
}
interface CheckBoxCardValue extends AnswerCardValue {
  answer: Record<string, boolean>;
  errorText?: string;
  setAnswer: (answerKey: string, value: boolean, otherAnswer?: string) => void;
  checkBoxOptions?: CheckBoxOptionValue[];
  otherOption: boolean | undefined;
  otherAnswer: string | undefined;
}
interface DropDownCardValue extends AnswerCardValue {
  answer: string;
  errorText?: string;
  setAnswer: (answer: string) => void;
  dropDownOptions?: DropDownOptionValue[];
}

const OTHER_OPTION = "Other";

const BORDER_RADIUS = "24px";

function TitleCard({
  title,
  description,
  gfLink,
  headerMaxHeight,
  headerGradientStart = "#8EC5FC",
  headerGradientEnd = "E0C3FC",
  userEmail,
  t,
}: TitleCardValue) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Paper sx={{ borderRadius: BORDER_RADIUS }} elevation={0}>
      <Paper
        sx={{
          borderTopLeftRadius: BORDER_RADIUS,
          borderTopRightRadius: BORDER_RADIUS,
          background: `linear-gradient(62deg, ${headerGradientStart} 0%, ${headerGradientEnd} 100%)`,
          backgroundSize: "400% 400%",
          height: headerMaxHeight ? headerMaxHeight : "140px",
          animation: "gradient 25s ease infinite",
          marginBottom: "16px",
        }}
        elevation={0}
      ></Paper>
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h5"
          component={"h1"}
          sx={{
            overflowWrap: "break-word",
            lineHeight: 1.25,
            fontSize: "24pt",
          }}
        >
          {title || "Untitled Form"}
        </Typography>
        {description && (
          <Typography
            variant="subtitle1"
            component={"p"}
            sx={{
              overflowWrap: "break-word",
              mt: 1,
              lineHeight: 1.5,
              letterSpacing: 0,
              fontSize: "11pt",
            }}
          >
            {description}
          </Typography>
        )}
        {gfLink && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Link
              href={gfLink}
              target="_blank"
              sx={{
                fontSize: "11pt",
              }}
            >
              Google Form
            </Link>
          </Box>
        )}
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Box sx={{ display: "flex", alignItems: "center", pt: 1, gap: 1 }}>
          <Typography
            variant="subtitle1"
            component={"p"}
            sx={{
              overflowWrap: "break-word",

              lineHeight: 1.5,
              letterSpacing: 0,
              fontSize: "11pt",
            }}
          >
            {t("surveyResponse.loggedInAs")}{" "}
          </Typography>
          <Chip
            label={userEmail || t("surveyResponse.notLoggedIn")}
            color="primary"
            size="small"
          />
        </Box>

        {!userEmail && (
          <>
            <Typography
              variant="subtitle1"
              component={"p"}
              sx={{
                overflowWrap: "break-word",
                pt: 1,
                lineHeight: 1.5,
                letterSpacing: 0,
                fontSize: "11pt",
                fontWeight: "bold",
              }}
            >
              {t("surveyResponse.notLoggedInHint")}
            </Typography>

            <Button
              onClick={() => {
                navigate("/login", { state: { from: location } });
              }}
            >
              {t("surveyResponse.login")}
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
}

function ThankYouCard({
  title,
  text,
  headerMaxHeight,
  headerGradientStart = "#8EC5FC",
  headerGradientEnd = "E0C3FC",
  t,
}: ThankYouCardValue) {
  const navigate = useNavigate();

  return (
    <Paper sx={{ borderRadius: BORDER_RADIUS }} elevation={0}>
      <Paper
        sx={{
          borderTopLeftRadius: BORDER_RADIUS,
          borderTopRightRadius: BORDER_RADIUS,
          background: `linear-gradient(62deg, ${headerGradientStart} 0%, ${headerGradientEnd} 100%)`,
          backgroundSize: "400% 400%",
          height: headerMaxHeight ? headerMaxHeight : "280px",
          animation: "gradient 25s ease infinite",
          marginBottom: "16px",
        }}
        elevation={0}
      ></Paper>
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h5"
          component={"h1"}
          sx={{
            overflowWrap: "break-word",
            lineHeight: 1.25,
            fontSize: "20pt",
          }}
        >
          {title || "Thank you for your response!"}
        </Typography>
        {text && (
          <Typography
            variant="subtitle1"
            component={"p"}
            sx={{
              overflowWrap: "break-word",
              pt: 1,
              lineHeight: 1.5,
              letterSpacing: 0,
              fontSize: "11pt",
            }}
          >
            {text}
          </Typography>
        )}
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/my-surveys");
            }}
          >
            {t("surveyResponse.createOwnSurvey")}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

// TODO:
function AnswerCard(props: AnswerCardValue) {
  const {
    id,
    dbId,
    title,
    description = "",
    theme,
    style = {},
    required = false,
  } = props;

  return (
    <Paper
      sx={{
        borderRadius: BORDER_RADIUS,
      }}
      variant="outlined"
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 400, fontSize: "12pt" }}>
            {_.isEmpty(title) ? "" : title}{" "}
            {required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          {description && (
            <Typography
              variant="subtitle1"
              component={"p"}
              sx={{
                overflowWrap: "break-word",
                pt: 1,
                fontSize: "11pt",
                lineHeight: 1.5,
                letterSpacing: 0,
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
        {props.children}
      </Stack>
    </Paper>
  );
}

// ShortAnswer Card
function ShortAnswerCard(props: ShortAnswerCardValue) {
  const {
    id,
    dbId,
    title,
    description = "",
    theme,
    style = {},
    answer = "",
    required = false,
    setAnswer = () => {},
    errorText = "",
    placeholder = "",
    t,
  } = props;

  return (
    <AnswerCard
      title={title}
      description={description}
      id={id}
      dbId={dbId}
      style={style}
      required={required}
      theme={theme}
      t={t}
    >
      <TextField
        label=""
        variant="standard"
        fullWidth
        placeholder={
          !_.isEmpty(placeholder) ? placeholder : t("surveyResponse.yourAnswer")
        }
        value={answer}
        onChange={(event) => {
          setAnswer(event.target.value);
        }}
        error={errorText !== ""}
        helperText={errorText}
        inputProps={{
          maxLength: MAX_ANSWER_LENGTH.SHORT_ANSWER,
        }}
      />
    </AnswerCard>
  );
}
// Paragraph Card
function ParagraphCard(props: ParagraphCardValue) {
  const {
    id,
    dbId,
    title,
    description = "",
    theme,
    style = {},
    answer = "",
    required = false,
    setAnswer = () => {},
    errorText = "",
    t,
  } = props;

  return (
    <AnswerCard
      title={title}
      description={description}
      id={id}
      dbId={dbId}
      style={style}
      required={required}
      theme={theme}
      t={t}
    >
      <TextField
        label=""
        variant="standard"
        fullWidth
        placeholder={t("surveyResponse.yourAnswer")}
        value={answer}
        multiline
        onChange={(event) => {
          setAnswer(event.target.value);
        }}
        error={errorText !== ""}
        helperText={errorText}
        inputProps={{
          maxLength: MAX_ANSWER_LENGTH.PARAGRAPH,
        }}
      />
    </AnswerCard>
  );
}
// MultipleChoice Card
function MultipleChoiceCard(props: MultipleChoiceCardValue) {
  const {
    id,
    dbId,
    title,
    description = "",
    theme,
    style = {},
    answer = "",
    required = false,
    setAnswer = () => {},
    errorText = "",
    multipleChoiceOptions = [],
    otherAnswer = "",
    otherOption = false,
    t,
  } = props;

  return (
    <AnswerCard
      title={title}
      description={description}
      id={id}
      dbId={dbId}
      style={style}
      required={required}
      theme={theme}
      t
    >
      <FormControl error={errorText !== ""}>
        <RadioGroup
          aria-labelledby={id + "-group-label"}
          name={id + "-radio-group"}
          value={answer}
          onChange={(e) => {
            setAnswer((e.target as HTMLInputElement).value);
          }}
        >
          {multipleChoiceOptions?.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                value={option.title}
                control={<Radio />}
                label={option.title}
              />
            );
          })}
          {otherOption && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                minWidth: 0,
              }}
            >
              <FormControlLabel
                value={t("surveyResponse.other")}
                control={<Radio />}
                label={t("surveyResponse.other")}
              />
              <TextField
                fullWidth
                variant="standard"
                value={otherAnswer}
                onChange={(event) => {
                  setAnswer(t("surveyResponse.other"), event.target.value);
                }}
                inputProps={{
                  maxLength: MAX_ANSWER_LENGTH.OTHER_ANSWER,
                }}
              />
            </Stack>
          )}
        </RadioGroup>
        <FormHelperText sx={{ ml: 0 }}>{errorText}</FormHelperText>
        {answer !== "" && (
          <Box sx={{ display: "flex" }}>
            <Button
              variant="text"
              sx={{
                fontWeight: 400,
                mt: 2,
                ml: "auto",
              }}
              onClick={() => {
                setAnswer("");
              }}
            >
              {t("surveyResponse.clearSelection")}
            </Button>
          </Box>
        )}
      </FormControl>
      {/* Clear selection on right */}
    </AnswerCard>
  );
}

// Checkboxes Card
function CheckBoxCard(props: CheckBoxCardValue) {
  const {
    id,
    dbId,
    title,
    description = "",
    theme,
    style = {},
    answer = {},
    setAnswer = () => {},
    errorText = "",
    required = false,
    checkBoxOptions = [],
    otherAnswer = "",
    otherOption = false,
    t,
  } = props;

  return (
    <AnswerCard
      title={title}
      description={description}
      id={id}
      dbId={dbId}
      required={required}
      style={style}
      theme={theme}
      t={t}
    >
      <FormControl component="fieldset" error={errorText !== ""}>
        <FormGroup>
          {checkBoxOptions?.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={_.get(answer, option.title, false)}
                    onChange={() => {
                      setAnswer(option.title, !_.get(answer, option.title));
                    }}
                    name={option.title}
                  />
                }
                label={option.title}
              />
            );
          })}
          {otherOption && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                minWidth: 0,
              }}
            >
              <FormControlLabel
                key={t("surveyResponse.other")}
                control={
                  <Checkbox
                    checked={_.get(answer, t("surveyResponse.other"), false)}
                    onChange={() => {
                      setAnswer(
                        t("surveyResponse.other"),
                        !_.get(answer, t("surveyResponse.other")),
                        otherAnswer
                      );
                    }}
                    name={t("surveyResponse.other")}
                  />
                }
                label={t("surveyResponse.other")}
              />
              <TextField
                fullWidth
                variant="standard"
                value={otherAnswer}
                onChange={(event) => {
                  setAnswer(
                    t("surveyResponse.other"),
                    true,
                    event.target.value
                  );
                }}
                inputProps={{
                  maxLength: MAX_ANSWER_LENGTH.OTHER_ANSWER,
                }}
              />
            </Stack>
          )}
        </FormGroup>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </AnswerCard>
  );
}
// Dropdown Card
function DropDownCard(props: DropDownCardValue) {
  const {
    id,
    dbId,
    title,
    description = "",
    theme,
    style = {},
    required = false,
    answer = "",
    setAnswer = () => {},
    errorText = "",
    dropDownOptions = [],
    t,
  } = props;

  return (
    <AnswerCard
      title={title}
      description={description}
      id={id}
      dbId={dbId}
      required={required}
      style={style}
      theme={theme}
      t={t}
    >
      <Box>
        <FormControl
          sx={{ m: 1, minWidth: 120, maxWidth: "100%" }}
          error={errorText !== ""}
        >
          <Select
            value={answer}
            displayEmpty
            autoWidth
            onChange={(e) => {
              setAnswer((e.target as HTMLInputElement).value);
            }}
          >
            <MenuItem value="">
              <span style={{ color: "#70757a" }}>
                {t("surveyResponse.choose")}
              </span>
            </MenuItem>
            {dropDownOptions?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.title}>
                  {option.title}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText sx={{ ml: 0 }}>{errorText}</FormHelperText>
        </FormControl>
      </Box>
    </AnswerCard>
  );
}
// Divider Card
// Next Page, Submit Card
export {
  TitleCard,
  ThankYouCard,
  AnswerCard,
  ShortAnswerCard,
  ParagraphCard,
  MultipleChoiceCard,
  CheckBoxCard,
  DropDownCard,
};
