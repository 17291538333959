import { useNavigate } from "react-router-dom";
import openAxiosInstance from "../api/Axios";
import useAuth from "./useAuth";
import GoAPI from "../api/GoAPI";

const useRefreshUser = () => {
  const { auth, setAuth } = useAuth();

  const getUser = async (accessToken: string) => {
    try {
      const response = await GoAPI.auth.getUserFromToken(accessToken);
      return response.data.user;
    } catch (error) {
      console.log(error);
    }
  };

  const refreshUser = async () => {
    if (auth?.accessToken) {
      const user = await getUser(auth.accessToken);
      if (user) {
        setAuth((prev: any) => {
          return {
            ...prev,
            user: user,
          };
        });
      }
    }
  };

  return refreshUser;
};

export default useRefreshUser;
