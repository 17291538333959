import { access } from "fs";
import { AnswerObject } from "../../components/SurveyResponse";
import axios, { authAxiosInstance, getJWTHeader } from "../Axios";

const routers = {
  getSurveyById: async (accessToken: string, surveyId: string) =>
    authAxiosInstance.get(
      "/survey/".concat(surveyId),
      getJWTHeader(accessToken)
    ),
  submitSurvey: async (
    accessToken: string,
    surveyId: string,
    surveyResponse: AnswerObject
  ) =>
    authAxiosInstance.post(
      "/survey/".concat(surveyId),
      {
        surveyResponse: surveyResponse,
        surveyId,
      },
      getJWTHeader(accessToken)
    ),
  getAllSurveys: async (accessToken: string) =>
    authAxiosInstance.get("/survey/surveys", getJWTHeader(accessToken)), // Add the new API endpoint for getting all surveys
  searchSurveys: async (accessToken: string, searchQuery: string) =>
    authAxiosInstance.get(
      `/survey/surveys?searchQuery=${searchQuery}`,
      getJWTHeader(accessToken)
    ),
  getRecSurveys: async (accessToken: string) =>
    authAxiosInstance.get("/survey/recommended", getJWTHeader(accessToken)),
  getRandomSurveys: async (accessToken: string) =>
    authAxiosInstance.get("/survey/random", getJWTHeader(accessToken)),
  getRecSurveysLang: async (accessToken: string, lang: string) =>
    authAxiosInstance.get(
      `/survey/recommended/${lang}`,
      getJWTHeader(accessToken)
    ),
  getAllSurveysLang: async (accessToken: string, lang: string) =>
    authAxiosInstance.get(
      `/survey/surveys/${lang}`,
      getJWTHeader(accessToken)
    ),
  getRandomSurveysLang: async (accessToken: string, lang: string) =>
    authAxiosInstance.get(
      `/survey/random/${lang}`,
      getJWTHeader(accessToken)
    ),
  calculateTotalCost: async (
    accessToken: string,
    questionCart: Record<string, number>,
    surveyType: string
  ) =>
    authAxiosInstance.post(
      "/survey/cost",
      {
        questionCart: questionCart,
        surveyType: surveyType,
      },
      getJWTHeader(accessToken)
    ),
  validateGFCode: async (surveyId: string, code: string) =>
    authAxiosInstance.post("/survey/validate/".concat(surveyId), {
      code: code,
    }),
};

export default routers;
