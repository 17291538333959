import axios from "axios";

// Change this to read .env value to be able to switch between dev and prodution
const API_BASE_URL = process.env.REACT_APP_API_URL + "/v1";

const getJWTHeader = (accessToken: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

// The openAxiosInstance is used for all API calls that do not require authentication, eg. login, signup
const openAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// The authAxiosInstance is used for all API calls that require authentication, eg. get user info, get user's posts
const authAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// const maxAge = 10000;
// const memoizedRefreshToken = mem(
//   async () => {
//     try {
//       console.log("Refresh token");
//       const response = await goAPI.auth.refreshToken();

//       const { token } = response.data;
//       if (!token) {
//         localStorage.removeItem("token");
//         localStorage.removeItem("user");
//       }

//       return token;
//     } catch (error) {
//       localStorage.removeItem("session");
//       localStorage.removeItem("user");
//     }
//   },
//   {
//     maxAge,
//   }
// );
// authAxiosInstance.interceptors.request.use(
//   async (config: any) => {
//     const token = localStorage.getItem("token");
//     if (!config.headers["Authorization"]) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error: any) => Promise.reject(error)
// );

// authAxiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const config = error?.config;

//     if (error?.response?.status === 401 && !config?.sent) {

//     }
//     return Promise.reject(error);
//   }
// );

export default openAxiosInstance;
export { authAxiosInstance, getJWTHeader };
