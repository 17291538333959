import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  Paper,
  styled,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import {
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { MAIN_COLORS, STEPPER_HEIGHT } from "../constants/Constants";
import React, { Suspense } from "react";
import _ from "lodash";
import chroma from "chroma-js";
import CreateSurveyPreview from "./CreateSurveyPreveiew";
import { SurveyValue } from "../pages/CreateSurvey";

interface Color {
  name: string;
  hexCode: string;
}

interface ColorCircleProps extends Color {
  selected: boolean;
  isLightColor?: boolean;
  onClick: () => void;
}

const ColorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 14,
  },
}));

const ColorCircle: React.FC<ColorCircleProps> = ({
  name,
  hexCode,
  selected,
  isLightColor = false,
  onClick,
}) => {
  return (
    <ColorTooltip title={`${name} (${hexCode})`} placement="top">
      <Paper
        sx={{
          width: 40,
          height: 40,
          borderRadius: "50%",
          bgcolor: hexCode,
          position: "relative",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
          },
          borderColor: name !== "grey" ? "" : "#cccccc",
          borderWidth: name !== "grey" ? "" : 1,
          borderStyle: name !== "grey" ? "" : "solid",
        }}
        elevation={selected ? 3 : 0}
        onClick={onClick}
      >
        {selected && (
          <CheckIcon
            sx={{
              fontSize: 30,
              color: isLightColor ? "black" : "white",
              fontWeight: "bold",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></CheckIcon>
        )}
      </Paper>
    </ColorTooltip>
  );
};

const backgroundColors = MAIN_COLORS.reduce<Record<string, Color[]>>(
  (acc, { key, hexCode }) => {
    acc[hexCode] = [
      {
        name: "light",
        hexCode: chroma(hexCode).alpha(0.15).hex(),
      },
      { name: "medium", hexCode: chroma(hexCode).alpha(0.25).hex() },
      { name: "dark", hexCode: chroma(hexCode).alpha(0.35).hex() },
    ];
    return acc;
  },
  {}
);

const backgroundGrey = "#f6f6f6";

interface CreateSurveyThemePageProps {
  goToPrevPage: () => void;
  goToNextPage: () => void;
  survey: SurveyValue;
  setSurvey: React.Dispatch<React.SetStateAction<SurveyValue>>;
  t: any;
}

export default function CreateSurveyThemePage({
  goToPrevPage,
  goToNextPage,
  survey,
  setSurvey,
  t,
}: CreateSurveyThemePageProps) {
  const theme = useTheme();

  const defaultColor = MAIN_COLORS[0].hexCode;
  const defaultBackgroundColor = backgroundColors[defaultColor][0].hexCode;

  React.useEffect(() => {
    if (!survey.theme || !survey.theme.color) {
      setSurvey((prevState) => {
        return {
          ...prevState,
          theme: {
            color: defaultColor,
            background: defaultBackgroundColor,
          },
        };
      });
    }
  }, [defaultBackgroundColor, defaultColor, setSurvey, survey]);

  const handleColorClick = (hexCode: string) => {
    setSurvey((prevState) => {
      return {
        ...prevState,
        theme: {
          color: hexCode,
          background: backgroundColors[hexCode][0].hexCode,
        },
      };
    });
  };

  return (
    <Suspense>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          height: {
            xs: `calc(100vh - ${STEPPER_HEIGHT.xs + 64}px)`,
            sm: `calc(100vh - ${STEPPER_HEIGHT.sm + 64}px)`,
            md: `calc(100vh - ${STEPPER_HEIGHT.md + 64}px)`,
          },
        }}
      >
        <Grid
          item
          container
          direction="column"
          sx={{
            borderRight: 1,
            borderColor: "rgba(0, 0, 0, 0.12)",
            p: 3,
          }}
          xs={12}
          md={4}
        >
          <Box component="form" noValidate autoComplete="off" sx={{ mb: 12 }}>
            <Box sx={{ width: "fit-content", display: "flex" }}>
              <Box
                sx={{
                  width: "6px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "8px",
                  mr: 1,
                }}
              ></Box>
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                {t("createSurvey.selectTheme")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 4 }} spacing={8}>
              <Box>
                <Typography variant="h6" component="h4">
                  {t("createSurvey.color")}
                </Typography>
                <Grid container spacing={2} marginTop={2}>
                  {MAIN_COLORS.map((color) => (
                    <Grid item key={color.key}>
                      <ColorCircle
                        name={color.name}
                        hexCode={color.hexCode}
                        selected={
                          color.hexCode ===
                          (survey.theme?.color || defaultColor)
                        }
                        onClick={() => handleColorClick(color.hexCode)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box>
                <Typography variant="h6" component="h4">
                  {t("createSurvey.background")}
                </Typography>
                <Grid container spacing={2} marginTop={2}>
                  {_.get(
                    backgroundColors,
                    survey.theme?.color || defaultColor,
                    []
                  ).map(({ hexCode, name }: Color) => (
                    <Grid item key={hexCode}>
                      <ColorCircle
                        name={name}
                        hexCode={hexCode}
                        isLightColor={true}
                        selected={hexCode === survey.theme?.background}
                        onClick={() => {
                          setSurvey((prevState) => {
                            return {
                              ...prevState,
                              theme: {
                                color: prevState.theme?.color || defaultColor,
                                background: hexCode,
                              },
                            };
                          });
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    <ColorCircle
                      name={"grey"}
                      isLightColor={true}
                      hexCode={backgroundGrey}
                      selected={backgroundGrey === survey.theme?.background}
                      onClick={() => {
                        setSurvey((prevState) => {
                          return {
                            ...prevState,
                            theme: {
                              color: prevState.theme?.color || defaultColor,
                              background: backgroundGrey,
                            },
                          };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ display: "flex", mt: "auto" }}>
            <Button
              variant="contained"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                goToPrevPage();
              }}
            >
              {t("createSurvey.back")}
            </Button>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              sx={{ ml: "auto" }}
              onClick={() => {
                goToNextPage();
              }}
            >
              {t("createSurvey.next")}
            </Button>
          </Box>
        </Grid>
        <Grid
          container
          item
          direction={"column"}
          md={8}
          justifyContent="center"
          alignItems="stretch"
          sx={{
            display: { xs: "none", md: "flex" },
            backgroundColor: theme.palette.greyBackground?.main || "#f5f5f5",
            overflow: "auto",
            height: "100%",
          }}
        >
          <CreateSurveyPreview survey={survey} t={t} />
        </Grid>
      </Grid>
    </Suspense>
  );
}
