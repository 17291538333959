// Home.js
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Alert, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import goAPI from "../api/GoAPI";
import CardGrid from "../components/CardGrid";
import ContactDialog from "../components/ContactDialog";
import FilterPopup from "../components/FilterPopup";
import Layout from "../components/Layout";
import NavUserSection from "../components/NavUserSection";
import TopAppBar from "../components/TopAppBar";
import useAuth from "../hooks/useAuth";

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

export default function Home() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  // Define media queries for different screen sizes
  const isSmScreen = useMediaQuery(theme.breakpoints.down("lg")); // For screens with maximum width lg

  const { auth } = useAuth();
  const [activeTab, setActiveTab] = React.useState("0");
  const [isLoading, setLoading] = React.useState(false);
  const [allSurveys, setAllSurveys] = React.useState([]);
  const [recommendedSurveys, setRecommendedSurveys] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const handleSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      try {
        // Perform the search request
        // setup searchSurveys function here
        const response = await goAPI.survey.searchSurveys(
          auth?.accessToken ?? "",
          searchQuery
        );
        setSearchResults(response.data.survey);
      } catch (error) {
        console.error("Error searching surveys:", error);
      }
    }
  };

  React.useEffect(() => {
    const fetchRecommendedSurveys = async () => {
      let results = [];
      try {
        setLoading(true);
        if (auth?.accessToken) {
          const response = await goAPI.survey.getRecSurveys(
            auth?.accessToken ?? ""
          );
          //console.log("Recommended Surveys:", response.data);
          results = response.data.survey;
          //setRecommendedSurveys(response.data.survey);
        } else {
          const response = await goAPI.survey.getRandomSurveys(
            auth?.accessToken ?? ""
          );
          //console.log("Rand Surveys:", response.data);
          results = response.data.survey;
          //setRandomSurveys(response.data.survey); // No recommended surveys for non-logged-in users
        }
        setRecommendedSurveys(results);
      } catch (error) {
        console.error("Error fetching recommended surveys:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchRecommendedSurveysLang = async () => {
      let results = [];
      try {
        setLoading(true);
        if (auth?.accessToken) {
          const response = await goAPI.survey.getRecSurveysLang(
            auth?.accessToken ?? "",
            i18n.language.split("-")[0]
          );
          //console.log("Recommended Surveys:", response.data);
          results = response.data.survey;
          //setRecommendedSurveys(response.data.survey);
        } else {
          const response = await goAPI.survey.getRandomSurveysLang(
            auth?.accessToken ?? "",
            i18n.language.split("-")[0]
          );
          //console.log("Rand Surveys:", response.data);
          results = response.data.survey;
          //setRandomSurveys(response.data.survey); // No recommended surveys for non-logged-in users
        }
        setRecommendedSurveys(results);
      } catch (error) {
        console.error("Error fetching recommended surveys:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVIEWAllSurveys = async () => {
      try {
        setLoading(true);
        const response = await goAPI.survey.getAllSurveys(
          auth?.accessToken ?? ""
        );
        console.log("All Surveys:", response.data);
        setAllSurveys(response.data.survey);
      } catch (error) {
        console.error("Error fetching all surveys:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVIEWAllSurveysLang = async () => {
      try {
        setLoading(true);
        const response = await goAPI.survey.getAllSurveysLang(
          auth?.accessToken ?? "",
          i18n.language.split("-")[0]
        );
        console.log("All Surveys:", response.data);
        setAllSurveys(response.data.survey);
      } catch (error) {
        console.error("Error fetching all surveys:", error);
      } finally {
        setLoading(false);
      }
    };
    if (activeTab === "0") {
      //fetchRecommendedSurveys();
      fetchRecommendedSurveysLang(); // Call the new function to fetch recommended surveys based on language
    } else if (activeTab === "1") {
      //fetchVIEWAllSurveys();
      fetchVIEWAllSurveysLang(); // Call the new function to fetch all surveys based on language
    }
  }, [auth?.accessToken, activeTab]);

  const [contactDialogOpen, setContactDialogOpen] = React.useState(false);
  const handleContactDialogOpen = () => {
    setContactDialogOpen(true);
  };
  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
  };

  return (
    <Suspense fallback={<Loader />}>
      <Layout t={t}>
        <ContactDialog
          open={contactDialogOpen}
          handleClickOpen={handleContactDialogOpen}
          handleClose={handleContactDialogClose}
          t={t}
        />
        <Box
          sx={{
            width: "100%",
            minHeight: "100%",
            backgroundColor: "#f8f8f8",
          }}
        >
          <Alert severity="info">{t("home.betaMessage")}</Alert>
          <TopAppBar
            t={t}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          />
          <Container
            sx={{
              paddingTop: "20px",
              padding: "0px",
              backgroundColor: "#f8f8f8",
            }}
            maxWidth="lg"
          >
            {/*Search bar starts here */}
            <Stack
              sx={{
                marginTop: "20px",
                px: {
                  xs: 0,
                  sm: 2,
                  md: 3,
                },
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Paper
                component="form"
                elevation={0}
                sx={{
                  placeItems: "center",
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: isSmScreen ? 300 : 600,
                  backgroundColor: "#D9D9D9",
                  marginRight: isSmScreen ? "auto" : "0px",
                  marginTop: "auto",
                  horizontalAlign: "center",
                  ml: isSmScreen ? "auto" : "0",
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,

                    input: {
                      color: "black",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                  placeholder={t("home.search")}
                  inputProps={{ "aria-label": t("home.search") }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={handleSearch}
                />
                {/* <FilterPopup t={t} /> */}
              </Paper>
              <Box
                sx={{
                  display: isSmScreen ? "none" : "inline-flex",
                  gap: "2",
                }}
              >
                <NavUserSection auth={auth} t={t} />
              </Box>
            </Stack>
            {/*Recommended/View all tab starts here */}

            <TabContext value={activeTab}>
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                centered
                sx={{
                  fontWeight: "bold",
                  "&.Mui-selected": {
                    color: "#2b6cb0",
                    fontWeight: "bold",
                  },
                }}
              >
                <Tab
                  label={t("home.recommended")}
                  value="0"
                  sx={{
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label={t("home.view_all")}
                  value="1"
                  sx={{
                    fontWeight: "bold",
                  }}
                />
              </Tabs>
              <TabPanel value="0">
                {isLoading ? (
                  <Typography variant="body1">Loading...</Typography>
                ) : (
                  <CardGrid
                    cards={
                      searchResults.length > 0
                        ? searchResults
                        : recommendedSurveys
                    }
                  />
                )}
              </TabPanel>

              <TabPanel value="1">
                {isLoading ? (
                  <Typography variant="body1">Loading...</Typography>
                ) : (
                  <CardGrid
                    cards={
                      searchResults.length > 0 ? searchResults : allSurveys
                    }
                  />
                )}
              </TabPanel>
            </TabContext>
          </Container>
          <Fab
            color="primary"
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "primary.main.dark",
              },
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
            onClick={handleContactDialogOpen}
          >
            <QuestionMarkIcon />
          </Fab>
        </Box>
      </Layout>
    </Suspense>
  );
}
