import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Copyright from "../components/Copyright";
import Stack from "@mui/material/Stack";
import TopAppBar from "../components/TopAppBar";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import TollIcon from "@mui/icons-material/Toll";
import { v4 as uuid } from "uuid";
import { Link as RouterLink } from "react-router-dom";
import { SURVEYSKY_TYPE } from "../constants/Constants";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DescriptionIcon from "@mui/icons-material/Description";
import { Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

export interface HomeCardProps {
  id: string;
  title: string;
  description: string;
  tags: string[];
  bonus: number;
  username: string;
  surveyType: string;
}

const MAX_DESCRIPTION_LENGTH = 75;

export const HomeCard = (props: HomeCardProps): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const surveyUrl = `/s/${props.id}`;
  const theme = useTheme();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Function to truncate the description
  const needsTruncation = props.description.length > MAX_DESCRIPTION_LENGTH;
  const truncatedDescription =
    props.description.slice(0, MAX_DESCRIPTION_LENGTH) + "...";

  
  return (
    <>
      <Card
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: 4,
          border: "1px solid #D3D3D3",
          "&:hover": {
            border: "1px solid #2C7EF4",
          },
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            sx={{
              fontWeight: 600,
            }}
          >
            <Link
              component={RouterLink}
              to={surveyUrl}
              sx={{
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {props.title}
            </Link>
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: 600,
              maxHeight: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "10",
            }}
          >
            {needsTruncation ? truncatedDescription : props.description}
          </Typography>
          {needsTruncation && (
            <Button onClick={handleOpenModal}>Show More</Button>
          )}
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={1}
            sx={{
              marginTop: "5px",
            }}
          >
            {props.tags.map((tag) => (
              <Chip
                key={uuid()}
                label={tag}
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: "5px",
                }}
              />
            ))}
          </Stack>
        </CardContent>
        <CardActions>
         <TollIcon sx={{ margin: "5px" }} />
          <Typography
          sx={{ marginRight: "auto" }}
          >{props.bonus}</Typography>
            {props.surveyType === SURVEYSKY_TYPE ? (
              <Tooltip title="Survey Sky">
                <InsertDriveFileIcon
                  sx={{
                    color: theme.palette.primary.main,
                    marginLeft: "auto",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Google Form">
                <DescriptionIcon
                  sx={{
                      color: "#6c4ab3",
                      marginLeft: "auto",
                  }}
                />
              </Tooltip>
            )}
        </CardActions>
      </Card>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(5px)", // Blurs the background
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "70%", // Adjust size as needed
            maxWidth: "600px", // Set a max-width for larger screens
            bgcolor: "background.paper",
            p: 2,
            borderRadius: 2, // Optional: Rounded corners
            boxShadow: 24, // Adds depth to the modal
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 4,
              border: "0px solid #D3D3D3",
              "&:hover": {
                border: "0px solid #2C7EF4",
              },
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 600,
                }}
              >
                <Link
                  component={RouterLink}
                  to={surveyUrl}
                  sx={{
                    color: "inherit",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {props.title}
                </Link>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontWeight: 600,
                  maxHeight: "500px",
                  overflow: "auto",
                  //textOverflow: "ellipsis",
                  //display: "-webkit-box",
                  //"-webkit-box-orient": "vertical",
                  //"-webkit-line-clamp": "10",
                }}
              >
                {props.description}
              </Typography>
              <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                spacing={1}
                sx={{
                  marginTop: "5px",
                }}
              >
                {props.tags.map((tag) => (
                  <Chip
                    key={uuid()}
                    label={tag}
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </Stack>
            </CardContent>
            <CardActions>
              {/* Hide bonus until it works */}
              <TollIcon sx={{ margin: "5px" }} />
              <Typography
              sx={{ marginRight: "auto" }}
              >{props.bonus}</Typography>
                {props.surveyType === SURVEYSKY_TYPE ? (
                  <Tooltip title="Survey Sky">
                    <InsertDriveFileIcon
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Google Form">
                    <DescriptionIcon
                      sx={{
                        color: "#6c4ab3",
                      }}
                    />
                  </Tooltip>
                )}
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default HomeCard;
