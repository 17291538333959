import { Padding, Settings } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BugReportIcon from "@mui/icons-material/BugReport";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";

const drawerWidth = 240;

interface Props {
  t: any;
}

export default function ResponsiveDrawer(props: Props) {
  const { t } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const Navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const routeChange = (route: string) => {
    let path = route;
    return () => {
      Navigate(path);
    };
  };

  const drawer = (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              p: 3,
            }}
          >
            <Avatar src={logo} sx={{ width: 42, height: 42 }}></Avatar>
            <Typography
              align="left"
              variant="h5"
              color="black"
              noWrap
              sx={{
                fontWeight: 700,
                textDecoration: "none",
              }}
            >
              SURVEY SKY
            </Typography>
          </Box>
          <List>
            <ListItem
              key="home"
              component={RouterLink}
              to="/"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="dashboard"
              component={RouterLink}
              to="/my-surveys"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"My Surveys"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="howTo"
              component={RouterLink}
              to="/how-to"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t("home.howto")} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="description"
              component={RouterLink}
              to="/terms-of-service"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Terms Of Service"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="feedback"
              component={RouterLink}
              target="_blank"
              to="https://forms.gle/ceKH3c9eaQeb6VWP8"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                <ListItemText primary={t("home.feedback")} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="bugReport"
              component={RouterLink}
              target="_blank"
              to="https://forms.gle/gNrKYB8WqA1ReyoH6"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BugReportIcon />
                </ListItemIcon>
                <ListItemText primary={t("home.bugReport")} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="reportSurvey"
              component={RouterLink}
              to="https://forms.gle/tVFLYusR9Cx2VBHB9"
              target="_blank"
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ReportProblemIcon />
                </ListItemIcon>
                <ListItemText primary={t("home.reportSurvey")} />
              </ListItemButton>
            </ListItem>

            {/* <ListItem
              key="saved"
              component={RouterLink}
              to=""
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary={"Saved"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="profile"
              component={RouterLink}
              to=""
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={"Profile"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="Settings"
              component={RouterLink}
              to=""
              style={{ color: "black", margin: "3px" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItemButton>
            </ListItem> */}
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
