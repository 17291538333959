import * as React from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip as MuiTooltip,
  Typography,
  useTheme,
  Stack,
} from "@mui/material";
import TopAppBar from "../components/TopAppBar";
import theme from "../theme";
import goAPI from "../api/GoAPI";
import { useParams } from "react-router-dom";
import UserContext from "../context/AuthProvider";
import _ from "lodash";
import { QuestionValue } from "../components/SurveyQuestions";
import {
  DEFAULT_SURVEY_THEME,
  GOOGLEFORM_TYPE,
  QUESTION_COLUMN_WIDTH,
} from "../constants/Constants";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

// export function DataTable({
//   columns,
//   rows,
//   show = false,
// }: {
//   columns: GridColDef[];
//   rows: any[];
//   show: boolean;
// }) {
//   return (
//     <Box
//       style={{ height: "100%", width: "100%" }}
//     >
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         sx={{ display: show ? "flex" : "none" }}
//         initialState={{
//           pagination: {
//             paginationModel: { page: 0, pageSize: 5 },
//           },
//         }}
//         pageSizeOptions={[5, 10, 20]}
//         checkboxSelection
//       />
//     </Box>
//   );
// }

const SideDrawer = ({ theme }: { theme: Theme }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid rgba(0,0,0,0.12)",
        width: "240px",
        zIndex: theme.zIndex.appBar - 1,
      }}
    >
      <Paper
        sx={{
          display: "flex",
          p: 2,
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "6px",
            backgroundColor: "blue",
            borderRadius: "8px",
            mr: 1,
          }}
        ></Box>
        <Typography variant="h4" component="h2">
          Select a theme
        </Typography>
      </Paper>
    </Box>
  );
};

const ANALYTICS_TAB = 0;
const INDIVIDUAL_TAB = 1;
// Choose 11 colors
const colors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#A1B56C",
  "#EC5F67",
  "#6699CC",
  "#FAC863",
  "#9C27B0",
  "#FF7043",
  "#26A69A",
  "#FF5722",
];

export default function SurveyReport({}: {}) {
  const { t, i18n } = useTranslation();

  let { surveyId } = useParams();
  const { auth } = useAuth();
  const theme = useTheme();
  const [survey, setSurvey] = React.useState<any>({});
  const [responses, setResponses] = React.useState<any[]>([]);
  const [tabValue, setTabValue] = React.useState(ANALYTICS_TAB);
  const [isInitializing, setInitializing] = React.useState(false);
  const [questionType, setQuestionType] = React.useState<
    Record<string, string>
  >({});
  const [summary, setSummary] = React.useState<Record<string, any>>({});
  const [meta, setMeta] = React.useState<Record<string, any>>({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // const generateColumns = (questions: QuestionValue[]) => {
  //   const columns: GridColDef[] = [
  //     { field: "id", headerName: "ID", hideable: true },
  //   ];

  //   questions.forEach((question: QuestionValue) => {
  //     columns.push({
  //       field: question.id,
  //       headerName: question.title,
  //       width: QUESTION_COLUMN_WIDTH,
  //     });
  //   });

  //   return columns;
  // };

  const tableColumns = useMemo<
    MRT_ColumnDef<any>[] & { qType?: string | undefined }
  >(() => {
    const columns: (MRT_ColumnDef<any> & { qType?: string | undefined })[] = [
      {
        accessorKey: "id", //access nested data with dot notation
        header: "ID",
        size: 150,
        qType: "",
      },
    ];
    survey.questions?.forEach((question: QuestionValue) => {
      columns.push({
        accessorKey: question.id,
        header: question.title,
        size: QUESTION_COLUMN_WIDTH,
        qType: question.type,
      });
    });
    return columns;
  }, [survey.questions]);

  const tableRows = useMemo<any[]>(() => {
    const rows: any[] = [];

    responses.forEach((response) => {
      const row: Record<string, any> = {};
      tableColumns.forEach((column) => {
        let fieldName = column.accessorKey as string;
        let fieldValue = "";
        if (fieldName === "id") {
          fieldValue = response.id;
        } else if (response && !_.isEmpty(response.response[fieldName])) {
          let { answer, otherAnswer } = response.response[fieldName];
          // If answer is object
          if (typeof answer === "object") {
            // Add answer for each selected option
            Object.entries(answer).forEach(([optionName, selected]) => {
              if (selected) {
                fieldValue += `${optionName}\n`;
              }
            });
          }
          // If answer is string
          else if (typeof answer === "string") {
            fieldValue = answer;
          } else {
            fieldValue = answer;
          }
          if (otherAnswer) {
            fieldValue += `${otherAnswer} (${t("surveyResponse.other")})`;
          }
        }
        row[fieldName] = fieldValue;
      });

      rows.push(row);
    });

    return rows;
  }, [tableColumns, responses]);

  // Make summary data for drawing with useMemo for each question based on question type
  // {
  //   "summary": {
  //       "9edee566-bd38-4043-a20f-126ff2fd1b12": {
  //           "Option 1": 4,
  //           "": 3
  //       },
  //       "4b320067-783d-4e34-afd3-a86079c6e99e": [
  //           "abc",
  //           "abc",
  //           "",
  //           "",
  //           "123",
  //           "hello",
  //           "123"
  //       ],
  //       "3019e399-0aa8-4140-9bd8-2feb6d0b319f": [
  //           "abc",
  //           "abc",
  //           "",
  //           "",
  //           "456",
  //           "eee",
  //           "123"
  //       ],
  //       "6a4e77bf-ac2d-43ce-bf5f-4d42a20a7576": {
  //           "Option 1": 5
  //       },
  //       "3e0d8b0d-85d7-4048-92b8-9d6c3e22c40c": {
  //           "Option 1": 5
  //       }
  //   },
  //   "questionType": {
  //       "9edee566-bd38-4043-a20f-126ff2fd1b12": "multiple-choice",
  //       "4b320067-783d-4e34-afd3-a86079c6e99e": "short-answer",
  //       "3019e399-0aa8-4140-9bd8-2feb6d0b319f": "paragraph",
  //       "6a4e77bf-ac2d-43ce-bf5f-4d42a20a7576": "checkboxes",
  //       "3e0d8b0d-85d7-4048-92b8-9d6c3e22c40c": "checkboxes"
  //   }
  // }

  // Summary data will be used to draw charts
  // {question_id: chartData}
  const summaryData = useMemo(() => {
    // If question is multiple choice or dropdown, draw donut chart
    // If question is short answer or paragraph, draw word cloud
    // If question is checkbox, draw horzontal bar chart
    const newSummaryData: Record<string, any> = {};
    Object.keys(summary).forEach((questionId) => {
      const type = questionType[questionId];
      switch (type) {
        case "multiple-choice":
        case "dropdown":
          newSummaryData[questionId] = {
            type: "donut",
            // Calculate the sum based on the values of the object
            total: summary[questionId]
              ? Object.values(
                  summary[questionId] as Record<string, number>
                ).reduce((a: number, b: number) => a + b, 0)
              : 0,
            data: summary[questionId]
              ? makeDonutAndBarChart(summary[questionId], t)
              : {},
          };
          break;
        case "short-answer":
        case "paragraph":
          newSummaryData[questionId] = {
            type: "list",
            total: summary[questionId] ? summary[questionId].length : 0,
            data: summary[questionId] ? summary[questionId] : [],
          };
          break;
        case "checkboxes":
          newSummaryData[questionId] = {
            type: "horzontalBar",
            total: summary[questionId]
              ? Object.values(
                  summary[questionId] as Record<string, number>
                ).reduce((a: number, b: number) => a + b, 0)
              : 0,
            data: summary[questionId]
              ? makeDonutAndBarChart(summary[questionId], t)
              : {},
          };
          break;
        default:
          break;
      }
    });
    return newSummaryData;
  }, [questionType, summary]);

  React.useEffect(() => {
    if (auth) {
      const fetchSurveyAndResponse = async () => {
        setInitializing(true);
        try {
          if (!surveyId) {
            throw new Error("Survey ID not found");
          }

          const { data } = await goAPI.mySurvey.getSurveyReportAnswers(
            _.get(auth, "accessToken", ""),
            surveyId
          );
          if (!data.survey) {
            throw new Error("Survey not found");
          }

          setSurvey(data.survey);
          setResponses(data.responses);
          setInitializing(false);
        } catch (error) {
          console.log(error);
        } finally {
          setInitializing(false);
        }
      };
      const fetchSummary = async () => {
        setInitializing(true);
        try {
          if (!surveyId) {
            throw new Error("Survey ID not found");
          }

          const { data } = await goAPI.mySurvey.getSurveyReportSummary(
            _.get(auth, "accessToken", ""),
            surveyId
          );

          setSummary(data.summary);
          setMeta(data.meta);
          setQuestionType(data.questionType);
          setInitializing(false);
        } catch (error) {
          console.log(error);
        } finally {
          setInitializing(false);
        }
      };
      fetchSurveyAndResponse();
      fetchSummary();
    }
  }, [auth, surveyId]);

  return (
    <React.Suspense>
      <Box
        sx={{
          minHeight: `100vh`,
          width: "100%",
          display: "flex", // added this line
          backgroundColor: theme.palette.blueBackground?.main || "#fff",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TopAppBar position="fixed" t={t} />
        <Container maxWidth="xl" sx={{ my: 9, p: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Typography variant="h5">{survey.title}</Typography>
              <Tabs value={tabValue} onChange={handleChange} sx={{ mt: 4 }}>
                <Tab
                  label={t("surveyReport.analytics")}
                  value={ANALYTICS_TAB}
                />
                <Tab
                  label={t("surveyReport.individual")}
                  value={INDIVIDUAL_TAB}
                />
              </Tabs>
            </Box>
            {/* Analytics */}
            {tabValue === ANALYTICS_TAB && (
              <Stack sx={{ mt: 4 }} gap={2}>
                <Paper sx={{ p: 4, borderRadius: "1rem" }} variant="outlined">
                  <Grid container direction={"column"}>
                    {/* Top bar */}
                    <Grid item container>
                      <Grid item>
                        <Box
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          <CheckIcon />
                          <Typography sx={{ ml: 1 }} variant="h5">
                            {meta.total !== undefined
                              ? `${meta?.total} ${t("surveyReport.responses")}`
                              : "Loading..."}
                          </Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item sx={{ ml: "auto" }}>
                      <Button variant="contained" startIcon={<IosShareIcon />}>
                        Export
                      </Button>
                    </Grid> */}
                    </Grid>
                  </Grid>
                </Paper>
                {survey?.surveyType === GOOGLEFORM_TYPE && (
                  <Paper sx={{ p: 4, borderRadius: "1rem" }} variant="outlined">
                    <Grid container direction={"column"}>
                      {/* Top bar */}
                      <Grid item container>
                        <Grid item>
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ ml: 1 }} variant="h5">
                              {t("surveyReport.googleFormHint")}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                )}

                {survey.questions &&
                  summaryData &&
                  // Render charts
                  Object.keys(summaryData).map((questionId) => {
                    const { total, type, data } = summaryData[questionId];
                    return (
                      <Paper
                        sx={{ p: 4, borderRadius: "1rem" }}
                        variant="outlined"
                        key={questionId}
                      >
                        <Grid container direction={"column"}>
                          {/* Top bar */}
                          <Grid item container>
                            <Grid item>
                              <Typography variant="h6">
                                {survey.questions.find(
                                  (q: QuestionValue) => q.id === questionId
                                )?.title || t("surveyReport.untitledQuestion")}
                              </Typography>
                              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                                {total !== undefined
                                  ? `${total} ${t("surveyReport.responses")}`
                                  : "Loading..."}
                              </Typography>
                            </Grid>
                            {/* <Grid item sx={{ ml: "auto" }}>
                            <MuiTooltip title="Export">
                              <IconButton>
                                <FileDownloadIcon />
                              </IconButton>
                            </MuiTooltip>
                          </Grid> */}
                          </Grid>
                          {/* Chart */}
                          {data ? (
                            <Grid
                              item
                              container
                              sx={{ mt: 2 }}
                              justifyContent={"center"}
                            >
                              {type === "donut" && (
                                <Grid item xs={12} md={4}>
                                  <Doughnut
                                    data={data}
                                    options={donutChartOptions}
                                  />
                                </Grid>
                              )}
                              {type === "horzontalBar" && (
                                <Grid item xs={12} md={6}>
                                  <Bar
                                    data={data}
                                    options={horzontalBarChartOptions}
                                  />
                                </Grid>
                              )}
                              {type === "list" && (
                                <ListAnswers data={data} t={t} />
                              )}
                            </Grid>
                          ) : (
                            <Grid
                              item
                              container
                              sx={{ mt: 4, height: 400 }}
                              justifyContent={"center"}
                            >
                              {t("surveyReport.empty")}
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    );
                  })}
              </Stack>
            )}

            {/* Individual */}
            <MaterialReactTable
              columns={tableColumns}
              data={tableRows || []}
              enableColumnResizing
              muiTablePaperProps={{
                //customize paper styles
                sx: {
                  display:
                    tabValue === INDIVIDUAL_TAB && !isInitializing
                      ? "block"
                      : "none",
                  mt: 4,
                  maxWidth: "100%",
                  p: 4,
                  borderRadius: "1rem",
                },
              }}
            />
          </Box>
        </Container>
      </Box>
    </React.Suspense>
  );
}

const makeDonutAndBarChart = (data: Record<string, number>, t: any) => {
  // Sort by value in descending order
  const sortedEntries = Object.entries(data).sort(([, a], [, b]) => b - a);

  let finalEntries = [];
  if (sortedEntries.length > 10) {
    // Prepare arrays for top 10 and remaining
    const topTenEntries = sortedEntries.slice(0, 10);
    const remainingEntries = sortedEntries.slice(10);

    // Calculate sum of remaining
    const remainingSum = remainingEntries.reduce(
      (acc, [, value]) => acc + value,
      0
    );
    // Merge top 10 and remaining
    finalEntries = [
      ...topTenEntries,
      [t("surveyReport.remaining"), remainingSum],
    ];
  } else {
    finalEntries = sortedEntries;
  }

  // Split entries into labels and values
  const labels = finalEntries.map(([key]) => key);
  const values = finalEntries.map(([, value]) => value);

  return {
    labels: labels,
    datasets: [
      {
        label: t("surveyReport.responses"),
        data: values,
        backgroundColor: colors,
      },
    ],
  };
};

const ListAnswers = ({ data, t }: { data: string[] | number[]; t: any }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <List
        sx={{
          width: "100%",
        }}
      >
        {data.map((answer: string | number, index: number) => {
          return (
            <ListItem
              key={index}
              sx={{
                mt: 2,
                bgcolor: theme.palette.grey[100],
                borderRadius: "16px",
              }}
            >
              <ListItemText primary={answer || t("surveyReport.emptyAnswer")} />
            </ListItem>
          );
        })}
        <ListItem
          sx={{
            mt: 2,
          }}
        >
          <ListItemText primary={t("surveyReport.displayOnlyTop10")} />
        </ListItem>
      </List>
    </Grid>
  );
};

const horzontalBarChartOptions = {
  indexAxis: "y" as const,
  responsive: true,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right" as const,
    },
  },
};

const donutChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
    },
  },
  // tooltips: {
  //   callbacks: {
  //     label: (tooltipItem: any, data: any) => {
  //       const dataset = data.datasets[tooltipItem.datasetIndex];
  //       const currentValue = dataset.data[tooltipItem.index];
  //       let total = 0;
  //       for (let i = 0; i < data.datasets.length; i++) {
  //         total += data.datasets[i].data[tooltipItem.index];
  //       }
  //       const percentage = ((currentValue / total) * 100).toFixed(0);
  //       return `${currentValue} (${percentage}%)`;
  //     },
  //     title: (tooltipItem: any) => `${tooltipItem[0]?.label}`,
  //   },
  // },
};
