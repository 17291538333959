import * as React from "react";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import goAPI from "../api/GoAPI";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import UserContext from "../context/AuthProvider";

import {
  Stack,
  Button,
  FormControlLabel,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Copyright from "../components/Copyright";
import BackgroundImage from "../images/login-background.jpg";
import CountrySelect from "../components/CountrySelect";
import FormSelect from "../components/FormSelect";
import {
  GENDERS,
  CountryType,
  SelectType,
  OCCUPATIONS,
  ACADEMIC_HISTORY,
  ACCOUNT_MAX_LENGTH,
  PREFERRED_LANGUAGES,
} from "../constants/Constants";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";
import { useTranslation } from "react-i18next";

import { Console } from "console";

interface FormValues {
  firstName: string;
  lastName: string;
  birthdate: string | null;
  gender: string;
  occupation: string;
  academicHistory: string;
  country: string | null | undefined | never;
  city: string;
  preferredLanguage: string;
  email: string;
  password: string;
}

function PasswordCheckListIcon({ passed = false }: { passed?: boolean }) {
  return (
    <>{passed ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</>
  );
}

export default function SignUp() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { auth } = React.useContext(UserContext);

  const [showPassword, setShowPassword] = React.useState(false);
  const [ShowPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [isSignUpSuccess, setSignUpSuccess] = React.useState(false);
  const handleShowPasswordClick = () => setShowPassword((show) => !show);
  const handleShowPasswordConfirmClick = () =>
    setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (!_.isEmpty(auth?.accessToken) && !_.isEmpty(auth?.user)) {
      navigate("/");
    }
  }, [auth]);

  const [lowercase, setLowercase] = React.useState(false);
  const [uppercase, setUppercase] = React.useState(false);
  const [number, setNumber] = React.useState(false);
  const [length, setLength] = React.useState(false);

  const validationSchema = yup.object({
    firstName: yup.string().required(t("signup.firstNameIsRequired")),
    lastName: yup.string().required(t("signup.lastNameIsRequired")),
    birthdate: yup
      .mixed<Dayjs>()
      .required(t("signup.birthdateIsRequired"))
      .test("Max date", t("signup.futureDateError"), (value) => {
        const today = dayjs();
        return today.diff(value) > 0;
      }),
    gender: yup.string().required(t("signup.genderIsRequired")),
    occupation: yup.string().required(t("signup.occupationIsRequired")),
    academicHistory: yup
      .string()
      .required(t("signup.academicHistoryIsRequired")),
    country: yup.mixed<CountryType>().required(t("signup.countryIsRequired")),
    city: yup.string().required(t("signup.cityIsRequired")),
    preferredLanguage: yup
      .string()
      .required(t("signup.preferredLanguageIsRequired")),
    email: yup
      .string()
      .email(t("signup.emailIsNotValid"))
      .required(t("signup.emailIsRequired")),
    password: yup
      .string()
      .min(8, t("signup.passwordMustBeAtLeast8Characters"))
      .required(t("signup.passwordIsRequired"))
      .test("password-checklist", t("signup.passwordIsNotValid"), (value) => {
        const errors = validatePassword(value);
        return errors.length === 0;
      }),
    confirmPassword: yup
      .string()
      .required(t("signup.confirmPasswordIsRequired"))
      .oneOf([yup.ref("password")], t("signup.passwordsDoNotMatch")),
  });

  const validatePassword = (value: string) => {
    if (!value) {
      setLowercase(false);
      setUppercase(false);
      setNumber(false);
      setLength(false);
      return [t("signup.passwordIsRequired")];
    }

    let errors = [];

    if (!/(?=.*[a-z])/.test(value)) {
      errors.push(t("signup.passwordMustContainAtLeast1LowerCaseLetter"));
      setLowercase(false);
    } else {
      setLowercase(true);
    }

    if (!/(?=.*[A-Z])/.test(value)) {
      errors.push(t("signup.passwordMustContainAtLeast1CapitalLetter"));
      setUppercase(false);
    } else {
      setUppercase(true);
    }

    if (!/(?=.*\d)/.test(value)) {
      errors.push(t("signup.passwordMustContainAtLeast1Number"));
      setNumber(false);
    } else {
      setNumber(true);
    }

    if (value.length < 8) {
      errors.push(t("signup.passwordMustBeAtLeast8Characters"));
      setLength(false);
    } else {
      setLength(true);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      birthdate: null,
      gender: "",
      occupation: "",
      academicHistory: "",
      country: null,
      city: "",
      preferredLanguage: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let submission: FormValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        birthdate: dayjs(values.birthdate).format("YYYY-MM-DD"),
        gender: values.gender,
        occupation: values.occupation,
        academicHistory: values.academicHistory,
        country: values.country!["code"],
        city: values.city,
        preferredLanguage: values.preferredLanguage,
        email: values.email,
        password: values.password,
      };
      try {
        const response = await goAPI.auth.register(submission);
        toast.success(response.data.message);
        setSignUpSuccess(true);
      } catch (error: any) {
        console.log(error); // Prints the error message
        if (error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(t("signup.failedToRegister"));
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleBlur = () => {
    if (formik.values.password === "") {
      setLowercase(false);
      setUppercase(false);
      setNumber(false);
      setLength(false);
    }
  };



  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={formik.isSubmitting}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {isSignUpSuccess ? (
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: 84, height: 84 }}
                src="/logo512.png"
                alt="logo"
              />
            </Box>
            <Typography
              component="h1"
              variant="h5"
              sx={{ textAlign: "center" }}
            >
              {t("signup.Thankyousigningup")}
            </Typography>
            <Typography component="p" variant="h6" sx={{ textAlign: "center" }}>
              {t("signup.pleaseCheckEmail")}
            </Typography>
            <Button
              size="large"
              variant="contained"
              component={RouterLink}
              to="/login"
              endIcon={<LoginIcon />}
              sx={{ mt: 3, mb: 2, borderRadius: 6 }}
            >
              {t("signup.goToLogin")}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "strecth",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: 84, height: 84 }}
                src="/logo512.png"
                alt="logo"
              />
            </Box>
            <Typography
              component="h1"
              variant="h4"
              sx={{ textAlign: "center" }}
            >
              {t("signup.title")}
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <Grid
                container
                sx={{
                  display: "flex",
                }}
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="firstName"
                    required
                    name="firstName"
                    label={t("signup.firstName")}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.FIRST_NAME }}
                    autoFocus
                    autoComplete="first-name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="lastName"
                    name="lastName"
                    label={t("signup.lastName")}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.LAST_NAME }}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    autoComplete="last-name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label={`${t("signup.birthDate")} *`}
                    value={formik.values.birthdate}
                    onChange={(val) => {
                      formik.setFieldValue("birthdate", val);
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error:
                          formik.touched.birthdate &&
                          Boolean(formik.errors.birthdate),
                        helperText: (
                          <Typography variant="caption">
                            {formik.touched.birthdate &&
                              formik.errors.birthdate}
                          </Typography>
                        ),
                        color:
                          formik.touched.birthdate &&
                          Boolean(formik.errors.birthdate)
                            ? "error"
                            : "primary",
                      },
                    }}
                    maxDate={dayjs()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    id="gender"
                    label={t("signup.gender")}
                    options={GENDERS}
                    value={formik.values.gender}
                    onChange={(event) => {
                      formik.setFieldValue("gender", event.target.value);
                    }}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                    t={t}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CountrySelect
                    id="country"
                    value={formik.values.country}
                    onChange={(e, val) => {
                      formik.setFieldValue("country", val);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                      id="city"
                      required
                    label={t("signup.city")}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.CITY }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSelect
                    id="preferred-language"
                    label={t("signup.preferredLanguage")}
                    options={PREFERRED_LANGUAGES}
                    value={formik.values.preferredLanguage}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "preferredLanguage",
                        event.target.value
                      );
                    }}
                    error={
                      formik.touched.preferredLanguage &&
                      Boolean(formik.errors.preferredLanguage)
                    }
                    helperText={
                      formik.touched.preferredLanguage &&
                      formik.errors.preferredLanguage
                    }
                    t={t}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSelect
                    id="occupation"
                    label={t("signup.occupation")}
                    options={OCCUPATIONS}
                    value={formik.values.occupation}
                    onChange={(event) => {
                      formik.setFieldValue("occupation", event.target.value);
                    }}
                    error={
                      formik.touched.occupation &&
                      Boolean(formik.errors.occupation)
                    }
                    helperText={
                      formik.touched.occupation && formik.errors.occupation
                    }
                    t={t}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSelect
                    id="academic-history"
                    label={t("signup.academicHistory")}
                    options={ACADEMIC_HISTORY}
                    value={formik.values.academicHistory}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "academicHistory",
                        event.target.value
                      );
                    }}
                    error={
                      formik.touched.academicHistory &&
                      Boolean(formik.errors.academicHistory)
                    }
                    helperText={
                      formik.touched.academicHistory &&
                      formik.errors.academicHistory
                    }
                    t={t}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    required
                    name="email"
                    label={t("signup.email")}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.EMAIL }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="password"
                    name="password"
                    label={t("signup.password")}
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={handleBlur}
                    inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.PASSWORD }}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    autoComplete="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowPasswordClick}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="confirmPassword"
                    name="confirmPassword"
                    label={t("signup.confirmPassword")}
                    type={ShowPasswordConfirm ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    autoComplete="confirm-password"
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    inputProps={{ maxLength: ACCOUNT_MAX_LENGTH.PASSWORD }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowPasswordConfirmClick}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {ShowPasswordConfirm ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <FormControlLabel
                      control={<PasswordCheckListIcon passed={lowercase} />}
                      label={t(
                        "signup.passwordMustContainAtLeast1LowerCaseLetter"
                      )}
                      disabled={!lowercase}
                      sx={{ mx: 0 }}
                    />
                    <FormControlLabel
                      control={<PasswordCheckListIcon passed={uppercase} />}
                      label={t(
                        "signup.passwordMustContainAtLeast1CapitalLetter"
                      )}
                      disabled={!uppercase}
                    />
                    <FormControlLabel
                      control={<PasswordCheckListIcon passed={number} />}
                      label={t("signup.passwordMustContainAtLeast1Number")}
                      disabled={!number}
                    />
                    <FormControlLabel
                      control={<PasswordCheckListIcon passed={length} />}
                      label={t("signup.passwordMustBeAtLeast8Characters")}
                      disabled={!length}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                disabled={formik.isSubmitting}
                sx={{ mt: 3, mb: 2, borderRadius: 6 }}
              >
                {t("signup.title")}
              </Button>
            </Box>
            <Copyright sx={{ mt: 3 }} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
