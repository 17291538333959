import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function SaveSurveyModal({
  open,
  handleClose,
  handleOk,
  t,
}: {
  open: boolean;
  handleClose: () => void;
  handleOk: () => void;
  t: any;
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("saveSurvey.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("saveSurvey.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("saveSurvey.cancel")}</Button>
          <Button onClick={handleOk} autoFocus>
            {t("saveSurvey.agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
