import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Copyright from "../components/Copyright";
import Stack from "@mui/material/Stack";
import TopAppBar from "../components/TopAppBar";
import { HomeCardProps, HomeCard } from "./HomeCard";

interface CardGridProps {
  cards: HomeCardProps[];
}
const CardGrid = ({ cards }: CardGridProps): JSX.Element => {
  return (
    <Grid container spacing={2}>
      {cards?.map((homeCardProps) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={homeCardProps.id}>
          <HomeCard {...homeCardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardGrid;
