import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ShortText as ShortTextIcon,
  Subject as SubjectIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckBoxIcon,
  ArrowDropDownCircle as ArrowDropDownCircleIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  Splitscreen as SplitscreenIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { AddQuestionButton } from "./DraggableButton";

interface CreateSurveyMobileDrawerProps {
  open: boolean;
  appendNewQuestion: (type: string) => void;
  handleClose: () => void;
  t: any;
}

export default function CreateSurveyMobileDrawer({
  open,
  appendNewQuestion,
  handleClose,
  t,
}: CreateSurveyMobileDrawerProps) {

  const appendQuestionAndClose = (type: string) => {
    appendNewQuestion(type);
    handleClose();
  };

  return (
    <Drawer anchor={"bottom"} open={open} onClose={handleClose}>
      {
        <Box
          sx={{ width: "auto" }}
          role="presentation"
          onKeyDown={handleClose}
        >
          <Grid container spacing={2} sx={{ width: "100%", p: 4 }}>
            {/* Input */}
            <Grid
              item
              container
              direction={"column"}
              xs={12}
              xl={6}
              justifyContent="start"
            >
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {t("createSurvey.InputQType")} - 10pts
                </Typography>
                <AddQuestionButton
                  id="short-answer"
                  icon={<ShortTextIcon />}
                  onClick={appendQuestionAndClose}
                >
                  {t("createSurvey.shortAnswer")}
                </AddQuestionButton>
                <AddQuestionButton
                  id="paragraph"
                  icon={<SubjectIcon />}
                  onClick={appendQuestionAndClose}
                >
                  {t("createSurvey.paragraph")}
                </AddQuestionButton>
              </Stack>
            </Grid>
            {/* Choice */}
            <Grid
              item
              container
              direction={"column"}
              xs={12}
              xl={6}
              justifyContent="start"
            >
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {t("createSurvey.choiceQType")} - 10pts
                </Typography>
                <AddQuestionButton
                  id="multiple-choice"
                  icon={<RadioButtonCheckedIcon />}
                  onClick={appendQuestionAndClose}
                >
                  {t("createSurvey.multipleChoice")}
                </AddQuestionButton>
                <AddQuestionButton
                  id="checkboxes"
                  icon={<CheckBoxIcon />}
                  onClick={appendQuestionAndClose}
                >
                  {t("createSurvey.checkboxes")}
                </AddQuestionButton>
                <AddQuestionButton
                  id="dropdown"
                  icon={<ArrowDropDownCircleIcon />}
                  onClick={appendQuestionAndClose}
                >
                  {t("createSurvey.dropdown")}
                </AddQuestionButton>
              </Stack>
            </Grid>
            {/* Section */}
            <Grid
              item
              container
              direction={"column"}
              xs={12}
              xl={6}
              justifyContent="start"
            >
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {t("createSurvey.choiceQType")}
                </Typography>
                <AddQuestionButton
                  id="divider"
                  icon={<SplitscreenIcon />}
                  onClick={appendQuestionAndClose}
                >
                  {t("createSurvey.divider")}
                </AddQuestionButton>
              </Stack>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      }
    </Drawer>
  );
}
