import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DescriptionIcon from "@mui/icons-material/Description";
import { SURVEYSKY_TYPE, GOOGLEFORM_TYPE } from "../constants/Constants";

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function CreateSurveyModal({
  open,
  handleClose,
  t,
}: {
  open: boolean;
  handleClose: (surveyType: string) => void;
  t: any;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose("");
        }}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("mySurvey.createModal.title")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ButtonBase
                onClick={() => {
                  handleClose(SURVEYSKY_TYPE);
                }}
              >
                <Card
                  sx={{
                    minWidth: 275,
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 300,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={theme.palette.primary.main}
                      gutterBottom
                    >
                      {t("mySurvey.createModal.createNew")}
                    </Typography>
                    <NoteAddIcon
                      sx={{ fontSize: 80, color: theme.palette.primary.main }}
                    />
                  </CardContent>
                </Card>
              </ButtonBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <ButtonBase
                onClick={() => {
                  handleClose(GOOGLEFORM_TYPE);
                }}
              >
                <Card
                  sx={{
                    minWidth: 275,
                    color: "#6c4ab3",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 300,
                    }}
                  >
                    {/* Future color reference #673ab7 */}
                    <Typography
                      variant="h6"
                      color={"#6c4ab3"}
                      gutterBottom
                    >
                      {t("mySurvey.createModal.linkGoogleForm")}
                    </Typography>
                    <DescriptionIcon
                      sx={{ fontSize: 80, color: "#6c4ab3" }}
                    />
                  </CardContent>
                </Card>
              </ButtonBase>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose("");
            }}
          >
            {t("mySurvey.createModal.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
