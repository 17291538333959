import React, { Suspense, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Container, Typography, useTheme } from "@mui/material";
import TopAppBar from "../components/TopAppBar";
import "../App.css";
import Cloud from "../images/cloud.png";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  return (
    <Suspense fallback="loading">
      <div
        style={{
          background: `linear-gradient(to bottom, ${theme.palette.primary.main} 50%, #ffffff)`,
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "50%",
          }}
        >
          <div className="cloud-animation">
            <img src={Cloud} alt="Cloud" />
          </div>
        </div>

        <TopAppBar t={t} />
        <Box
          component={"main"}
          sx={{
            marginTop: 20,
          }}
        >
          <Container maxWidth="md">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            ></Box>
            <Typography
              variant="h2"
              component={"h1"}
              sx={{
                textAlign: "center",
                color: theme.palette.primary.contrastText,
                marginBottom: 2,
                fontWeight: 700,
              }}
            >
              404
            </Typography>
            <Typography
              variant="h4"
              component={"h2"}
              sx={{
                textAlign: "center",
                color: theme.palette.primary.contrastText,
              }}
            >
              {t("notFound.description")}
            </Typography>
          </Container>
        </Box>
      </div>
    </Suspense>
  );
}
