import * as React from "react";
import _ from "lodash";
import { Button, Stack, Tooltip, Paper, ButtonGroup } from "@mui/material";
import {
  PhoneIphone as PhoneIphoneIcon,
  TabletMac as TabletMacIcon,
  LaptopChromebook as LaptopChromebookIcon,
} from "@mui/icons-material";
import {
  PREVIEW_PHONE_SIZE,
  PREVIEW_TABLET_SIZE,
  PREVIEW_DESKTOP_SIZE,
} from "../constants/Constants";
import { SurveyValue } from "../pages/CreateSurvey";
import SurveyResponse from "./SurveyResponse";
import { toast } from "react-toastify";

export default function CreateSurveyPreview({
  survey,
  t,
}: {
  survey: SurveyValue;
  t: any;
}) {
  //   Iphone SE screen size
  const [previewSize, setPreviewSize] =
    React.useState<Record<string, number | string>>(PREVIEW_PHONE_SIZE);

  return (
    <Stack sx={{ height: "100%" }} gap={2} padding={4} alignItems={"center"}>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        size="large"
      >
        <Tooltip title={t("createSurvey.previewOnPhone")} placement="top">
          <Button
            size="large"
            onClick={() => {
              setPreviewSize(PREVIEW_PHONE_SIZE);
            }}
          >
            <PhoneIphoneIcon />
          </Button>
        </Tooltip>
        <Tooltip title={t("createSurvey.previewOnTablet")} placement="top">
          <Button
            size="large"
            onClick={() => {
              setPreviewSize(PREVIEW_TABLET_SIZE);
            }}
          >
            <TabletMacIcon />
          </Button>
        </Tooltip>
        <Tooltip title={t("createSurvey.previewOnDesktop")} placement="top">
          <Button
            size="large"
            onClick={() => {
              setPreviewSize(PREVIEW_DESKTOP_SIZE);
            }}
          >
            <LaptopChromebookIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Paper
        sx={{
          width: previewSize.width,
          height: previewSize.height,
          borderRadius: "8px",
          backgroundColor: survey.theme?.background,
          px: previewSize.width === PREVIEW_PHONE_SIZE.width ? 2 : 0,
          overflowY: "auto",
        }}
      >
        <SurveyResponse
          survey={survey}
          isPreview={true}
          submitSurvey={() => {
            toast.success(t("createSurvey.submissionTest"));
          }}
          t={t}
        />
      </Paper>
    </Stack>
  );
}
