import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SurveyTargetAudienceValue } from "../pages/CreateSurvey";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type TitleGroup = {
  title: string;
  group: string;
};

interface TargetAudienceSelectProps {
  value: SurveyTargetAudienceValue[];
  setValue: (newValue: SurveyTargetAudienceValue[]) => void;
  t: any;
}

export default function TargetAudienceSelect({
  value,
  setValue,
  t,
}: TargetAudienceSelectProps) {
  return (
    <Autocomplete
      multiple
      id="target-audience-select"
      options={targetAudienceOptions}
      disableCloseOnSelect
      value={value}
      onChange={(event: any, newValue: any) => {
        setValue(newValue);
      }}
      getOptionLabel={(option) =>
        t(getTitleGroup(option.type, option.value)?.title)
      }
      isOptionEqualToValue={(option, value) =>
        option.value === value.value && option.type === value.type
      }
      groupBy={(option) => t(getTitleGroup(option.type, option.value)?.group)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {t(getTitleGroup(option.type, option.value)?.title)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("createSurvey.recommendationRules")}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
        />
      )}
    />
  );
}

const targetAudienceOptions = [
  {
    type: "gender",
    value: "female",
  },
  {
    type: "gender",
    value: "male",
  },
  {
    type: "gender",
    value: "transgender",
  },
  {
    type: "gender",
    value: "nonBinary",
  },
  {
    type: "gender",
    value: "noAnswer",
  },
  {
    type: "occupation",
    value: "juniorHighSchool",
  },
  {
    type: "occupation",
    value: "highSchool",
  },
  {
    type: "occupation",
    value: "undergraduate",
  },
  {
    type: "occupation",
    value: "graduate",
  },
  {
    type: "occupation",
    value: "notStudent",
  },
  {
    type: "academicHistory",
    value: "juniorHighSchool",
  },
  {
    type: "academicHistory",
    value: "highSchool",
  },
  {
    type: "academicHistory",
    value: "undergraduate",
  },
  {
    type: "academicHistory",
    value: "master",
  },
  {
    type: "academicHistory",
    value: "phd",
  },
  {
    type: "ageRange",
    value: "0:18",
  },
  {
    type: "ageRange",
    value: "18:24",
  },
  {
    type: "ageRange",
    value: "25:34",
  },
  {
    type: "ageRange",
    value: "35:44",
  },
  {
    type: "ageRange",
    value: "45:54",
  },
  {
    type: "ageRange",
    value: "55:64",
  },
  {
    type: "ageRange",
    value: "65:100",
  },
];

const titleGroupMapping: { [key: string]: TitleGroup } = {
  gender_female: {
    title: "signup.gender.female",
    group: "signup.gender",
  },
  gender_male: {
    title: "signup.gender.male",
    group: "signup.gender",
  },
  gender_transgender: {
    title: "signup.gender.transgender",
    group: "signup.gender",
  },
  gender_nonBinary: {
    title: "signup.gender.nonBinary",
    group: "signup.gender",
  },
  gender_noAnswer: {
    title: "signup.gender.noAnswer",
    group: "signup.gender",
  },
  occupation_juniorHighSchool: {
    title: "signup.occupations.juniorHighSchool",
    group: "signup.occupation",
  },
  occupation_highSchool: {
    title: "signup.occupations.highSchool",
    group: "signup.occupation",
  },
  occupation_undergraduate: {
    title: "signup.occupations.undergraduate",
    group: "signup.occupation",
  },
  occupation_graduate: {
    title: "signup.occupations.graduate",
    group: "signup.occupation",
  },
  occupation_notStudent: {
    title: "signup.occupations.notStudent",
    group: "signup.occupation",
  },
  academicHistory_juniorHighSchool: {
    title: "signup.academicHistory.juniorHighSchool",
    group: "signup.academicHistory",
  },
  academicHistory_highSchool: {
    title: "signup.academicHistory.highSchool",
    group: "signup.academicHistory",
  },
  academicHistory_undergraduate: {
    title: "signup.academicHistory.undergraduate",
    group: "signup.academicHistory",
  },
  academicHistory_master: {
    title: "signup.academicHistory.master",
    group: "signup.academicHistory",
  },
  academicHistory_phd: {
    title: "signup.academicHistory.phd",
    group: "signup.academicHistory",
  },
  "ageRange_0:18": {
    title: "home.age.under18",
    group: "home.age",
  },
  "ageRange_18:24": {
    title: "home.age.18to24",
    group: "home.age",
  },
  "ageRange_25:34": {
    title: "home.age.25to34",
    group: "home.age",
  },
  "ageRange_35:44": {
    title: "home.age.35to44",
    group: "home.age",
  },
  "ageRange_45:54": {
    title: "home.age.45to54",
    group: "home.age",
  },
  "ageRange_55:64": {
    title: "home.age.55to64",
    group: "home.age",
  },
  "ageRange_65:100": {
    title: "home.age.65andOver",
    group: "home.age",
  },
};

function getTitleGroup(type: string, value: string): TitleGroup {
  const key = `${type}_${value}`;
  return titleGroupMapping[key];
}

export { getTitleGroup };
