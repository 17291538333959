import * as React from "react";
import goAPI from "../api/GoAPI";

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Copyright from "../components/Copyright";
import TopAppBar from "../components/TopAppBar";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import {
  Backdrop,
  Button,
  CardHeader,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import Link from "@mui/material/Link";
import CreateSurveyModal from "../components/CreateSurveyModal";
import UserContext from "../context/AuthProvider";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSurveyModal from "../components/DeleteSurveyModal";
import { useTranslation } from "react-i18next";
import { SURVEYSKY_TYPE, GOOGLEFORM_TYPE } from "../constants/Constants";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DescriptionIcon from "@mui/icons-material/Description";

export default function MySurveys() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { auth } = React.useContext(UserContext);
  const theme = useTheme();
  const [isLoading, setLoading] = React.useState(false);
  const [isInitializing, setInitializing] = React.useState(true);
  const [surveys, setSurveys] = React.useState<any[]>([]);
  const [createSurveyModalOpen, setCreateSurveyModalOpen] =
    React.useState(false);
  const [accessToken, setAccessToken] = React.useState(
    _.get(auth, "accessToken", "")
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOptionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOptionMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const { data } = await goAPI.mySurvey.getSurvey(
          _.get(auth, "accessToken", "")
        );
        setSurveys(data.surveys);
      } catch (error) {
        console.log(error);
      } finally {
        setInitializing(false);
      }
    };
    fetchSurvey();
    setAccessToken(_.get(auth, "accessToken", ""));
  }, [auth?.accessToken]);

  React.useEffect(() => {
    // Listen to changes for surveys
    // console.log(surveys);
  }, [surveys]);

  const [deleteSurveyId, setDeleteSurveyId] = React.useState("");

  const createSurvey = async (surveyType: string) => {
    try {
      // Response should contain the new survey id
      setLoading(true);
      const response = await goAPI.mySurvey.createSurvey(
        _.get(auth, "accessToken", ""),
        surveyType
      );
      // If get the id, redirect to the create-survey page
      let newSurveyID = _.get(response, "data._id");
      if (newSurveyID) {
        navigate("/my-surveys/edit/" + newSurveyID);
      } else {
        throw Error("Failed to get new Survey ID");
      }
      // If not, throw a error
    } catch (error: any) {
      console.log(error); // Prints the error message
      toast.error(t("mySurvey.failedToCreateSurvey"));
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteSurvey = (surveyId: string) => {
    setDeleteSurveyId(surveyId);
  };

  const deleteSurvey = async (surveyId: string) => {
    if (!accessToken || !surveyId) {
      return;
    }
    try {
      const toBeDeleted = surveyId;
      setDeleteSurveyId("");
      setLoading(true);
      // If response code is 204 then success
      const response = await goAPI.mySurvey.deleteSurveyById(
        accessToken,
        toBeDeleted
      );
      if (response.status === 204) {
        toast.success(t("mySurvey.surveyDeleted"));
        setSurveys((prev) =>
          prev.filter((survey) => survey.id !== toBeDeleted)
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function truncate(str: string, n: number) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <React.Suspense>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || isInitializing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TopAppBar t={t} />
      <DeleteSurveyModal
        open={deleteSurveyId !== ""}
        handleClose={() => setDeleteSurveyId("")}
        handleOk={() => {
          deleteSurvey(deleteSurveyId);
        }}
        t={t}
      />
      <CreateSurveyModal
        open={createSurveyModalOpen}
        handleClose={(surveyType: string) => {
          if (surveyType !== "") {
            createSurvey(surveyType);
          }

          setCreateSurveyModalOpen(false);
        }}
        t={t}
      />
      <main>
        {/* Survey cards start here */}
        <Container sx={{ py: 4 }} maxWidth="lg">
          <Menu
            id="option-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleOptionMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                if (!anchorEl) {
                  handleOptionMenuClose();
                  return;
                }
                const { id } = anchorEl.dataset;
                if (!id) {
                  handleOptionMenuClose();
                  return;
                }
                confirmDeleteSurvey(id);
                handleOptionMenuClose();
              }}
            >
              {t("mySurvey.delete")}
            </MenuItem>
          </Menu>
          {/*Search bar starts here */}
          <Paper
            component="form"
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: 600,
              backgroundColor: "#D9D9D9",
              margin: "auto",
              mb: 4,
            }}
          >
            {/* <InputBase
              sx={{
                ml: 1,
                flex: 1,
                input: {
                  color: "black",
                  "&::placeholder": {
                    opacity: 1,
                  },
                },
              }}
              placeholder="Search"
              inputProps={{ "aria-label": "Search" }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px", color: "black" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton> */}
          </Paper>
          {surveys && surveys.length > 0 ? (
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
              direction="row"
              justifyItems="flex-start"
              alignItems="baseline"
            >
              {surveys.map((surveyItem: any) => (
                <Grid item key={surveyItem.id} xs={12} sm={6} lg={4}>
                  <Card
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 4,
                      border: "1px solid #D3D3D3",
                      "&:hover": {
                        border: "1px solid #2C7EF4",
                      },
                    }}
                  >
                    <CardHeader
                      sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                          overflow: "hidden",
                        },
                      }}
                      avatar={
                        surveyItem.surveyType === SURVEYSKY_TYPE ? (
                          <Tooltip title="Survey Sky">
                            <InsertDriveFileIcon
                              sx={{
                                color: theme.palette.primary.main,
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Google Form">
                            <DescriptionIcon
                              sx={{
                                color: "#6c4ab3",
                              }}
                            />
                          </Tooltip>
                        )
                      }
                      title={
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h3"
                          noWrap={true}
                        >
                          <Link
                            component={RouterLink}
                            to={"/my-surveys/report/" + surveyItem.id}
                            sx={{
                              color: "inherit",
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {surveyItem.title || t("mySurvey.untitledSurvey")}
                          </Link>
                        </Typography>
                      }
                      titleTypographyProps={{ noWrap: true }}
                      action={
                        <IconButton
                          aria-label="more"
                          aria-controls={
                            Boolean(anchorEl) ? "option-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                          data-id={surveyItem.id}
                          onClick={handleOptionMenuClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      }
                    />
                    <Tooltip title={t("mySurvey.viewResponse")}>
                      <CardContent
                        sx={{
                          height: 160,
                          flexGrow: 1,
                          cursor: "pointer",
                          overflow: "hidden",
                        }}
                        onClick={() => {
                          navigate("/my-surveys/report/" + surveyItem.id);
                        }}
                      >
                        <Typography>
                          {truncate(surveyItem.description, 100) || ""}
                        </Typography>
                      </CardContent>
                    </Tooltip>
                    <CardActions>
                      {/* <CheckIcon sx={{ margin: "5px" }} />
        <Typography>{surveyItem.numResponse || 0}</Typography> */}
                      <Button
                        sx={{ marginLeft: "auto", borderRadius: 16 }}
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          navigate("/my-surveys/edit/" + surveyItem.id);
                        }}
                      >
                        {t("mySurvey.edit")}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}{" "}
            </Grid>
          ) : (
            <Stack gap={2} justifyContent={"center"} alignItems={"center"}>
              {!isInitializing && (
                <>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    {t("mySurvey.noSurvey")}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCreateSurveyModalOpen(true);
                    }}
                    startIcon={<AddIcon />}
                  >
                    {t("mySurvey.createSurvey")}
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Container>
        {/* Survey cards end here, plus button here */}
        <Fab
          color="primary"
          sx={{
            color: "white",
            "&:hover": {
              backgroundColor: "primary.main.dark",
            },
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
          }}
          onClick={() => {
            setCreateSurveyModalOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </main>
    </React.Suspense>
  );
}
