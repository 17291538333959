import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import { Box, Button, Grid, Paper } from "@mui/material";

import HowTo1 from "../images/howto_1.png";
import HowTo2 from "../images/howto_2.png";
import HowTo3 from "../images/howto_3.png";
import HowTo4 from "../images/howto_4.png";
import HowTo5 from "../images/howto_5.png";
import HowTo6 from "../images/howto_6.png";
import HowTo7 from "../images/howto_7.png";
import HowTo8 from "../images/howto_8.png";
import HowTo9 from "../images/howto_9.png";

import { FC } from "react";

export default function HowTo() {
  const { t, i18n } = useTranslation();
  //   const handleChangeLauguage = () => {
  //     i18n.changeLanguage(i18n.language === "ja" ? "en" : "ja");
  //   };
  return (
    <Layout t={t}>
      {/* <Button onClick={handleChangeLauguage}>change language!</Button> */}
      <Box sx={{ p: 4, width: "100%", backgroundColor: "#f8f8f8" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper
              variant="outlined"
              sx={{
                px: 5,
                py: 4,
                display: "flex",
                flexDirection: "column",
                gap: 5,
                borderRadius: 4,
              }}
            >
              <Box sx={{ fontSize: 18, fontWeight: "bold" }}>
                {/* Google formなどの外部サービスを使用する場合（PC版） */}
                {t("howto.title1")}
              </Box>
              <Box>
                {/* {＊Google
                formを利用する場合の例を掲載していますが、アンケート回答後に利用者がSurveySkyにより発行されるコードが取得できる仕組みがあれば他会社のアンケートでも利用可能です。} */}
                {t("howto.comment")}
              </Box>
              <StepsAndImage
                // title="1. 左側のページ一覧から、My surveysをクリック"
                title={t("howto.step1.1")}
                imageSource={HowTo1}
              />
              <StepsAndImage
                // title="2. 画面右下に表示される＋マークをクリック"
                title={t("howto.step1.2")}
                imageSource={HowTo2}
              />
              <StepsAndImage
                // title="3. 「Google formをリンク」を選択"
                title={t("howto.step1.3")}
                imageSource={HowTo3}
              />
              <StepsAndImage
                // title="4. ページに表示されるSurveySkyのコードをコピー"
                title={t("howto.step1.4")}
                imageSource={HowTo4}
              />
              <StepsAndImage
                // title="5. Google formの設定→表示設定→確認メッセージを開く"
                title={t("howto.step1.5")}
                imageSource={HowTo5}
              />
              <StepsAndImage
                //title="6. 確認メッセージにコピーしたコードを貼る"
                title={t("howto.step1.6")}
                imageSource={HowTo6}
              />
              <StepsAndImage
                //title="7. Google formのリンクをSurveySkyの元のページにペーストする"
                title={t("howto.step1.7")}
                imageSource={HowTo7}
              />
              <StepsAndImage title={t("howto.step1.8")} imageSource={HowTo8} />
              <StepsAndImage
                // title="＊上の手順通りに行なった場合、１つ１つのアンケートの最後に、SurveySkyのリンクが以下の写真のように添付されます"
                title={t("howto.step1.9")}
                imageSource={HowTo9}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              variant="outlined"
              sx={{
                px: 5,
                py: 4,
                display: "flex",
                flexDirection: "column",
                gap: 5,
                borderRadius: 4,
              }}
            >
              <Box sx={{ fontSize: 18, fontWeight: "bold" }}>
                {/* SurveySkyを使ってアンケートを作成する場合（PC版） */}
                {t("howto.title2")}
              </Box>
              <StepsAndImage
                //title="1. 左側のページ一覧から、My surveysをクリック"
                title={t("howto.step2.1")}
                imageSource={HowTo1}
              />
              <StepsAndImage
                //title="2. 画面右下に表示される＋マークをクリック"
                title={t("howto.step2.2")}
                imageSource={HowTo2}
              />
              <StepsAndImage
                //title="3. 「新しいアンケートを作成」を選択"
                title={t("howto.step2.3")}
                imageSource={HowTo3}
              />
              <StepsAndImage
                // title="4. ガイドに沿って質問を投稿"
                title={t("howto.step2.4")}
                imageSource={HowTo4}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}

const StepsAndImage = ({
  title,
  imageSource,
}: {
  title: string;
  imageSource: string;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>{title}</Box>
      <Paper
        elevation={5}
        sx={{
          "@media screen and (max-width:768px)": { mx: 0 },
          mx: 2,
          overflow: "hidden",
          borderRadius: 4,
          //   border: "1px solid #f8f8f8",
        }}
      >
        <img
          src={imageSource}
          alt="how to image"
          style={{ width: "100%", display: "block" }}
        />
      </Paper>
    </Box>
  );
};
