import React from "react";
import { Container, Box } from "@mui/material";
import Sidebar from "./Sidebar";
interface LayoutProps {
  children: React.ReactNode;
  t: any;
}
const Layout = ({ children, t }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        minHeight: "100vh", // This may need to be changed, parent of this should be 100% as well
        width: "100%",
        padding: "0",
      }}
    >
      <Box
        className="sidebar"
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <Sidebar t={t} />
      </Box>
      {children}
    </Box>
  );
};

export default Layout;
