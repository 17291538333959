import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import BugReportIcon from '@mui/icons-material/BugReport';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
  t: any;
}

export default function ContactDialog({
  open,
  handleClickOpen,
  handleClose,
  t,
}: Props) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Contact us"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("home.contactDialog.description")}
          </DialogContentText>
          <Stack gap={2} sx={{mt: 2, alignItems: "start"}}>
            <Button
              variant="outlined"
              component={RouterLink}
              target="_blank"
              to="https://forms.gle/ceKH3c9eaQeb6VWP8"
              startIcon={<FeedbackIcon />}
            >
              {t("home.feedback")}
            </Button>
            <Button
              variant="outlined"
              component={RouterLink}
              target="_blank"
              to="https://forms.gle/gNrKYB8WqA1ReyoH6"
              startIcon={<BugReportIcon />}
            >
              {t("home.bugReport")}
            </Button>
            <Button
              variant="outlined"
              component={RouterLink}
              target="_blank"
              to="https://forms.gle/tVFLYusR9Cx2VBHB9"
              startIcon={<ReportProblemIcon />}
            >
              {t("home.reportSurvey")}
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("home.contactDialog.ok")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
