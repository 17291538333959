import { useState, useMemo } from "react";

type HoverEventHandlers = {
  onMouseOver: () => void;
  onMouseOut: () => void;
};

type UseHoverResult = [boolean, HoverEventHandlers];

export default function useHover(): UseHoverResult {
  const [hovered, setHovered] = useState(false);

  const eventHandlers = useMemo<HoverEventHandlers>(
    () => ({
      onMouseOver() {
        setHovered(true);
      },
      onMouseOut() {
        setHovered(false);
      },
    }),
    []
  );

  return [hovered, eventHandlers];
}